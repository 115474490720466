import { Text } from '@pixi/react'
import { TextStyle } from 'pixi.js'

export default function TokenLabel({ wordWrapWidth, ...rest }) {
	return (
		<Text
			{...rest}
			anchor={[0.5, 0]}
			resolution={5}
			style={
				new TextStyle({
					trim: true,
					align: 'center',
					fontFamily: 'Helvetica, sans-serif',
					fontSize: 14,
					fill: '#ffffff',
					wordWrap: true,
					wordWrapWidth: wordWrapWidth,
					stroke: '#555555',
					strokeThickness: 2,
					dropShadow: true,
					dropShadowColor: '#000000',
					dropShadowAlpha: 0.3,
					dropShadowBlur: 3,
					dropShadowAngle: Math.PI / 3,
					dropShadowDistance: 4,
				})
			}
		/>
	)
}
