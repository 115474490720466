import * as DropdownMenu from '@radix-ui/react-dropdown-menu'
import React from 'react'
import { IDocument } from '../../interfaces/document'
import DocumentMenuContent from './MenuContent'

type Props = {
	document: IDocument
	children: React.ReactElement // Notice the type is React.ReactElement
}

const Menu = ({ document, children }: Props) => {
	const [isOpen, setIsOpen] = React.useState(false)

	return (
		<DropdownMenu.Root onOpenChange={open => setIsOpen(open)}>
			<DropdownMenu.Trigger className='dropdownMenu focus:outline-none'>
				{React.cloneElement(React.Children.only(children), { isOpen })}
			</DropdownMenu.Trigger>
			<DocumentMenuContent document={document} />
		</DropdownMenu.Root>
	)
}

export default Menu
