import React from 'react'
import SmallUppercase from '../text/SmallUppercase'
import AccessDots from './AccessDots'
import { IDocument } from '../../interfaces/document'

type Props = {
	title: string
	singularName: string
	document: IDocument
}

const DocumentWindowTitle = ({ title, singularName, document }: Props) => {
	return (
		<div className='flex-col'>
			<div>{title}</div>
			<div className='flex space-x-2 text-xs'>
				<SmallUppercase>{singularName}</SmallUppercase>
				<AccessDots document={document} />
			</div>
		</div>
	)
}

export default DocumentWindowTitle
