import { useContext, useEffect } from 'react'
import { FormProvider, useForm, useWatch } from 'react-hook-form'
import { IAsset } from '../../../../../shared/types/asset'
import GameContext from '../../../contexts/game'
import { IDocument } from '../../../interfaces/document'
import AssetManager from '../../AssetManager'
import ActivateSceneButton from '../ActivateSceneButton'
import SceneName from '../SceneEditor/SceneName'
import SceneSecret from '../SceneEditor/SceneSecret'
import SceneSubtitle from '../SceneEditor/SceneSubtitle'

type Props = {
	doc: IDocument
}

const MapEditor = ({ doc }: Props) => {
	const { dispatch } = useContext(GameContext)
	const form = useForm()

	const values = useWatch({
		control: form.control,
		defaultValue: doc.values,
	})

	const handleUpdate = () => {
		const updatedDocument = {
			...doc,
			version: doc.version + 1,
			values: {
				...doc.values,
				...values,
			},
		}

		dispatch({
			type: 'UPDATE_DOCUMENT',
			payload: {
				updatedDocument,
			},
		})
	}

	useEffect(handleUpdate, [values])

	const handleAssetChange = (asset?: IAsset) => {
		const updatedDocument = {
			...doc,
			version: doc.version + 1,
			values: {
				...doc.values,
				mapId: asset?._id,
			},
		}

		dispatch({
			type: 'UPDATE_DOCUMENT',
			payload: {
				updatedDocument,
			},
		})
	}

	return (
		<FormProvider {...form}>
			<div>
				<div className='relative'>
					<AssetManager
						assetId={doc.values.mapId}
						onAssetChange={handleAssetChange}
					/>
					{doc.values.mapId && (
						<div className='absolute bottom-4 left-4 z-20'>
							<ActivateSceneButton sceneId={doc._id} />
						</div>
					)}
				</div>

				<div className='p-4'>
					<SceneName doc={doc} />
					<SceneSubtitle doc={doc} />
					<SceneSecret doc={doc} />
				</div>
			</div>
		</FormProvider>
	)
}

export default MapEditor
