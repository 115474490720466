import { FC } from 'react'
import { IGame } from '../../../interfaces/game'
import GameCardContainer from '../elements/GameCardContainer'

interface IProps {
	games: Partial<IGame>[]
	refetch: () => void
	loading: boolean
}

const Loading = (
	<div className='my-40 text-center text-gray-500'>Loading...</div>
)

const GamesList: FC<IProps> = ({ games, refetch, loading }) => {
	if (loading) return Loading

	const gameList = games.map(game => (
		<li className='mb-8' key={game._id}>
			<GameCardContainer
				game={{
					_id: game._id,
					title: game.title,
					description: game.description,
					coverImage: game.coverImage || game.system?.coverPath,
					logo: game.logo || game.system?.logoPath,
					hideLogo: game.hideLogo,
					assets: game.assets,
					creator: {
						// @ts-ignore
						_id: game.creator._id,
					},
				}}
				refetch={refetch}
			/>
		</li>
	))

	if (gameList.length === 0)
		return <div className='my-40 text-center text-gray-500'>No Games Found</div>

	return <>{gameList.length > 0 && <ul>{gameList}</ul>}</>
}

export default GamesList
