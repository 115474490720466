import * as PIXI from 'pixi.js'
import { useContext } from 'react'
import GameContext from '../../contexts/game'
import useGetActiveScene from '../../hooks/useGetActiveScene'

type Props = {
	tokenId: string
}

const useTokenInteractions = ({ tokenId }: Props) => {
	const { dispatch } = useContext(GameContext)
	const { _id: sceneId } = useGetActiveScene()

	let isDragging = false
	let dragData: PIXI.FederatedPointerEvent | null = null
	const dragOffset = { x: 0, y: 0 }
	let draggedObject: PIXI.DisplayObject | null = null

	const onPointerDown = (event: PIXI.FederatedPointerEvent) => {
		event.stopPropagation()
		dragData = event
		isDragging = true

		draggedObject = event.currentTarget as PIXI.DisplayObject
		const localPosition = event.data.getLocalPosition(
			draggedObject.parent as PIXI.Container,
		)
		dragOffset.x = localPosition.x - draggedObject.position.x
		dragOffset.y = localPosition.y - draggedObject.position.y

		// draggedObject.alpha = 0.5

		window.addEventListener('pointermove', onGlobalPointerMove)
		window.addEventListener('pointerup', onGlobalPointerUp)
	}

	const onGlobalPointerMove = (event: PointerEvent) => {
		if (!isDragging || !dragData || !draggedObject) return

		event.stopPropagation()

		const newPosition = dragData.data.getLocalPosition(
			draggedObject.parent as PIXI.Container,
		)
		draggedObject.position.x = newPosition.x - dragOffset.x
		draggedObject.position.y = newPosition.y - dragOffset.y
	}

	const onGlobalPointerUp = () => {
		if (draggedObject) {
			draggedObject.alpha = 1

			dispatch({
				type: 'UPDATE_TOKEN',
				payload: {
					sceneId,
					updatedToken: {
						_id: tokenId,
						x: draggedObject.position.x,
						y: draggedObject.position.y,
					},
				},
			})
		}

		isDragging = false
		dragData = null

		// Remove global events
		window.removeEventListener('pointermove', onGlobalPointerMove)
		window.removeEventListener('pointerup', onGlobalPointerUp)
	}

	const interactionProps = {
		pointerdown: onPointerDown,
	}

	return { interactionProps }
}

export default useTokenInteractions
