import React from 'react'
import { NavLink, NavLinkProps } from 'react-router-dom'
import { twMerge } from 'tailwind-merge'

interface ButtonLinkProps extends Omit<NavLinkProps, 'className'> {
	children: React.ReactNode
	className?: string
}

const ButtonLink: React.FC<ButtonLinkProps> = ({
	className,
	children,
	...props
}) => {
	return (
		<NavLink
			className={({ isActive, isPending }) =>
				twMerge(
					'text-md mb-8 font-semibold text-black dark:text-white md:text-xl',
					isActive ? 'active-class' : '',
					isPending ? 'pending-class' : '',
					className,
				)
			}
			{...props}
		>
			{children}
		</NavLink>
	)
}

export default ButtonLink
