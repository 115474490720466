import { ChevronRightIcon } from '@heroicons/react/24/solid'
import * as ContextMenu from '@radix-ui/react-context-menu'
import React, { useContext } from 'react'
import UsersContext from '../../../contexts/users'
import useAssumeCharacter from '../../../hooks/useAssumeCharacter'
import { IDocument } from '../../../interfaces/document'
import MenuItem from '../../ContextMenu/Item'
import Separator from '../../ContextMenu/Separator'
import UserDot from '../../UserDot'
import AssumeCharacterMenuItem from './AssumeCharacterMenuItem'

interface AssignCharacterProps {
	document: IDocument
}

const AssignCharacter: React.FC<AssignCharacterProps> = ({ document }) => {
	const { usersState } = useContext(UsersContext)
	const users = usersState.users
	const { handleAssumeCharacter } = useAssumeCharacter()

	return (
		<>
			<Separator />

			<AssumeCharacterMenuItem document={document} />

			<ContextMenu.Sub>
				<ContextMenu.SubTrigger className='cursor-pointer rounded-lg py-1.5 px-2.5 text-sm hover:bg-gray-800 hover:outline-none'>
					<div className='flex w-full justify-between'>
						<div className='text-left'>Assign Character</div>
						<div className='text-right'>
							<ChevronRightIcon className='h-5 w-5' />
						</div>
					</div>
				</ContextMenu.SubTrigger>
				<ContextMenu.Portal>
					<ContextMenu.SubContent className='z-50 space-y-1 rounded-xl border border-gray-700/50 bg-gray-900 p-1 text-gray-200 shadow-xl'>
						{users.map(user => {
							return (
								<MenuItem
									key={user.userId}
									onSelect={() =>
										handleAssumeCharacter(document._id, user.userId)
									}
								>
									<div className='flex'>
										<UserDot
											color={user.userSettings.color}
											className='mr-2 self-center'
										/>
										{user.userProfile.name}
									</div>
								</MenuItem>
							)
						})}
					</ContextMenu.SubContent>
				</ContextMenu.Portal>
			</ContextMenu.Sub>
		</>
	)
}

export default AssignCharacter
