import type { Identifier } from 'dnd-core'
import { useContext, useRef, useState } from 'react'
import { useDrag, useDrop } from 'react-dnd'
import { twMerge } from 'tailwind-merge'
import GameContext from '../../contexts/game'
import useGetDocumentById from '../../hooks/useGetDocumentById'
import ItemTypes from '../draganddrop/ItemTypes'
import DocumentMenu from './DocumentMenu'
import FolderFold from './FolderFold'
import ItemInformation from './ItemInformation'
import { TDragItem } from './Library'
import List from './List'
import useUser from '../../hooks/useUser'

type Props = {
	id: string
	dragIndex: number
	hoverIndex: number
	hoverItem: any
	moveItem: any // argh!
	selectedType: string
}

export default function Folder({
	id,
	dragIndex,
	hoverIndex,
	hoverItem,
	moveItem,
	selectedType,
}: Props) {
	const { game } = useContext(GameContext)
	const { document, fetchDocument } = useGetDocumentById(id)
	const ref = useRef<HTMLDivElement>()
	const [hoverDirection, setHoverDirection] = useState<'up' | 'down' | null>(
		null,
	)
	const docIndex = game.documents.allIds.findIndex(i => i === id)
	const shiftUp = dragIndex <= docIndex && docIndex < hoverIndex
	const shiftDown = hoverIndex <= docIndex && docIndex <= dragIndex
	const { isGM } = useUser()

	const [isFolded, setIsFolded] = useState<boolean>(() => {
		// Initialize state from localStorage or default to false if not set
		const storedFolded = localStorage.getItem(`folded-${id}`)
		return storedFolded ? JSON.parse(storedFolded) : false
	})

	const handleHover = (dragItem: TDragItem, monitor) => {
		if (!ref.current) return

		const dragId = dragItem.id
		// const dropId = id

		// Child is being hovered
		if (!monitor.isOver({ shallow: true })) return

		// Don't replace items with themselves
		// if (dragId === dropId) return

		// // Determine rectangle on screen
		const hoverRect = ref.current?.getBoundingClientRect()

		// // Determine mouse position
		const clientOffset = monitor.getClientOffset()

		// // Get pixels to the top
		const hoverClientY = clientOffset.y - hoverRect.top

		// // Get vertical middle
		const hoverMiddleY = hoverRect.height / 2

		let direction: 'up' | 'down' | null = 'up'

		// When dragging up, only move when the cursor is above 50%
		if (hoverClientY < hoverMiddleY) direction = 'up'

		// When dragging down, only move when the cursor is below 50%
		if (hoverClientY >= hoverMiddleY) direction = 'down'

		setHoverDirection(direction)

		hoverItem(dragId, id, direction)
	}

	const handleDrop = (dragItem: TDragItem) => {
		if (!ref.current) return

		const dragId = dragItem.id
		const dropId = id
		const insertBefore = hoverDirection === 'up'

		setHoverDirection(null)

		// do not drop on self
		if (dragId === dropId) return

		// do not drop on child
		const droppedOnOwnChild = (parentId: string): boolean => {
			const parent = fetchDocument(parentId)
			if (!parent) return false
			if (parent.parentId === dragId) return true
			return droppedOnOwnChild(parent.parentId)
		}

		if (droppedOnOwnChild(dropId)) return

		moveItem({
			id: dragId,
			targetId: dropId,
			parentId: insertBefore ? document.parentId : dropId,
			before: insertBefore,
		})

		setHoverDirection(null)
	}

	const [{ handlerId }, drop] = useDrop<
		TDragItem,
		void,
		{
			handlerId: Identifier | void
			isOver: boolean
		}
	>(
		() => ({
			accept: [ItemTypes.DOCUMENT, ItemTypes.FOLDER],
			hover: handleHover,
			drop: handleDrop,
			collect: monitor => ({
				isOver: !!monitor.isOver({ shallow: true }),
				handlerId: monitor.getHandlerId(),
			}),
		}),
		[id, hoverDirection],
	)

	const handleEnd = () => {
		setHoverDirection(null)
		hoverItem(null, null, null)
	}

	const [{ isDragging }, drag] = useDrag(
		() => ({
			type: ItemTypes.FOLDER,
			item: () => ({ id }),
			end: handleEnd,
			collect: monitor => ({
				isDragging: !!monitor.isDragging(),
			}),
		}),
		[id],
	)

	if (isGM) drag(drop(ref))

	const toggleFolded = () => {
		const newFoldedState = !isFolded
		setIsFolded(newFoldedState)
		// Save new state to localStorage
		localStorage.setItem(`folded-${id}`, JSON.stringify(newFoldedState))
	}

	return (
		<DocumentMenu document={document}>
			<div ref={ref} data-handler-id={handlerId}>
				<div
					className={twMerge(
						'flex flex-1',
						!isDragging && 'transition-transform duration-200 ease-in-out',
						isDragging && 'opacity-0',
						shiftDown ? 'translate-y-full' : '',
						shiftUp ? '-translate-y-full' : '',
					)}
				>
					<FolderFold isFolded={isFolded} toggleFolded={toggleFolded} />
					<ItemInformation document={document} />
				</div>
			</div>
			<div className={twMerge('ml-6', isFolded ? 'hidden' : '')}>
				<List
					parentId={document._id}
					dragIndex={dragIndex}
					hoverIndex={hoverIndex}
					hoverItem={hoverItem}
					moveItem={moveItem}
					selectedType={selectedType}
				/>
			</div>
		</DocumentMenu>
	)
}
