import { useContext } from 'react'
import { twMerge } from 'tailwind-merge'
import GameContext from '../../contexts/game'

type Props = React.HTMLProps<HTMLDivElement>

const SmallUppercase = ({ children, className, style, ...rest }: Props) => {
	const { game } = useContext(GameContext)
	const { theme } = game

	return (
		<div
			className={twMerge(`text-xs uppercase tracking-widest`, className)}
			style={{
				fontSize: '9px',
				fontWeight: 600,
				color: theme.secondary,
				...style,
			}}
			{...rest}
		>
			{children}
		</div>
	)
}

export default SmallUppercase
