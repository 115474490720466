// takes a document id and creates a full token object for that document
// see IToken interface for details

import { IToken } from '../interfaces/scene'
import { generateUUID } from '../utils/generateUUID'
import useGetDocumentById from './useGetDocumentById'

type Props = {
	documentId: string
}

const useDocumentToToken = ({ documentId }: Props) => {
	const { document } = useGetDocumentById(documentId)

	const token: IToken = {
		_id: generateUUID(),
		x: 0,
		y: 0,
		width: 1,
		height: 1,
		rotation: 0,
		locked: false,
		visible: true,
		type: 'token',
		documentId: document._id,
		name: document.values.name || 'Unnamed ',
		nameIsSecret: false,
	}

	return token
}

export default useDocumentToToken
