import { animated, useSpring } from '@react-spring/web'
import { useDrag } from '@use-gesture/react'
import clamp from 'lodash/clamp'
import inRange from 'lodash/inRange'
import { useCallback, useContext, useEffect, useRef, useState } from 'react'
import { createPortal } from 'react-dom'
import { twMerge } from 'tailwind-merge'
import WindowsContext from '../../contexts/windows'
import { ISavedWindow, IWindow } from '../../interfaces/game'
import WindowInner from './WindowInner'
import WindowResizeHandles from './WindowResizeHandles'
import Translucency from '../Translucency'

const Window: React.FC<IWindow> = ({
	documentId,
	open,
	title,
	children,
	className,
	headerChildren,
	footerChildren,
	bodyClassName = '',
	position = { x: 100, y: 100 },
	size = 'small',
	headerIcon,
	onClose = () => {}, // how clients can call things when we're closing
}) => {
	const { windowsState, dispatchWindows } = useContext(WindowsContext)
	const minW = 300
	const minH = 400
	const windowRef = useRef(document.createElement('div'))
	const [zIndex, setZIndex] = useState(0)
	const sizeMap = {
		small: { width: minW, height: minH },
		medium: { width: minW * 2, height: minH * 2 },
		large: { width: minW * 3, height: minH * 3 },
	}
	const defaultWindowRect = {
		x: position.x,
		y: position.y,
		width: sizeMap[size].width,
		height: sizeMap[size].height,
	}
	const savedWindowRect = windowsState.windows.find(
		w => w?.documentId === documentId,
	) || {
		documentId,
		x: position.x,
		y: position.y,
		width: sizeMap[size]?.width,
		height: sizeMap[size]?.height,
	}
	const [windowRect, setWindowRect] = useState<ISavedWindow>({
		...defaultWindowRect,
		...savedWindowRect,
	})

	const getFreshWindowRect = () => {
		const boundingRect = windowRef.current.getBoundingClientRect()
		const { x, y } = boundingRect
		const width = boundingRect.width
		const height = boundingRect.height

		return {
			documentId,
			x,
			y,
			width: width < minW ? minW : width,
			height: height < minH ? minH : height,
		}
	}

	const fitWindowToViewport = () => {
		const newWindowRect = { ...windowRect }
		const viewportWidth = window.innerWidth
		const viewportHeight = window.innerHeight

		const margin = 20 // margin to the nearest edge in px

		// Check if the window exceeds the right edge of the viewport
		if (newWindowRect.x + newWindowRect.width > viewportWidth - margin) {
			// Adjust the window width so it fits inside the viewport with a margin
			newWindowRect.width = viewportWidth - newWindowRect.x - margin
		}

		// Check if the window exceeds the bottom edge of the viewport
		if (newWindowRect.y + newWindowRect.height > viewportHeight - margin) {
			// Adjust the window height so it fits inside the viewport with a margin
			newWindowRect.height = viewportHeight - newWindowRect.y - margin
		}

		// Make sure the window's x position doesn't go beyond the right edge of the viewport
		newWindowRect.x = Math.min(
			newWindowRect.x,
			viewportWidth - newWindowRect.width - margin,
		)

		// Make sure the window's y position doesn't go beyond the bottom edge of the viewport
		newWindowRect.y = Math.min(
			newWindowRect.y,
			viewportHeight - newWindowRect.height - margin,
		)

		// Apply the new dimensions
		setWindowRect(newWindowRect)
		api.set(newWindowRect)
	}

	const handleOpenClose = () => {
		fitWindowToViewport()
		const rect = windowRect ? windowRect : getFreshWindowRect()

		// circumvent weird bug where windowRect is 0
		if (windowRect.width === 0 || windowRect.height === 0) return

		dispatchWindows({
			type: 'OPEN_WINDOW',
			payload: {
				documentId,
				x: rect.x,
				y: rect.y,
				width: rect.width < 1 ? minW : rect.width,
				height: rect.height < 1 ? minH : rect.height,
			},
		})
	}
	useEffect(handleOpenClose, [open])

	const doZIndex = () => {
		const i = windowsState.windows.findIndex(w => w?.documentId === documentId)
		setZIndex(i > -1 ? i : 0)
	}
	useEffect(doZIndex, [windowsState]) // eslint-disable-line react-hooks/exhaustive-deps

	const [{ x, y, width, height }, api] = useSpring(() => windowRect)

	// move/resize window
	useDrag(
		({ down, movement: [x, y], target }) => {
			if (down) document.querySelector('body')?.classList.add('dragging')
			else document.querySelector('body')?.classList.remove('dragging')

			dispatchWindows({
				type: 'OPEN_WINDOW',
				payload: windowRect,
			})

			// @ts-ignore: target is always a HTMLElement
			const dragType = target.closest('[data-dragtype]')?.dataset?.dragtype
			const cancelDrag = !dragType || dragType[0] === ''

			if (cancelDrag) return

			if (!down) {
				setWindowRect(getFreshWindowRect())
				return
			}

			const rect = windowRect
			const maxW = window.innerWidth
			const maxH = window.innerHeight
			const bottom = rect.y + rect.height
			const right = rect.x + rect.width
			const settings = { ...rect }

			if (dragType.includes('move')) {
				settings.x = x + rect.x
				settings.y = y + rect.y
			}

			if (dragType.includes('top')) {
				const h = clamp(rect.height - y, minH, maxH)
				settings.height = h
				settings.y = h <= minH ? bottom - minH : rect.y + y
			}

			if (dragType.includes('left')) {
				const w = clamp(rect.width - x, minW, maxW)
				settings.width = w
				settings.x = w <= minW ? right - minW : rect.x + x
			}

			if (dragType.includes('bottom')) {
				settings.height = clamp(rect.height + y, minH, maxH)
			}

			if (dragType.includes('right')) {
				settings.width = clamp(rect.width + x, minW, maxW)
			}

			// snap to horizontal sides
			const snap = 10
			const winWidth = window.innerWidth
			const rightSnap = winWidth - rect.width
			const leftEdge = settings.x
			const rightEdge = settings.x + settings.width

			settings.x = inRange(leftEdge, -snap, snap) ? 0 : settings.x
			settings.x =
				dragType === 'move' &&
				inRange(rightEdge, winWidth - snap, winWidth + snap)
					? rightSnap
					: settings.x

			// block at top
			settings.y = settings['y'] < 0 ? 0 : settings['y']

			api.set({
				x: settings.x,
				y: settings.y,
				width: settings.width,
				height: settings.height,
			})
		},
		{
			target: windowRef,
		},
	)

	const clampWindowToViewport = useCallback(() => {
		if (windowRect.x + windowRect.width > window.innerWidth) {
			const x = window.innerWidth - windowRect.width

			setWindowRect({
				...windowRect,
				x: x,
			})

			api.set({
				x: x,
				y: windowRect.y,
				width: windowRect.width,
				height: windowRect.height,
			})
		}

		if (windowRect.y + 55 > window.innerHeight) {
			let y = window.innerHeight - 55
			y = y < 0 ? 0 : y

			setWindowRect({
				...windowRect,
				y: y,
			})

			api.set({
				x: windowRect.x,
				y: y,
				width: windowRect.width,
				height: windowRect.height,
			})
		}
	}, [JSON.stringify(windowRect)]) // eslint-disable-line react-hooks/exhaustive-deps

	const resizeListener = () => {
		clampWindowToViewport()
		window.addEventListener('resize', clampWindowToViewport)
		return () => window.removeEventListener('resize', clampWindowToViewport)
	}
	useEffect(resizeListener, [clampWindowToViewport])

	return createPortal(
		<animated.div
			id={documentId}
			className={twMerge('fixed top-0 left-0 z-30', className)}
			style={{
				zIndex: 30 + zIndex,
				x,
				y,
				width,
				height,
			}}
		>
			<div
				ref={windowRef}
				className={twMerge(
					'relative flex h-full w-full flex-col rounded-xl border border-gray-50 border-opacity-5',
					Translucency,
					className,
				)}
				style={{
					touchAction: 'none',
					boxShadow:
						'0 1.7px 16.8px rgba(0,0,0,0.02), 0 4.3px 27.9px rgba(0,0,0,0.03), 0 8.7px 39.6px rgba(0,0,0,0.03), 0 17.9px 53.6px rgba(0,0,0,0.04), 0 49px 80px rgba(0,0,0,0.06), 0 0 0 1px rgba(255,255,255,.05)',
				}}
			>
				<WindowInner
					title={title}
					children={children}
					headerChildren={headerChildren}
					footerChildren={footerChildren}
					bodyClassName={bodyClassName}
					headerIcon={headerIcon}
					zIndex={zIndex}
					onClose={onClose}
				/>
				<WindowResizeHandles />
			</div>
		</animated.div>,
		document.body,
	)
}

export default Window
