import { useContext, useEffect, useRef } from 'react'
import PlaceholderPage from './PlaceholderPage'
import PdfPage from './PdfPage'
import PdfViewerContext from '../../contexts/book'

type Props = {
	observer: IntersectionObserver
	containerRef: React.RefObject<HTMLDivElement>
	renderCount: number
}

const RenderPages = ({ observer, containerRef, renderCount }: Props) => {
	const { bookState } = useContext(PdfViewerContext)
	const { numPages, renderedPages, pagesToDisplay, isCoverAlone } = bookState
	const renderPasses = Math.ceil(numPages / pagesToDisplay)
	const pageRefs = useRef([]) // Create a ref array

	const renderPage = (pageNumber: number) => {
		const props = {
			key: `page_${pageNumber}`,
			pageNumber: pageNumber,
			observer,
		}

		const pageIndex = renderedPages.indexOf(pageNumber)

		if (pageIndex !== -1 && pageIndex < renderCount) {
			return <PdfPage {...props} containerRef={containerRef} />
		} else {
			return <PlaceholderPage {...props} />
		}
	}

	useEffect(() => {
		pageRefs.current.forEach(ref => {
			if (ref && observer) {
				observer.observe(ref)
			}
			return () => {
				if (ref && observer) {
					observer.unobserve(ref)
				}
			}
		})
	}, [observer])

	return (
		<>
			{Array.from({ length: renderPasses }, (_, i) => {
				return isCoverAlone && pagesToDisplay === 2 && i !== 0
					? i * pagesToDisplay
					: i * pagesToDisplay + 1
			}).map((firstPageNumber, index) => (
				<div
					ref={el => (pageRefs.current[index] = el)}
					id={`page_${firstPageNumber}`}
					key={`page_${firstPageNumber}`}
					className='flex'
				>
					{renderPage(firstPageNumber)}
					{pagesToDisplay === 2 &&
						(firstPageNumber !== 1 || !isCoverAlone) &&
						firstPageNumber + 1 <= numPages &&
						renderPage(firstPageNumber + 1)}
				</div>
			))}
		</>
	)
}

export default RenderPages
