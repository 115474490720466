import { FC, ReactElement } from 'react'
import PageTitle from '../elements/PageTitle'
import Button from '../elements/TextButton'

interface PageHeaderProps {
	title: string
	className?: string
	button?: ReactElement
	buttonOnClick?: () => void
	children?: ReactElement
}

const PageHeader: FC<PageHeaderProps> = ({
	className = '',
	title,
	button,
	buttonOnClick,
	children,
}) => {
	return (
		<header className={`flex items-baseline ${className || ''}`}>
			<PageTitle className='flex-1'>{title}</PageTitle>

			{button && (
				<Button className='flex-1' onClick={buttonOnClick}>
					{button}
				</Button>
			)}
			{children && <div className='flex flex-1 justify-end'>{children}</div>}
		</header>
	)
}

export default PageHeader
