import { UserIcon } from '@heroicons/react/24/solid'
import Color from 'color'
import { useMemo } from 'react'
import { twMerge } from 'tailwind-merge'
import useGetUserSettings from '../hooks/useGetUserSettings'
import useTheme from '../hooks/useTheme'
import Translucency from './Translucency'
import UserDot from './UserDot'

interface AvatarProps {
	color?: string
	src?: string
	className?: string
	style?: React.CSSProperties
	borderSize?: number
	userId?: string
}

const Avatar: React.FC<AvatarProps> = ({
	color,
	src,
	className,
	style,
	userId,
}) => {
	const { primary } = useTheme()
	const lighterColor = useMemo(
		() =>
			Color(color || 'gray')
				.lighten(0.7)
				.string(),
		[color],
	)
	const { userSettings } = useGetUserSettings(userId)
	const isGM = userSettings?.role === 'gm'

	const avatarContent = () => {
		if (src) {
			return null // No content needed as we're using the image as a background
		} else {
			return (
				<div
					className='font-bold tracking-wider'
					style={{
						color: lighterColor,
					}}
				>
					{isGM ? 'GM' : <UserIcon className='h-5 w-5' aria-hidden='true' />}
				</div>
			)
		}
	}

	return (
		<div
			className={twMerge(
				`relative flex aspect-square h-9 max-w-lg flex-none place-items-center justify-center overflow-hidden rounded-full bg-green-400 text-center leading-10 text-gray-600`,
				Translucency,
				className,
			)}
			style={{
				boxShadow: '0 6px 30px 0 rgba(0,0,0,.2), 0 4px 10px 0 rgba(0,0,0,0.2)',
				backgroundColor: color + '85',
				backgroundImage: src ? `url(${src})` : undefined,
				backgroundSize: 'cover',
				backgroundPosition: 'center',
				...style,
			}}
		>
			<UserDot
				color={color}
				className='absolute z-20'
				style={{
					width: '10%',
					paddingTop: '10%',
					bottom: '5%',
					boxSizing: 'border-box',
					boxShadow: `0 0 0 1px ${primary}`,
				}}
			/>
			{avatarContent()}
		</div>
	)
}

export default Avatar
