import { useMutation } from '@apollo/client'
import LightningFS, { PromisifiedFS } from '@isomorphic-git/lightning-fs'
import ObjectID from 'bson-objectid'
import { Buffer } from 'buffer'
import git from 'isomorphic-git'
import http from 'isomorphic-git/http/web'
import { useContext, useRef, useState } from 'react'
import AuthContext from '../../contexts/auth'
import DocumentsContext from '../../contexts/documents'
import GameContext from '../../contexts/game'
import { socket } from '../../contexts/socket'
import { SAVE_GAME_SYSTEM } from '../../graphql/games'
import useAssetUploader from '../../hooks/useAssetUploader'
import useGetAssetById from '../../hooks/useGetAssetById'
import useProxy from '../../hooks/useProxy'
import useRemoveAsset from '../../hooks/useRemoveAsset'
import { IMessage } from '../../interfaces/chat'
import { ICollection, ISystem } from '../../interfaces/system'
import Button from '../FormComponents/Button'
import Label from '../FormComponents/Label'
import Input from '../Input'
window.Buffer = window.Buffer || Buffer

type Props = {
	onClose: () => void
}

const SystemLoader = ({ onClose }: Props) => {
	const { game, dispatch } = useContext(GameContext)
	const { authState } = useContext(AuthContext)
	const { dispatchDocuments } = useContext(DocumentsContext)
	const dir = '/system'
	const [url, setUrl] = useState(
		game.system.repository || 'https://github.com/Heilemann/nr-traveller.git',
	)
	const fs = useRef<LightningFS>()
	const pfs = useRef<PromisifiedFS>()
	const giturlRef = useRef<HTMLInputElement>(null)
	const assetUploader = useAssetUploader()
	const { fetchAsset } = useGetAssetById()
	const { removeAsset } = useRemoveAsset()
	const proxy = useProxy()

	const [saveSystem] = useMutation(SAVE_GAME_SYSTEM, {
		onCompleted: () => {
			// tell connected players to load system from server
			socket.emit('load system', { gameId: game._id })
		},
		onError: error => {
			console.error(error)
			throw new Error('Asset upload error')
		},
	})

	const removeOldSystemAssets = async () => {
		if (!game.system) return
		try {
			const { assetIds } = game.system

			for (const assetId of assetIds) {
				const asset = fetchAsset(assetId)
				if (!asset) return
				removeAsset({
					variables: {
						gameId: game._id,
						assetId,
					},
				})
			}
		} catch (e) {
			console.log('error while removing old assets: ', e)
		}
	}

	// This function compares the filenames of the built file and certain system files
	const compareFilenames = (
		builtFileNamePath: string,
		manifestPath: string,
	) => {
		// Remove /system/ from the built path name
		builtFileNamePath = builtFileNamePath.replace('/system/', '')

		// Function to extract the base name and extension
		const getBaseNameAndExtension = (path: string) => {
			const parts = path.split('/')
			const fileName = parts[parts.length - 1]
			// Match the base name, optional hash (starting with . or -), and extension
			const match = fileName.match(/^(.+?)(?:[-.][\w]+)?(\.\w+)$/)
			if (match) {
				return { baseName: match[1], extension: match[2] }
			}
			return { baseName: fileName, extension: '' }
		}

		const built = getBaseNameAndExtension(builtFileNamePath)
		const manifest = getBaseNameAndExtension(manifestPath)

		console.log('Comparing', {
			built: `${built.baseName}${built.extension}`,
			manifest: `${manifest.baseName}${manifest.extension}`,
		})

		// Compare base names and extensions
		return (
			built.baseName === manifest.baseName &&
			built.extension === manifest.extension
		)
	}

	async function initGit() {
		setIsLoading(true)
		console.log('Starting system installation...')

		dispatchDocuments({
			type: 'CLOSE_ALL_DOCUMENTS',
		})

		try {
			console.log('Creating virtual file system...')
			// @ts-ignore: LFS bug? db shouldn't be required I think
			fs.current = new LightningFS('fs', { wipe: true })
			pfs.current = fs.current.promises
			await pfs.current.mkdir(dir)

			console.log('Cloning git repository...')
			await git.clone({
				fs: fs.current,
				http,
				dir,
				corsProxy: proxy,
				url,
				ref: 'main',
				singleBranch: true,
				depth: 1,
			})

			console.log('Reading system.json manifest...')
			let systemManifest: Uint8Array | string | null = null
			try {
				systemManifest = await pfs.current.readFile(`${dir}/src/system.json`)
			} catch (error) {
				console.error('Error reading system.json manifest:', error)
				throw new Error(
					'Failed to read system.json manifest. The repository structure might be incorrect.',
				)
			}
			const manifest = JSON.parse(systemManifest.toString())
			console.log('system.json contents:', manifest)

			console.log('Reading system code...')
			let code: Uint8Array | string | null = null
			try {
				if (manifest.code) {
					code = await pfs.current.readFile(`${dir}/${manifest.code}`)
				} else {
					throw new Error('No code path specified in system.json manifest')
				}
			} catch (error) {
				console.error('Error reading system code:', error)
				throw new Error(
					`Failed to read system code from ${manifest.code}. The file might be missing or the path in the system.json manifest might be incorrect.`,
				)
			}
			manifest.code = code.toString()

			console.log('Processing system assets...')
			const assetsPath = `${dir}/${manifest.assetsPath}`
			let systemAssets = []
			try {
				systemAssets = await pfs.current.readdir(assetsPath)
				console.log(`Found ${systemAssets.length} assets in ${assetsPath}`)
			} catch (error) {
				console.warn(`No assets found in ${assetsPath}:`, error)
				systemAssets = []
			}
			const newAssetIds = []

			// for each asset, get the file and save it to the game assets
			for (const fileName of systemAssets) {
				console.log(`Processing asset: ${fileName}`)
				const filePath = `${assetsPath}${fileName}`
				const extension = fileName.split('.').pop()
				const fileBuffer = await pfs.current.readFile(filePath)

				// supported mimetypes
				// TODO: expand this list
				const mimetypes = {
					png: 'image/png',
					jpg: 'image/jpeg',
					jpeg: 'image/jpeg',
					gif: 'image/gif',
					mp3: 'audio/mpeg',
					mp4: 'video/mp4',
					webm: 'video/webm',
					webp: 'image/webp',
					otf: 'font/otf',
					ttf: 'font/ttf',
				}

				// if the file extension isn't supported, skip it
				if (!extension || !(mimetypes as any)[extension]) {
					console.warn(`Skipping unsupported file type: ${fileName}`)
					continue
				}

				// create a file object
				const mimetype = (mimetypes as any)[extension]
				const blob = new Blob([fileBuffer])
				const file = new File([blob], fileName, { type: mimetype })

				// upload the file
				console.log(`Uploading asset: ${fileName} (${mimetype})`)
				const asset = await assetUploader(file, game._id)

				if (asset) {
					console.log(
						`Asset uploaded successfully: ${fileName} (ID: ${asset._id})`,
					)
					// add the asset id to list (to be added to the system manifest)
					newAssetIds.push(asset._id)

					// swap the old filename for the new one
					const newFileName = asset.fileurl.split('/').pop()
					console.log({ code: manifest.code, fileName, newFileName })
					const replacements =
						manifest.code.match(new RegExp(fileName, 'g'))?.length || 0
					manifest.code = manifest.code.replaceAll(fileName, newFileName)
					console.log(
						`Replaced ${replacements} occurrences of ${fileName} with ${newFileName} in manifest code`,
					)

					// if the logoPath is the same as the file being replaced, replace it
					console.log('Checking if logoPath needs to be updated...', {
						currentLogoPath: manifest.logoPath,
						filePath,
						assetId: asset._id,
					})
					if (
						manifest.logoPath &&
						compareFilenames(filePath, manifest.logoPath)
					) {
						console.log(
							`Updating logoPath from ${manifest.logoPath} to ${asset._id}`,
						)
						manifest.logoPath = asset._id
					}

					// if the coverPath is the same as the file being replaced, replace it
					console.log('Checking if coverPath needs to be updated...', {
						currentCoverPath: manifest.coverPath,
						filePath,
						assetId: asset._id,
					})
					if (
						manifest.coverPath &&
						compareFilenames(filePath, manifest.coverPath)
					) {
						console.log(
							`Updating coverPath from ${manifest.coverPath} to ${asset._id}`,
						)
						manifest.coverPath = asset._id
					}
				} else {
					console.warn(`Failed to upload asset: ${fileName}`)
				}
			}

			console.log(
				`Processed ${systemAssets.length} assets, uploaded ${newAssetIds.length} successfully`,
			)

			// sort collections alphabetically by type
			manifest.collections = manifest.collections.sort(
				(a: ICollection, b: ICollection) => {
					if (a.type < b.type) return -1
					if (a.type > b.type) return 1
					return 0
				},
			)

			// if logo and cover paths are missing, set them to empty strings
			if (!manifest.logoPath) manifest.logoPath = ''
			if (!manifest.coverPath) manifest.coverPath = ''

			// TODO: validate system config before passing it on
			// e.g. are acceptTypes present in this system/platform?

			console.log('Removing old system assets...')
			await removeOldSystemAssets()

			manifest.assetIds = newAssetIds

			console.log('Preparing modified game system...')
			let modifiedGameSystem = {
				...manifest,
				diceConfig: manifest.diceConfig || [],
			} as ISystem

			// if folder collection doesn't already exist, add it
			const folderCollectionExists = manifest.collections.find(
				(c: ICollection) => c.type === 'folder',
			)

			if (!folderCollectionExists) {
				const folderCollection = {
					singularName: 'Folder',
					pluralName: 'Folders',
					description: 'A folder to organize documents YO!',
					type: 'folder',
					allowCreate: 'false',
					acceptTypes: [],
					hasEditMode: 'false',
					defaultAccess: 'private',
				} as ICollection

				modifiedGameSystem = {
					...modifiedGameSystem,
					collections: [...modifiedGameSystem.collections, folderCollection],
					repository: url,
				}
			}

			if (manifest.chatMessageTypes) {
				modifiedGameSystem.chatMessageTypes = manifest.chatMessageTypes
			}

			console.log('Saving new system configuration...')
			await saveSystem({
				variables: {
					gameId: game._id,
					system: modifiedGameSystem,
				},
			})

			console.log('Updating game context...')
			dispatch({
				type: 'LOAD_SYSTEM',
				payload: {
					system: modifiedGameSystem,
				},
			})

			console.log('Adding system installation message...')
			const messagePayload: IMessage = {
				_id: ObjectID().toHexString(),
				sender: authState.userId,
				type: 'system',
				message: `Installed ${modifiedGameSystem.name} ${modifiedGameSystem.version}`,
				access: 'public',
				accessList: [],
				createdAt: Date.now(),
			}

			dispatch({
				type: 'ADD_MESSAGE',
				payload: messagePayload,
			})

			setIsLoading(false)
			console.log('System installation completed successfully!')

			onClose()
		} catch (e) {
			console.error('Error during system installation:', e)
			alert(`Error loading system: ${e.message}\nSee console for more details.`)
			setIsLoading(false)
		}
	}

	const [isLoading, setIsLoading] = useState(false)

	const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
		event.preventDefault()
		if (!process.env.DEVELOPMENT) {
			if (
				window.confirm(
					'Are you sure you want to load this system? This will replace the current system.',
				)
			)
				initGit()
		} else {
			initGit()
		}
	}

	return (
		<div>
			<Label htmlFor='giturl'>System Git Repository</Label>
			<form onSubmit={handleSubmit} className='flex'>
				<Input
					className='mr-2 flex-1 pt-0'
					onChange={e => setUrl(e.target.value)}
					ref={giturlRef}
					placeholder='git repo goes here'
					defaultValue={url}
					name='giturl'
					loading={isLoading}
				/>
				<Button type='submit' className='flex-0 mt-0 w-24 leading-4'>
					Install
				</Button>
			</form>
			{isLoading && (
				<>
					<div className='absolute top-0 left-0 z-10 h-full w-full bg-gray-900 bg-opacity-70'></div>
					<div className='fixed top-0 left-0 z-50 h-full w-full'></div>
				</>
			)}
		</div>
	)
}

export default SystemLoader
