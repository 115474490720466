import { useContext } from 'react'
import GameContext from '../../../contexts/game'
import { IDocument } from '../../../interfaces/document'
import MenuItem from '../../dropdownmenu/MenuItem'

type Props = {
	document: IDocument
}

const Rename = ({ document }: Props) => {
	const { dispatch } = useContext(GameContext)

	const handleRenameDocument = () => {
		const name = prompt('Enter a new name', document.values.name)

		if (!name) return

		dispatch({
			type: 'RENAME_DOCUMENT',
			payload: {
				documentId: document._id,
				name: name,
			},
		})
	}

	return <MenuItem onSelect={handleRenameDocument}>Rename...</MenuItem>
}

export default Rename
