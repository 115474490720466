import { useContext, useEffect, useMemo, useReducer } from 'react'
import { useParams } from 'react-router-dom'
import PdfViewerContext from '../../contexts/book'
import GameContext from '../../contexts/game'
import { ResourceContext } from '../../contexts/resources'
import { PdfViewerState } from '../../interfaces/book'
import reducer from '../../reducers/book'
import PDFViewer from '../resources/PDFViewer'

const initialState: PdfViewerState = {
	name: 'Loading...',
	currentPage: 1,
	numPages: null,
	renderedPages: [],
	onRenderSuccess: () => {},
	pagesToDisplay: 2,
	isCoverAlone: false,
	searchText: '',
	fitWidth: true,
	pageSize: {
		width: '50%',
		height: '90vh',
	},
	isOutlineVisible: JSON.parse(
		localStorage.getItem('outlineVisible') || 'true',
	),
	isThumbnailsVisible: JSON.parse(
		localStorage.getItem('thumbnailVisible') || 'false',
	),
	loading: [],
}

const BookPopout = () => {
	const { game } = useContext(GameContext)
	const [bookState, bookDispatch] = useReducer(reducer, initialState)
	const { resources } = useContext(ResourceContext)
	const { bookId } = useParams()
	const page = new URLSearchParams(window.location.search).get('page')
	const book = game.books.byId[bookId]
	const resource = useMemo(
		() => resources[book.resourceId],
		[resources, book.resourceId],
	)
	const name = resource?.name

	const setWindowTitle = () => {
		if (name) document.title = name + ' - ' + game.title + ' — Hi Adventure'
		else document.title = 'Hi Adventure'

		document.body.className = 'document'
		document.body.style.backgroundColor = 'black'
		document.documentElement.style.backgroundColor = 'black'

		return () => {
			document.title = 'Hi Adventure'
			document.body.className = ''
		}
	}

	useEffect(setWindowTitle, [name])

	if (resource === undefined) return null

	return (
		<PdfViewerContext.Provider value={{ bookState, bookDispatch }}>
			<PDFViewer resource={resource} page={page} isGame={true} />
		</PdfViewerContext.Provider>
	)
}

export default BookPopout
