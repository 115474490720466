import { yupResolver } from '@hookform/resolvers/yup'
import { useContext } from 'react'
import { FieldValues, useForm } from 'react-hook-form'
import { NavLink, useNavigate } from 'react-router-dom'
import request from 'superagent'
import * as yup from 'yup'
import AuthContext from '../../contexts/auth'
import Button from '../FormComponents/Button'
import Label from '../FormComponents/Label'
import Input from '../Input'
import Card from './elements/Card'
import PageHeader from './sections/PageHeader'

interface IFormInputs {
	email: string
	password: string
}

const schema = yup
	.object({
		email: yup.string().email().required(),
		password: yup.string().required(),
	})
	.required()

const AuthPage = () => {
	const navigate = useNavigate()
	const { authState, authDispatch } = useContext(AuthContext)

	const {
		register,
		handleSubmit,
		formState: { errors },
	} = useForm<IFormInputs>({
		resolver: yupResolver(schema),
		reValidateMode: 'onChange',
	})

	const loginHandler = (data: FieldValues) => {
		const { email, password } = data

		request
			.post('/api/login')
			.send({
				email: email,
				password: password,
			})
			.then(res => {
				if (!res.ok) {
					alert('Something went wrong.')
				}
				if (res.status === 401) {
					alert('Signing in failed.')
				}
				if (res.status !== 200 && res.status !== 201) {
					throw new Error('Failed!')
				}

				console.log(res.body)

				authState.login(res.body.token, res.body.user)
				localStorage.setItem('token', res.body.token)
				localStorage.setItem('user', res.body.user._id)

				authDispatch({
					type: 'UPDATE_ALL',
					payload: {
						userId: res.body.user._id,
						token: res.body.token,
						userDetails: {
							name: res.body.user.name,
							avatar: res.body.user.avatar || '',
							email: res.body.user.email,
						},
					},
				})

				navigate('/profile')
			})
			.catch(error => {
				alert(error)
				console.log('sign in error', error)
			})
	}

	return (
		<Card className='h-screen'>
			<PageHeader title='Sign In' className='text-center' />
			<div className='mb-12 text-center'>
				<NavLink to='/signup' className='text-gray-400 underline'>
					Sign Up Instead?
				</NavLink>
			</div>

			<form onSubmit={handleSubmit(loginHandler)}>
				<Label>Email</Label>
				<Input
					type='email'
					placeholder='Email address...'
					inputClassName='dark:bg-gray-900'
					{...register('email', { required: true })}
				/>
				{errors.email && <p className='text-red-500'>Email is required</p>}

				<Label>Password</Label>
				<Input
					type='password'
					placeholder='Password...'
					className='-mb-0'
					inputClassName='dark:bg-gray-900'
					{...register('password', { required: true })}
				/>
				{errors.password && (
					<p className='text-red-500'>Password is required</p>
				)}
				<p className='mb-12 mt-1 text-sm text-gray-400 underline'>
					Forgot password?
				</p>

				<Button type='submit'>Sign In</Button>
			</form>
		</Card>
	)
}

export default AuthPage
