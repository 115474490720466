import Color from 'color'
import { useContext, useRef } from 'react'
import { useDrag, useDrop } from 'react-dnd'
import { twMerge } from 'tailwind-merge'
import GameContext from '../../contexts/game'
import useTheme from '../../hooks/useTheme'
import Input from '../Input'
import Translucency from '../Translucency'
import ItemTypes from '../draganddrop/ItemTypes'
import Document from './Document'
import OpenDocumentButton from './OpenDocumentButton'
import RemoveButton from './RemoveButton'
import ToggleActiveButton from './ToggleActiveButton'
import ToggleIdentifiedButton from './ToggleIdentifiedButton'

interface DragItem {
	id: string
}

interface Props {
	id: string
}

const Item = ({ id }: Props) => {
	const { game, dispatch } = useContext(GameContext)
	const { currentRound, currentTurnIndex, turnOrder } = game.turnTracker
	const isStopped = currentRound === 0
	const { name, initiative, isActive, identified, documentId } =
		turnOrder.byId[id]
	const isMyTurn = currentTurnIndex === turnOrder.allIds.indexOf(id)
	const ref = useRef<HTMLDivElement>(null)
	const { primary } = useTheme()
	const fadedPrimaryColor = Color(primary).fade(0.7).string()

	const handleInitiativeChange = (newInitiative: number) => {
		dispatch({
			type: 'SET_TURN_TRACKER_ITEM_INITIATIVE',
			payload: {
				id,
				initiative: newInitiative,
			},
		})
	}

	const [, drop] = useDrop({
		accept: [
			ItemTypes.TURN_ORDER_ITEM,
			// , ItemTypes.DOCUMENT
		],
		hover(item: DragItem, monitor) {
			const draggedId = item.id
			if (draggedId === id) {
				return
			}

			const ownIndex = turnOrder.allIds.indexOf(id)
			const draggedIndex = turnOrder.allIds.indexOf(draggedId)

			const boundingRect = ref.current?.getBoundingClientRect()
			if (!boundingRect) {
				return
			}

			const ownMiddleY = (boundingRect.bottom - boundingRect.top) / 2
			const clientOffset = monitor.getClientOffset()
			const hoverClientY = clientOffset.y - boundingRect.top

			if (draggedIndex < ownIndex && hoverClientY < ownMiddleY) {
				return
			}

			if (draggedIndex > ownIndex && hoverClientY > ownMiddleY) {
				return
			}

			dispatch({
				type: 'SORT_TURN_ORDER',
				payload: {
					dragId: draggedId,
					hoverId: id,
				},
			})
		},
	})

	const [{ isDragging }, drag] = useDrag({
		type: ItemTypes.TURN_ORDER_ITEM,
		item: { id },
		collect: monitor => ({
			isDragging: monitor.isDragging(),
		}),
	})

	drag(drop(ref))

	return (
		<div
			ref={ref}
			className={twMerge(
				Translucency,
				'mb-1 flex cursor-grab justify-between rounded-lg border-none bg-white/5 py-1 px-1 pr-2',
				isDragging && 'opacity-0',
			)}
			style={{
				backgroundColor: isMyTurn && !isStopped ? fadedPrimaryColor : '',
				// outline: isMyTurn && !isStopped ? `1px solid ${primary}` : '',
			}}
		>
			<div>
				<div
					className={twMerge(
						'flex items-center space-x-2 text-sm',
						isActive ? 'opacity-100:' : 'opacity-40',
					)}
				>
					<ToggleActiveButton id={id} />
					<Input
						className='w-8 pt-0'
						value={initiative}
						onChange={e => handleInitiativeChange(Number(e.target.value))}
						inputClassName='py-1 text-center px-0'
					/>
					{documentId ? (
						<Document documentId={documentId} />
					) : (
						<div>{name}</div>
					)}
				</div>
				<div className='text-xs text-gray-500'>
					{[
						...(!isActive ? ['Inactive'] : []),
						...(!identified ? ['Unidentified'] : []),
					].join(' • ')}
				</div>
			</div>
			<div className='flex items-center space-x-2'>
				{documentId && <OpenDocumentButton documentId={documentId} />}
				<ToggleIdentifiedButton id={id} />
				<RemoveButton id={id} />
			</div>
		</div>
	)
}

export default Item
