import AIGamemaster from './FeatureFlags/AIGamemaster'
import Maps from './FeatureFlags/Maps'
import Syrinscape from './FeatureFlags/Syrinscape'

const SettingsFeatureFlags = () => {
	return (
		<div>
			<div className='center mt-4 align-middle'>
				<Maps />
				<Syrinscape />
				<AIGamemaster />

				{/* <Switch.Root
					className='bg-blackA9 shadow-blackA7 relative h-[25px] w-[42px] cursor-default rounded-full shadow-[0_2px_10px] outline-none focus:shadow-[0_0_0_2px] focus:shadow-black data-[state=checked]:bg-black'
					id='airplane-mode'
				>
					<Switch.Thumb className='shadow-blackA7 block h-[21px] w-[21px] translate-x-0.5 rounded-full bg-white shadow-[0_2px_2px] transition-transform duration-100 will-change-transform data-[state=checked]:translate-x-[19px]' />
				</Switch.Root> */}
			</div>
		</div>
	)
}

export default SettingsFeatureFlags
