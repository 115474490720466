import { Reducer } from 'react'
import { TUsersAction, IUsersState } from '../interfaces/users'

const UsersReducer: Reducer<IUsersState, TUsersAction> = (state, action) => {
	const { type, payload } = action

	switch (type) {
		case 'UPDATE_SOCKET_USER_CONNECTION': {
			const userIndex = state.users.findIndex(u => u.userId === payload.userId)
			if (userIndex === -1) {
				// User not found, return the original state
				return state
			}

			const user = state.users[userIndex]
			if (user.socketId === payload.socketId) {
				// Socket ID hasn't changed, return the original state
				return state
			}

			// Update the user and create a new users array
			const updatedUser = { ...user, socketId: payload.socketId }
			const updatedUsers = [...state.users]
			updatedUsers[userIndex] = updatedUser

			return {
				...state,
				users: updatedUsers,
			}
		}

		case 'UPDATE_USERS': {
			return {
				...state,
				users: [...payload.users],
			}
		}
	}
}

export default UsersReducer
