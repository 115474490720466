// Tabs.tsx
import { NavLink } from 'react-router-dom'

const Tabs = () => {
	return (
		<>
			<NavLink
				to='/profile'
				className={({ isActive }) => (isActive ? '' : 'opacity-50')}
			>
				Games
			</NavLink>
			<NavLink
				to='/profile/books'
				className={({ isActive }) => (isActive ? '' : 'opacity-50')}
			>
				Books
			</NavLink>
		</>
	)
}

export default Tabs
