import Color from 'color'
import React from 'react'
import { useFormContext, useWatch } from 'react-hook-form'
import { twMerge } from 'tailwind-merge'
import useCollectionTypes from '../../hooks/useCollectionTypes'
import useTheme from '../../hooks/useTheme'
import Translucency from '../Translucency'
import SmallUppercase from '../text/SmallUppercase'

type Props = {
	label: string | React.ReactNode
	type: string
}

const DocumentTypeListItem = ({ label, type }: Props) => {
	const { primary } = useTheme()
	const { register } = useFormContext()
	const collectionTypes = useCollectionTypes()
	const selectedType = useWatch({
		name: 'selection',
		defaultValue: collectionTypes[0],
	})
	const fadedPrimaryColor = Color(primary).fade(0.8).string()

	return (
		<li className='mx-0.5 inline-block'>
			<input
				className='hidden'
				type='radio'
				value={type}
				id={type}
				{...register('selection')}
			/>
			<label
				htmlFor={type}
				className={twMerge(
					Translucency,
					`flex min-w-[3.5rem] cursor-pointer items-center rounded-full px-3 py-1 transition-colors duration-100`,
				)}
				style={{
					backgroundColor: selectedType === type ? primary : fadedPrimaryColor,
				}}
			>
				<SmallUppercase
					className={twMerge('w-full text-center')}
					style={{
						color: selectedType === type ? 'black' : primary,
					}}
				>
					{label}
				</SmallUppercase>
			</label>
		</li>
	)
}

export default DocumentTypeListItem
