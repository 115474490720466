import { Fragment, useContext } from 'react'
import { twMerge } from 'tailwind-merge'
import GameContext from '../../contexts/game'
import Document from './Document'
import Folder from './Folder'

type Props = {
	parentId: string
	dragIndex: number
	hoverIndex: number
	hoverItem: any
	moveItem: any
	selectedType: string
}

export default function List({
	parentId,
	dragIndex,
	hoverIndex,
	hoverItem,
	moveItem,
	selectedType,
}: Props) {
	const { game } = useContext(GameContext)

	const renderChild = (parentId: string) => {
		return game.documents.allIds
			.map(documentId => game.documents.byId[documentId])
			.filter(doc => doc.parentId === parentId)
			.sort((a, b) => {
				const nameA = a.values.name.toUpperCase()
				const nameB = b.values.name.toUpperCase()
				if (nameA < nameB) return -1
				if (nameA > nameB) return 1
				return 0
			})
			.map(doc => {
				const props = {
					id: doc._id,
					dragIndex,
					hoverIndex,
					hoverItem,
					moveItem,
					selectedType,
				}

				let childComponent: JSX.Element

				if (doc.type === 'folder') {
					childComponent = <Folder {...props} />
				} else {
					childComponent = <Document {...props} />
				}

				return <Fragment key={doc._id}>{childComponent}</Fragment>
			})
	}

	return (
		<ul
			className={twMerge('overflow-auto', game.library.show ? '' : 'hidden')}
			style={{
				minHeight: '200px',
			}}
		>
			{renderChild(parentId)}
		</ul>
	)
}
