import { useCallback, useContext, useEffect, useState } from 'react'
import GameContext from '../../contexts/game'
import { useHandleUpload } from '../../hooks/useHandleUpload'
import { TDocumentModes } from '../../interfaces/document'
import { IPostMessage, IPostMessageData } from '../../interfaces/postmessages'
import { getAuxServerDetails } from '../../utils/getServerDetails'
import useHandleDelete from './useHandleDelete'
import useHandleDocumentModeChange from './useHandleDocumentModeChange'
import useHandleFrameFocus from './useHandleFrameFocus'
import useHandleFrameLoad from './useHandleFrameLoad'
import useHandleGeneratorMessage from './useHandleGeneratorMessage'
import useHandleMessage from './useHandleMessage'
import useHandleOpenDocument from './useHandleOpenDocument'
import useHandleRemoveAsset from './useHandleRemoveAsset'
import useHandleSaveMessage from './useHandleSaveMessage'
import useHandleSetScene from './useHandleSetScene'
import useSyncDataToFrame from './useSyncDataToFrame'

type Props = {
	documentId: string
	messageTarget: Window | null
	documentMode: TDocumentModes
	onClose: () => void
}

const useDocumentMessaging = ({
	documentId,
	messageTarget,
	documentMode,
	onClose,
}: Props) => {
	const { game } = useContext(GameContext)
	const [frameIsLoaded, setFrameIsLoaded] = useState(false)
	const { origin: frameOrigin } = getAuxServerDetails()
	const doc = game.documents.byId[documentId]
	const collection =
		game.system?.collections?.find(t => t.type === doc.type) || null
	const hasEditMode = collection?.hasEditMode ?? false

	const messageToFrame = useCallback(
		(message: string, bundle?: IPostMessageData) => {
			const data = bundle ? { ...bundle, id: documentId } : { id: documentId }
			const isNotReady = !messageTarget || !frameIsLoaded
			const messageIsNotOnLoad = message !== 'load'

			if (isNotReady && messageIsNotOnLoad) return

			const postMessage: IPostMessage = {
				source: 'App',
				message,
				data,
			}

			if (!messageTarget?.postMessage) {
				console.warn('messageTarget has no postMessage method', messageTarget)
				return
			}

			messageTarget.postMessage(postMessage, frameOrigin)
		},
		[frameOrigin, documentId, frameIsLoaded, messageTarget],
	)

	const loadPayload = {
		documentId,
		documentMode: hasEditMode === 'true' ? documentMode : 'edit',
		messageToFrame,
		setFrameIsLoaded,
	}

	const handleFrameLoad = useHandleFrameLoad(loadPayload)
	const handleUpload = useHandleUpload(doc)
	const handleGeneratorMessage = useHandleGeneratorMessage({ documentId })
	const handleRemoveAsset = useHandleRemoveAsset()
	const handleSaveMessage = useHandleSaveMessage({ documentId })
	const handleFrameFocus = useHandleFrameFocus({ documentId })
	const handleDocumentModeChange = useHandleDocumentModeChange({
		documentMode: hasEditMode === 'true' ? documentMode : 'edit',
		messageToFrame,
	})
	const handleOpenDocument = useHandleOpenDocument()
	const handleSetScene = useHandleSetScene()
	const handleMessage = useHandleMessage()

	useSyncDataToFrame({ frameIsLoaded, messageToFrame })

	useHandleDelete({
		documentId,
		frameIsLoaded,
		onClose,
	})

	useEffect(handleDocumentModeChange, [handleDocumentModeChange])

	const frameMessagingListener = (e: MessageEvent) => {
		const messageData = e?.data ?? {}
		const { message, data } = messageData ?? {}
		const { payload } = data ?? {}
		const originatingDocumentId = data?.documentId ?? null
		const wrongOrigin = e.origin !== frameOrigin
		const wrongSource = messageData.source !== 'Aux'
		const wrongDocument = originatingDocumentId !== documentId
		const isNotLoadMessage = message !== 'load'

		// slight complexity here. At first the new frame has no documentId,
		// so it sends a load message, which we handle. Then afterwards, when
		// messages arrive, we filter them by documentId. This is to prevent
		// messages from other frames being handled by this frame.
		if (wrongDocument && isNotLoadMessage) return

		// moreover we guard against a few other things here
		if (!messageTarget || wrongOrigin || wrongSource) return

		console.log('frameMessagingListener', e)

		// This is the frame -> parent API:
		switch (message) {
			// tells the parent that the frame has loaded and is ready for messages and payload
			case 'load':
				handleFrameLoad()
				break

			// saves the given document
			case 'save':
				handleSaveMessage(payload)
				break

			// hack focus frame on clicks on the window.
			case 'focus':
				handleFrameFocus()
				break

			// allow the frame to trigger asset uploads
			case 'upload asset': {
				const { name } = data
				const documentId = originatingDocumentId
				handleUpload({ name, documentId })
				break
			}

			// allow the frame to trigger asset removals
			case 'remove asset': {
				handleRemoveAsset({ assetId: data.assetId })
				break
			}
			// allow the frame to send messages to chat
			case 'send message': {
				console.log('send message', payload.payload)
				handleMessage(payload)
				break
			}

			// allow the frame to set the active scene
			case 'set scene': {
				const { sceneId } = payload
				handleSetScene({ sceneId })
				break
			}

			// allow the frame to open document windows
			case 'open document':
				handleOpenDocument(payload)
				break

			// allow the frame generate and return the value of a GPT prompt
			case 'generate': {
				const { name, prompt } = payload
				handleGeneratorMessage(name, prompt)
				break
			}

			// handle when the window is closed from a popout window
			case 'unload':
				onClose()
				break
		}
	}

	const initMessageListener = () => {
		window.addEventListener('message', frameMessagingListener)
		return () => window.removeEventListener('message', frameMessagingListener)
	}

	useEffect(initMessageListener, [
		doc,
		documentId,
		game.documents,
		game.assets,
		messageTarget,
	])
}

export default useDocumentMessaging
