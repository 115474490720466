import { useContext } from 'react'
import UsersContext from '../../contexts/users'
import Overlapper from '../Overlapper'
import UserHead from './UserHead'

export default function UserHeads() {
	const { usersState } = useContext(UsersContext)

	if (!usersState || !usersState.users) return null

	return (
		<div className='mx-auto w-4/5'>
			<Overlapper className='space-x-2' hoverScale={1.5} centered>
				{[
					...usersState.users.map(user => (
						<UserHead key={user.userId} user={user} />
					)),
					// <AddPlayerButton key='addPlayerButton' />,
				]}
			</Overlapper>
		</div>
	)
}
