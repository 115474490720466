import { useMutation } from '@apollo/client'
import { useContext } from 'react'
import { Link } from 'react-router-dom'
import AuthContext from '../../../contexts/auth'
import { socket } from '../../../contexts/socket'
import { DELETE_GAME, LEAVE_GAME } from '../../../graphql/games'
import { IGameCard } from '../../../interfaces/game'
import GameCard from './GameCard'

export interface IGameCardContainerProps {
	game: IGameCard
	refetch?: () => void
	className?: string
}

export default function GameCardContainer({
	game,
	refetch,
	className,
}: IGameCardContainerProps) {
	const { authState } = useContext(AuthContext)
	const gameId = game._id
	// @ts-ignore - creator is populated in the query
	const creatorId: string = game.creator?._id
	const userIsCreator = creatorId === authState.userId

	const [deleteGame] = useMutation(DELETE_GAME, {
		onCompleted: refetch,
	})

	const [leaveGame] = useMutation(LEAVE_GAME, {
		onCompleted: () => {
			socket.emit('userDataUpdated', gameId)
			refetch()
		},
	})

	const deleteGameHandler = (gameId: string, title: string) => {
		const confirmed = window.confirm(
			`Delete the game '${title}'? This cannot be undone.`,
		)

		if (!confirmed) {
			return
		}

		// TODO: Delete game from users' joined games list
		// can't use dispatch, have to do it on the server
		deleteGame({
			variables: { gameId: gameId },
		})
	}

	const leaveGameHandler = (gameId: string, title: string) => {
		const confirmed = window.confirm(
			`Leave the game '${title}'? You will no longer be able to join the game unless you're reinvited.`,
		)

		if (!confirmed) {
			return
		}

		leaveGame({
			variables: { gameId: gameId, userId: authState.userId },
		})
	}

	return (
		<div className={className}>
			<Link key={game._id} to={'/game/' + game._id}>
				<GameCard game={game} />
			</Link>

			<p>{game.title}</p>

			{userIsCreator && (
				<div className='flex justify-center'>
					<button
						onClick={() => {
							deleteGameHandler(gameId, game.title)
						}}
						className='mb-3 p-2 pl-0 text-red-700'
					>
						Delete
					</button>
				</div>
			)}

			{!userIsCreator && (
				<div className='flex justify-center'>
					<button
						onClick={() => {
							leaveGameHandler(gameId, game.title)
						}}
						className='mb-3 p-2 pl-0 text-red-700'
					>
						Leave
					</button>
				</div>
			)}
		</div>
	)
}
