/* eslint-disable react-hooks/exhaustive-deps */
import { useContext, useMemo, useState } from 'react'
import GameContext from '../../contexts/game'
import useOrigin from '../../hooks/UseOrigin'
import useFrameUrl from '../../hooks/useFrameUrl'
import useGetCollectionName from '../../hooks/useGetCollectionName'
import { IDocument, TDocumentModes } from '../../interfaces/document'
import WindowModeToggle from '../WindowModeToggle'
import useDocumentMessaging from '../document/useDocumentMessaging'
import Menu from '../documentmenu/Menu'
import DroppableFrame from '../draganddrop/DroppableFrame'
import Window from '../window/Window'
import AssumeAsCharacterButton from './AssumeAsCharacterButton'
import DocumentWindowMenuButton from './MenuButton'
import PopoutButton from './PopoutButton'
import DocumentWindowTitle from './Title'

interface DocumentWindowProps {
	documentId: string
	onClose: () => void
}

const emptyDocument: IDocument = {
	_id: '',
	type: '',
	creator: '',
	access: 'private',
	accessList: [],
	values: {},
} as IDocument

const DocumentWindow = ({
	documentId,
	onClose = () => {},
}: DocumentWindowProps) => {
	const { game } = useContext(GameContext)
	const [documentMode, setDocumentMode] = useState<TDocumentModes>('view')
	const { origin } = useOrigin()
	const [messageTarget, setMessageTarget] = useState<Window | null>(null)
	const document = useMemo(
		() => game.documents.byId[documentId] || emptyDocument,
		[game.documents, game.assets, documentId],
	)
	const collection =
		game.system?.collections?.find(t => t.type === document.type) || null
	const { singularName } = useGetCollectionName(document)
	const title = document.values?.name || `Untitled ${singularName}`
	const frameUrl = useFrameUrl({ documentId })

	const isDevelopmentModeActive = JSON.parse(
		localStorage.getItem(`isDevelopmentModeActive_${game._id}`) || 'false',
	)
	const developmentOrigin = localStorage.getItem(
		`developmentOrigin_${game._id}`,
	)

	// set up post message communication with the iframe
	useDocumentMessaging({
		documentId,
		messageTarget,
		documentMode,
		onClose,
	})

	const handlePop = () => {
		const url = new URL(`${origin}/document/${game._id}/${documentId}`)

		if (isDevelopmentModeActive && developmentOrigin) {
			url.searchParams.append('developmentOrigin', developmentOrigin)
		}

		window.open(url.toString(), '_blank', 'width=800,height=600')

		onClose()
	}

	if (!document._id) return null

	return (
		<Window
			key={documentId}
			documentId={documentId}
			title={DocumentWindowTitle({ title, singularName, document })}
			open={true}
			onClose={onClose}
			bodyClassName='p-0 overflow-hidden'
			size={collection?.windowSize || 'small'}
			headerIcon={
				<Menu document={document}>
					<DocumentWindowMenuButton />
				</Menu>
			}
			headerChildren={
				<>
					{collection?.canAssumeAsCharacter === 'true' && (
						<AssumeAsCharacterButton characterId={document._id} />
					)}
					{collection?.hasEditMode === 'true' && (
						<WindowModeToggle
							documentMode={documentMode}
							setDocumentMode={setDocumentMode}
						/>
					)}
					<PopoutButton handlePop={handlePop} />
				</>
			}
		>
			<DroppableFrame
				src={frameUrl}
				messageTarget={messageTarget}
				setMessageTarget={setMessageTarget}
				onClose={onClose}
			/>
		</Window>
	)
}

export default DocumentWindow
