import { useContext } from 'react'
import GameContext from '../../../contexts/game'
import useDocumentToToken from '../../../hooks/useDocumentToToken'
import useFlags from '../../../hooks/useFlags'
import useGetActiveScene from '../../../hooks/useGetActiveScene'
import { IDocument } from '../../../interfaces/document'
import MenuItem from '../../ContextMenu/Item'

type Props = {
	document: IDocument
}

const AddToMapMenuItem = ({ document }: Props) => {
	const { dispatch } = useContext(GameContext)
	const { _id: sceneId } = useGetActiveScene()
	const token = useDocumentToToken({ documentId: document._id })
	const { maps } = useFlags()

	if (!maps) return null

	const handleAddToMap = () => {
		dispatch({
			type: 'ADD_TOKEN',
			payload: {
				sceneId,
				token,
			},
		})
	}

	return <MenuItem onSelect={handleAddToMap}>Add to Map</MenuItem>
}

export default AddToMapMenuItem
