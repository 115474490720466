import { forwardRef, useRef, useState } from 'react'
import { twMerge } from 'tailwind-merge'
import useTheme from '../../hooks/useTheme'
import Tooltip from '../Tooltip'

interface ToolbarControlButtonProps {
	children: React.ReactNode
	className?: string
	innerClassName?: string
	onClick?: () => void
	type?: 'button' | 'submit' | 'reset' | undefined
	label?: string
	isOpen?: boolean
	style?: React.CSSProperties
	hideLabel?: boolean
	disabled?: boolean
	innerStyle?: React.CSSProperties
}

const ToolbarControlButton = forwardRef<
	HTMLButtonElement,
	ToolbarControlButtonProps
>(
	(
		{
			children,
			className,
			innerClassName,
			onClick,
			type = 'button',
			label,
			isOpen = false,
			hideLabel = false,
			disabled,
			style,
			innerStyle,
		},
		ref,
	) => {
		const [showLabel, setShowLabel] = useState(false)
		const innerRef = useRef(null)
		const { primary } = useTheme()

		return (
			<>
				<button
					ref={ref}
					type={type}
					disabled={disabled}
					onClick={onClick}
					onMouseEnter={() => setShowLabel(true)}
					onMouseLeave={() => setShowLabel(false)}
					className={twMerge(
						'relative flex h-9 w-9 flex-none cursor-pointer rounded-full hover:bg-white/20 focus:outline-none',
						disabled ? 'hover:bg-none' : '',
						className,
					)}
					style={
						isOpen
							? {
									boxShadow:
										'0 1.7px 16.8px rgba(0,0,0,0.02), 0 4.3px 27.9px rgba(0,0,0,0.03), 0 8.7px 39.6px rgba(0,0,0,0.03), 0 17.9px 53.6px rgba(0,0,0,0.04), 0 49px 80px rgba(0,0,0,0.06), 0 0 0 1px rgba(255,255,255,.05)',
									backgroundColor: primary,
									...style,
							  }
							: {}
					}
				>
					<div
						ref={innerRef}
						className={twMerge(
							'absolute flex h-full w-full items-center justify-center',
							innerClassName,
						)}
						style={innerStyle}
					>
						{children}
					</div>
				</button>

				{label && (
					<Tooltip
						placement='bottom'
						open={showLabel && !hideLabel}
						anchor={innerRef}
					>
						{label}
					</Tooltip>
				)}
			</>
		)
	},
)
export default ToolbarControlButton
