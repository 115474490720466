import { IResource } from '../../../shared/types/resources'
import useApi from './useApi'
import usePdfTextExtractor from './usePdfTextExtractor'
import useResourceUpload from './useResourceUpload'
import useSummarizeText from './useSummarizeText'

const useProcessBook = () => {
	const uploadResource = useResourceUpload()
	const { request } = useApi()
	const { extractText } = usePdfTextExtractor()
	const summarizeText = useSummarizeText()

	const generateEmbeddings = async (
		text: string,
		bookId: string,
		bookTitle: string,
	): Promise<{ success: boolean; message: string }> => {
		const token = localStorage.getItem('token')
		const url = '/api/generate-embeddings'
		const options = {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${token}`,
			},
			body: JSON.stringify({ text, bookId, bookTitle }),
		}

		try {
			const data = await request(url, options)
			return {
				success: true,
				message: data.message || 'Embeddings generated and stored successfully',
			}
		} catch (error) {
			console.error('Error generating embeddings:', error)
			if (error instanceof Error && error.message.includes('already exists')) {
				return {
					success: true,
					message: 'Embeddings already exist for this book',
				}
			} else {
				return {
					success: false,
					message:
						error instanceof Error
							? error.message
							: 'Failed to generate embeddings',
				}
			}
		}
	}

	const processBook = async (file: File) => {
		try {
			const resource: IResource = await uploadResource(file)
			const extractedText = await extractText(file)
			const summary = await summarizeText(extractedText, resource.name)
			const embeddingsResult = await generateEmbeddings(
				extractedText,
				resource._id,
				resource.name,
			)

			if (!embeddingsResult.success) {
				console.error(
					'Failed to generate embeddings:',
					embeddingsResult.message,
				)
			}

			return { resource, summary, embeddingsResult }
		} catch (error) {
			console.error('Error processing book:', error)
			throw error
		}
	}

	return { processBook }
}

export default useProcessBook
