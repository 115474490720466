/* eslint-disable react-hooks/exhaustive-deps */
import { useContext, useMemo } from 'react'
import GameContext from '../../../contexts/game'
import useGetCollectionName from '../../../hooks/useGetCollectionName'
import { IDocument } from '../../../interfaces/document'
import Menu from '../../documentmenu/Menu'
import Window from '../../window/Window'
import DocumentWindowMenuButton from '../MenuButton'
import DocumentWindowTitle from '../Title'
import SceneEditor from './SceneEditor'

interface SceneWindowProps {
	documentId: string
	onClose: () => void
}

const emptyDocument: IDocument = {
	_id: '',
	type: '',
	creator: '',
	access: 'private',
	accessList: [],
	values: {},
} as IDocument

const SceneWindow = ({ documentId, onClose = () => {} }: SceneWindowProps) => {
	const { game } = useContext(GameContext)
	// const [documentMode, setDocumentMode] = useState<TDocumentModes>('view')
	const document = useMemo(
		() => game.documents.byId[documentId] || emptyDocument,
		[game.documents, game.assets, documentId],
	)
	const collection =
		game.system?.collections?.find(t => t.type === document.type) || null
	const { singularName } = useGetCollectionName(document)
	const title = document.values?.name || `Untitled ${singularName}`

	if (!document._id) return null

	return (
		<Window
			key={documentId}
			documentId={documentId}
			title={DocumentWindowTitle({ title, singularName, document })}
			open={true}
			onClose={onClose}
			bodyClassName='p-0'
			size={collection?.windowSize || 'small'}
			headerIcon={
				<Menu document={document}>
					<DocumentWindowMenuButton />
				</Menu>
			}
		>
			<SceneEditor doc={document} />
		</Window>
	)
}

export default SceneWindow
