import { useContext, useRef } from 'react'
import { useDrop } from 'react-dnd'
import GameContext from '../../contexts/game'
import useUser from '../../hooks/useUser'
import { generateUUID } from '../../utils/generateUUID'
import SectionDivider from '../SectionDivider'
import ItemTypes from '../draganddrop/ItemTypes'
import ListMessage from '../views/ListMessage'
import AddTurnOrderItemButton from './AddTurnOrderItemButton'
import Item from './Item'
import TurnTrackerControls from './TurnTrackerControls'

interface DragItem {
	id: string
}

const GMTurnTracker = () => {
	const { game, dispatch } = useContext(GameContext)
	const { turnOrder, currentRound } = game.turnTracker
	const listRef = useRef<HTMLDivElement>(null)
	const { isGM } = useUser()

	const handleDrop = (item: DragItem, monitor) => {
		const itemType = monitor.getItemType()

		if (itemType === ItemTypes.DOCUMENT) {
			const document = game.documents.byId[item.id]
			if (!document?.values) return
			const { name } = document.values
			dispatch({
				type: 'ADD_TO_TURN_ORDER',
				payload: {
					item: {
						id: generateUUID(),
						name: name || 'Unnamed',
						initiative: 0,
						isActive: true,
						visibility: true,
						isTurn: false,
						identified: true,
						documentId: item.id,
						relationship: 'neutral',
					},
					index: turnOrder.allIds.length, // add to end
				},
			})
		}
	}

	const [, drop] = useDrop({
		accept: [ItemTypes.DOCUMENT, ItemTypes.TURN_ORDER_ITEM],
		drop: handleDrop,
	})

	drop(listRef)

	if (!game.turnTracker.show) return null

	if (!isGM) return null

	return (
		<>
			<SectionDivider
				label={currentRound ? `Round ${currentRound}` : 'Turn Tracker'}
				className='mb-1 mt-0'
			>
				<AddTurnOrderItemButton />
			</SectionDivider>

			<TurnTrackerControls />

			<div
				className='turn-tracker-dropzone flex flex-col pb-2'
				style={{ minHeight: '3rem' }}
				ref={listRef}
			>
				{turnOrder.allIds.length === 0 && (
					<div className='flex h-32 flex-col items-center justify-center'>
						<ListMessage title='Drop Documents Here' />
					</div>
				)}

				{turnOrder.allIds.length > 0 &&
					turnOrder.allIds.map(id => {
						const item = turnOrder.byId[id]
						if (!item) {
							console.error(`No item found for id: ${id}`)
							return null
						}
						return <Item id={item.id} key={item.id} />
					})}
			</div>
		</>
	)
}

export default GMTurnTracker
