import { useCallback, useContext } from 'react'
import ObjectID from 'bson-objectid'
import { TAccess } from '../../interfaces/document'
import { IMessage } from '../../interfaces/chat'
import GameContext from '../../contexts/game'
import { useDrop } from 'react-dnd'
import ItemTypes from '../draganddrop/ItemTypes'

interface UseHandleDropProps {
	userId: string
	userProfile: { name: string; avatar?: string }
}

const useHandleDrop = ({ userId, userProfile }: UseHandleDropProps) => {
	const { game, dispatch } = useContext(GameContext)
	const { documents } = game

	const handleDrop = useCallback(
		({ id }) => {
			if (!id) return
			const document = documents.byId[id]

			if (document?.access === 'private') {
				const confirm = window.confirm(
					'This document is private. Make it public before sharing?',
				)
				if (confirm) {
					dispatch({
						type: 'UPDATE_DOCUMENT',
						payload: {
							updatedDocument: {
								...document,
								access: 'public' as TAccess,
							},
						},
					})
				}
			}

			if (!id) return

			const payload: IMessage = {
				_id: ObjectID().toHexString(),
				sender: userId,
				type: 'document',
				message: '',
				attachmentId: id,
				access: 'public',
				accessList: [],
				assumedCharacterId: '',
				fallbackName: userProfile.name,
				fallbackAvatarId: userProfile.avatar || '',
			}

			dispatch({
				type: 'ADD_MESSAGE',
				payload,
			})
		},
		[dispatch, documents, userId, userProfile],
	)

	const [, drop] = useDrop({
		accept: [ItemTypes.DOCUMENT, ItemTypes.FOLDER],
		drop: handleDrop,
	})

	return drop
}

export default useHandleDrop
