import { useContext } from 'react'
import { twMerge } from 'tailwind-merge'
import PdfViewerContext from '../../contexts/book'

const ProgressBar = () => {
	const { bookState } = useContext(PdfViewerContext)
	const { numPages, renderedPages } = bookState
	const progress = Math.round((renderedPages.length / numPages) * 100)

	return (
		<div className='z-10 h-px w-full bg-gray-900'>
			<div
				className={twMerge(
					'h-full',
					progress >= 100 ? 'bg-gray-700' : 'bg-gray-500',
				)}
				style={{
					width: `${progress}%`,
				}}
			/>
		</div>
	)
}

export default ProgressBar
