import { useContext, useEffect, useRef } from 'react'
import PdfViewerContext from '../../contexts/book'

interface PlaceholderPageProps {
	pageNumber: number
	observer: IntersectionObserver
}

const PlaceholderPage: React.FC<PlaceholderPageProps> = ({
	pageNumber,
	observer,
}) => {
	const { bookState } = useContext(PdfViewerContext)
	const { pageSize } = bookState
	const { width, height } = pageSize
	const placeholderRef = useRef(null)

	useEffect(() => {
		const placeholder = placeholderRef.current
		if (placeholder && observer) {
			observer.observe(placeholder)
		}
		return () => {
			if (placeholder && observer) {
				observer.unobserve(placeholder)
			}
		}
	}, [observer, placeholderRef.current])

	return (
		<div
			ref={placeholderRef}
			id={`page_${pageNumber}`}
			data-placeholder-page-number={pageNumber}
			className='page m-px flex flex-grow items-center justify-center bg-gray-700/20 text-gray-500'
			style={{
				minWidth: width || '10vw',
				minHeight: height || '80vh',
			}}
		>
			Loading page {pageNumber}...
		</div>
	)
}
export default PlaceholderPage
