import SoloBanner from '../../../../assets/frontsite/solobanner.png'
import BodyText from '../../elements/BodyText'
import ButtonLink from '../../elements/ButtonLink'
import Card from '../../elements/Card'
import Eyebrow from '../../elements/Eyebrow'
import Title from '../../elements/Title'

const Solo = () => {
	return (
		<Card
			innerClassName='text-center md:max-w-full md:flex items-center h-[450px]'
			style={{
				backgroundImage: `url(${SoloBanner})`,
				backgroundSize: 'cover',
				backgroundPosition: '30% 15%',
			}}
		>
			<div
				className='absolute inset-0'
				style={{
					background:
						'radial-gradient(circle at 30% 50%, transparent 10%, rgba(0, 0, 0, 0.4) 70%)',
					zIndex: 1,
				}}
			></div>
			<div className='-mx-16 mt-16 flex-1 sm:mt-0 md:ml-8 md:-mr-12'>
				{/* <img
					src={SoloImage}
					alt='A proud black, female paladin brandishing a sword, against a sunset cloudscape.'
					className='-my-12 -ml-4'
				/> */}
			</div>
			<div className='relative z-10 flex-1 md:ml-16'>
				<Eyebrow>Go Solo</Eyebrow>
				<Title>No Party Required</Title>
				<BodyText>
					The AI GM is always prepped,
					<br />
					and has no scheduling issues.
				</BodyText>
				<ButtonLink
					to='/signup'
					className='mx-auto w-fit rounded-full bg-[#fff]/20 px-6 py-3 text-black backdrop-blur-xl'
				>
					Get Started →
				</ButtonLink>
			</div>
		</Card>
	)
}

export default Solo
