import useGetActiveScene from '../../hooks/useGetActiveScene'
import { IToken } from '../../interfaces/scene'
import Token from './Token'

const Tokens = () => {
	const scene = useGetActiveScene()

	return (
		<>
			{scene?.values?.tokens?.map((token: IToken, i: number) => (
				<Token
					// key={token._id}
					key={i}
					token={token}
				/>
			))}
		</>
	)
}

export default Tokens
