import { LockClosedIcon, LockOpenIcon } from '@heroicons/react/24/solid'
import * as Toggle from '@radix-ui/react-toggle'
import { TDocumentModes } from '../interfaces/document'
import TooltipWrapper from './TooltipWrapper'
import { windowButtonStyles } from './window/Button'

export interface IWindowModeToggleProps {
	documentMode: TDocumentModes
	setDocumentMode: (mode: TDocumentModes) => void
}

export default function WindowModeToggle(props: IWindowModeToggleProps) {
	const { documentMode, setDocumentMode } = props

	// Handler for toggle changes
	const handleToggleChange = () => {
		const nextMode = documentMode === 'view' ? 'edit' : 'view'
		setDocumentMode(nextMode)
	}

	return (
		<TooltipWrapper label={documentMode === 'edit' ? 'Done Editing' : 'Edit'}>
			<Toggle.Root
				className={windowButtonStyles}
				aria-label='Document Mode'
				// onToggleChange={handleToggleChange}
				onClick={handleToggleChange}
				pressed={documentMode === 'edit'}
			>
				{/* <Toggle.Thumb className='h-4 w-4 rounded-full bg-gray-900' /> */}
				{documentMode === 'edit' ? (
					<LockOpenIcon className='h-4 w-4 self-center' aria-hidden='true' />
				) : (
					<LockClosedIcon className='h-4 w-4 self-center' aria-hidden='true' />
				)}
			</Toggle.Root>
		</TooltipWrapper>
	)
}
