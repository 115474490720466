import { useContext, useEffect, useState } from 'react'
import { twMerge } from 'tailwind-merge'
import GameContext from '../../contexts/game'
import UsersContext from '../../contexts/users'
import useTheme from '../../hooks/useTheme'
import SmallUppercase from '../text/SmallUppercase'

type UserPillProps = {
	userId: string
	color?: string
	className?: string
}

const UserPill: React.FC<UserPillProps> = ({ userId, className }) => {
	const { game } = useContext(GameContext)
	const { usersState } = useContext(UsersContext)
	const { primary } = useTheme()
	// const { userId: thisUserId } = useUser()
	const user = usersState.users.find(u => u.userId === userId)
	const [userName, setUserName] = useState<string>('')
	const [characterName, setCharacterName] = useState<string>('')

	// const label = useMemo(() => {
	// 	const isYou = userId === thisUserId
	// 	const isGM = user?.userSettings?.role === 'gm'
	// 	let computedLabel = isGM ? 'GM' : 'Player'
	// 	if (isYou) computedLabel = 'You • ' + computedLabel

	// 	return computedLabel
	// }, [userId, thisUserId, user?.userSettings?.role])

	useEffect(() => {
		if (!user) return

		setUserName(user.userProfile.name.split(' ')[0])

		const assumedCharacterId = user.userSettings?.assumedCharacterId

		if (assumedCharacterId) {
			const assumedCharacter = game.documents.byId[assumedCharacterId]

			if (assumedCharacter?.values?.name) {
				setCharacterName(assumedCharacter?.values?.name?.split(' ')[0])
			}
		} else {
			setCharacterName('')
		}
	}, [user?.userSettings?.assumedCharacterId, game.documents])

	return (
		<div
			className={twMerge(
				'mt-1 w-full text-center transition-opacity duration-200 ease-in-out',
				className,
			)}
		>
			<SmallUppercase>
				<div
					className='max-w-4/5 text-xs'
					style={{
						display: '-webkit-box',
						WebkitBoxOrient: 'vertical',
						WebkitLineClamp: 2,
						overflow: 'hidden',
						color: primary,
						textShadow: '0 2px 4px rgba(0, 0, 0, 0.8)',
					}}
				>
					{/* {color && (
						<UserDot color={color} className='mr-1 mt-px inline-block' />
					)} */}
					<p>{characterName}</p>
					<p
						style={{
							color: primary,
							textShadow: '0 1px 3px rgba(0, 0, 0, 0.8)',
						}}
					>
						{userName}
					</p>
				</div>
				{/* <span
					className='whitespace-nowrap'
					style={{
						color: secondary,
					}}
				>
					{label}
				</span> */}
			</SmallUppercase>
		</div>
	)
}

export default UserPill
