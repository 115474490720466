import { IToolsAction, IToolsState } from '../interfaces/tools'

export default function ToolsReducer(state: IToolsState, action: IToolsAction) {
  switch (action.type) {
    case 'SET_IS_SCENE_SETTINGS_OPEN':
      return { ...state, isSceneSettingsOpen: action.payload }

    case 'SET_MODE':
      return { ...state, mode: action.payload }

    case 'SET_DRAW_TOOL':
      return { ...state, draw: { ...state.draw, tool: action.payload } }

    case 'SET_FOG_TOOL':
      return { ...state, fog: { ...state.fog, tool: action.payload } }

    default:
      return state
  }
}
