import ExitButton from '../ExitButton'
import SettingsButton from '../toolbar/SettingsButton'
import AddPlayerButtonSmall from './AddPlayerButtonSmall'

const LeftTools = () => {
	return (
		<div className='pointer-events-auto flex justify-center space-x-1.5'>
			<ExitButton />
			<SettingsButton />
			<AddPlayerButtonSmall />
			{/* <div className='w-1'></div> */}
			{/* <SyrinscapeButton />
			<TurnTrackerButton />
			<ContentButton /> */}
			{/* <div className='w-1'></div> */}
			{/* <LogGame />
			<DeleteLogButton /> */}
		</div>
	)
}

export default LeftTools
