import React, { ReactNode, useEffect, useRef, useState } from 'react'
import { animated, useSprings } from 'react-spring'
import { twMerge } from 'tailwind-merge'

type Props = {
	children: ReactNode[]
	topMost?: 'first' | 'last'
	selectedIndex?: number
	centered?: boolean
	hoverScale?: number
} & React.HTMLAttributes<HTMLDivElement>

const HOVER_SCALE = 1.15

const Overlapper = ({
	children,
	topMost = 'last',
	selectedIndex,
	centered = false,
	className,
	hoverScale = HOVER_SCALE,
	...rest
}: Props) => {
	const containerRef = useRef<HTMLDivElement>(null)
	const [containerWidth, setContainerWidth] = useState(0)
	const [itemWidths, setItemWidths] = useState<number[]>([])
	const [hoveredIndex, setHoveredIndex] = useState<number | null>(null)

	const childrenArray = React.Children.toArray(children)

	useEffect(() => {
		if (containerRef.current) {
			setContainerWidth(containerRef.current.offsetWidth)
			setItemWidths(
				Array.from(containerRef.current.children).map(
					child => (child as HTMLElement).offsetWidth,
				),
			)
		}
	}, [childrenArray.length])

	const totalItemWidth = itemWidths.reduce((a, b) => a + b, 0)
	const initialTranslation =
		totalItemWidth > containerWidth
			? Math.abs(totalItemWidth - containerWidth) / (childrenArray.length - 1)
			: 0
	const prefixSumTranslations = itemWidths.map((_, index) =>
		index === 0 ? 0 : initialTranslation * index,
	)
	const itemsWidthExceedsContainerWidth = totalItemWidth > containerWidth

	const springs = useSprings(
		childrenArray.length,
		childrenArray.map((_, index) => {
			const isHovered = hoveredIndex !== null
			const activeIndex = isHovered ? hoveredIndex : selectedIndex
			const isBeforeActive = index < activeIndex
			const isAfterActive = index > activeIndex
			const isAtActive = index === activeIndex

			const prefixSumTranslation = prefixSumTranslations[index] || 0
			const baseTranslation = -prefixSumTranslation - initialTranslation
			const afterActiveTranslation =
				(prefixSumTranslation - initialTranslation) * -1

			const translation =
				isHovered || selectedIndex !== undefined
					? isBeforeActive
						? baseTranslation
						: isAfterActive
						? afterActiveTranslation
						: -prefixSumTranslation
					: -prefixSumTranslation

			return {
				to: {
					transform: translation,
					scale: isAtActive && isHovered ? hoverScale : 1,
				},
				config: { tension: 300, friction: 20 },
			}
		}),
	)

	if (!childrenArray.length) return null

	return (
		<div
			className={twMerge(
				'flex',
				centered && !itemsWidthExceedsContainerWidth && 'justify-center',
				className,
			)}
			ref={containerRef}
			{...rest}
		>
			{springs.map((springProps, index) => (
				<animated.div
					key={index}
					className='relative'
					style={{
						transform: springProps.transform.to(t => `translateX(${t}px)`),
						zIndex:
							selectedIndex !== undefined && hoveredIndex !== null
								? index === selectedIndex && index === hoveredIndex
									? childrenArray.length
									: index
								: topMost === 'first'
								? index
								: childrenArray.length - index,
						pointerEvents: 'none',
					}}
				>
					<animated.div
						style={{
							transform: springProps.scale.to(s => `scale(${s})`),
							pointerEvents: 'auto',
						}}
						onMouseEnter={() => setHoveredIndex(index)}
						onMouseLeave={() => setHoveredIndex(null)}
					>
						{childrenArray[index]}
					</animated.div>
				</animated.div>
			))}
		</div>
	)
}

export default Overlapper
