import { IMessage } from '../../interfaces/chat'

type Props = {
	message: IMessage
}

const MediaMessage = ({ message }: Props) => {
	return (
		<div key={message._id} className='mt-2'>
			<img
				alt='message attachment'
				src={message.attachmentId}
				className='w-full rounded-lg shadow-md'
			/>
		</div>
	)
}

export default MediaMessage
