import { useContext } from 'react'
import GameContext from '../../contexts/game'
import useTheme from '../../hooks/useTheme'
import SectionButton from './SectionButton'

const SortButton = () => {
	const { game, dispatch } = useContext(GameContext)
	const { turnOrder } = game.turnTracker
	const { primary } = useTheme()

	const turnOrderIsSortedDescending = turnOrder.allIds.every((id, index) => {
		if (index === 0) return true
		const previousInitiative =
			turnOrder.byId[turnOrder.allIds[index - 1]].initiative
		const currentInitiative = turnOrder.byId[id].initiative
		return currentInitiative <= previousInitiative
	})

	const sortAsc = () => {
		dispatch({ type: 'SORT_TURN_ORDER_ASCENDING' })
	}

	const sortDesc = () => {
		dispatch({ type: 'SORT_TURN_ORDER_DESCENDING' })
	}

	if (turnOrder.allIds.length === 0) return null

	return (
		<>
			{turnOrderIsSortedDescending ? (
				<SectionButton onClick={sortAsc} style={{ color: primary }}>
					{/* <BarsArrowUpIcon className='h-4 w-4' /> */}
					<div>123</div>
				</SectionButton>
			) : (
				<SectionButton onClick={sortDesc} style={{ color: primary }}>
					{/* <BarsArrowDownIcon className='h-4 w-4' /> */}
					<div>321</div>
				</SectionButton>
			)}
		</>
	)
}

export default SortButton
