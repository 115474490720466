import { useContext, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import GameContext from '../contexts/game'
import { socket } from '../contexts/socket'

const useSocketConnection = () => {
	const { game } = useContext(GameContext)
	const { gameId } = useParams()

	const handleDisconnect = () => {
		socket.emit('join game', gameId)
	}

	const handleSocketSetup = () => {
		socket.on('disconnect', handleDisconnect)

		if (game._id) return
		socket.emit('join game', gameId)

		return () => {
			socket.emit('leave game', gameId)
			socket.off('disconnect', handleDisconnect)
		}
	}

	useEffect(handleSocketSetup, [])
}

export default useSocketConnection
