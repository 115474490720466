import { useContext } from 'react'
import { Outline } from 'react-pdf'
import { useSpring, animated } from 'react-spring'
import { twMerge } from 'tailwind-merge'
import PdfViewerContext from '../../contexts/book'

type Props = {
	width: number
}

const DocumentOutline = ({ width }: Props) => {
	const { bookState } = useContext(PdfViewerContext)
	const { isOutlineVisible } = bookState

	const props = useSpring({
		transform: `translateX(${isOutlineVisible ? 0 : -width}px)`,
	})

	return (
		<animated.div
			className='h-full overflow-auto'
			style={{
				...props,
				width: width,
			}}
		>
			<Outline
				className={twMerge(
					'flex-shrink-0 overflow-auto border-r border-white/10 p-4 text-sm text-gray-500 transition-all duration-500 ease-in-out',
				)}
			/>
		</animated.div>
	)
}

export default DocumentOutline
