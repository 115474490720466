import React from 'react'
import { twMerge } from 'tailwind-merge'
import Translucency from '../Translucency'
import useTheme from '../../hooks/useTheme'
import Color from 'color'
import SmallUppercase from '../text/SmallUppercase'

type Props = React.ButtonHTMLAttributes<HTMLButtonElement> & {}

const SectionButton: React.FC<Props> = ({ className, children, ...props }) => {
	const { primary } = useTheme()
	const fadedPrimaryColor = Color(primary).fade(0.8).string()

	return (
		<button
			{...props}
			className={twMerge(
				Translucency,
				'mx-1 flex space-x-2 rounded-full p-1 px-3',
				props.disabled ? 'pointer-events-none opacity-50' : '',
				className,
			)}
			style={{
				backgroundColor: fadedPrimaryColor,
			}}
			{...props}
		>
			<SmallUppercase
				className='flex space-x-1'
				style={{
					color: primary,
				}}
			>
				{children}
			</SmallUppercase>
		</button>
	)
}

export default SectionButton
