import { useContext, useState } from 'react'
import SmallUppercase from '../text/SmallUppercase'
import GameContext from '../../contexts/game'

export default function TipMessage() {
	const { game } = useContext(GameContext)

	const tips = [
		'Drop documents from the library on the chat to share them.',
		'Right click chat messages to delete them.',
		'Documents marked as "Private" can only be seen by GMs and players they are specifically shared with.',
		'Documents with colored dots are shared with specific players.',
		'Double-click a token on the map to open its sheet',
	]

	if (game.flags.maps) {
		tips.push('Drop documents from the library on the map to place them.')
	}

	const [tip] = useState(tips[Math.floor(Math.random() * tips.length)])

	return (
		<div className='m-auto mt-4 w-4/6 text-center text-xs text-gray-400'>
			<SmallUppercase>{tip}</SmallUppercase>
		</div>
	)
}
