import { useContext } from 'react'
import Loader from 'react-loader-spinner'
import { twMerge } from 'tailwind-merge'
import PdfViewerContext from '../../contexts/book'

const Loading = () => {
	const { bookState } = useContext(PdfViewerContext)
	const { loading } = bookState

	return (
		<div
			className={twMerge(
				'flex h-9 w-9 items-center justify-center',
				loading.length ? 'visible' : 'invisible',
			)}
		>
			<Loader type='Oval' color='#666' height={22} width={22} />
		</div>
	)
}

export default Loading
