import { PlayIcon, StopIcon } from '@heroicons/react/24/solid'
import { useContext } from 'react'
import GameContext from '../../contexts/game'
import SectionButton from './SectionButton'

const StartStopButton = () => {
	const { game, dispatch } = useContext(GameContext)
	const { currentRound, turnOrder } = game.turnTracker

	const handleStart = () => {
		dispatch({ type: 'START_TURN_TRACKER' })
	}

	const handleStop = () => {
		dispatch({ type: 'STOP_TURN_TRACKER' })
	}

	const hasActiveItems = turnOrder.allIds.some(
		id => turnOrder.byId[id].isActive,
	)

	if (currentRound > 0) {
		return (
			<SectionButton onClick={handleStop}>
				<StopIcon className='h-4 w-4' />
			</SectionButton>
		)
	}

	return (
		<SectionButton onClick={handleStart} disabled={!hasActiveItems}>
			<PlayIcon className='h-4 w-4' />
		</SectionButton>
	)
}

export default StartStopButton
