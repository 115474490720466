import { useEffect, useState } from 'react'
import { MemoryProvider } from '../../contexts/MemoryProvider'
import useUser from '../../hooks/useUser'
import Sidebar from '../Sidebar'
import Chat from '../chat/Chat'
import DiceToolsWrapper from '../dice/DiceToolsWrapper'
import SceneTools from '../toolbar/SceneTools'
import GMTurnTracker from '../turnTracker/GMTurnTracker'
import PlayerTurnTracker from '../turnTracker/PlayerTurnTracker'
import UserHeads from '../users/UserHeads'
import GameName from './GameName'
import LeftTools from './LeftTools'
import RightTools from './RightTools'
import Scratchpad from './Scratchpad'

const Interface = () => {
	const { activeSceneId: hasScene } = useUser()
	const [showGameName, setShowGameName] = useState(false)
	const [fadeOut, setFadeOut] = useState(false)

	useEffect(() => {
		const timer = setTimeout(() => {
			setFadeOut(true)
			const hideTimer = setTimeout(() => {
				setShowGameName(false)
			}, 1000) // Match the duration of the CSS transition
			return () => clearTimeout(hideTimer)
		}, 3000) // 3 seconds

		return () => clearTimeout(timer)
	}, [])

	return (
		<MemoryProvider>
			<div className='pointer-events-none fixed inset-0 z-30 m-4 flex flex-col gap-4 text-gray-300'>
				<div className='flex flex-1'>
					<div
						className='pointer-events-auto flex flex-col @container'
						style={{ flex: '0 0 360px', width: '360px' }}
					>
						<LeftTools />
						<PlayerTurnTracker />
						<Scratchpad />
						<Chat />
					</div>

					<div className='pointer-events-none flex flex-auto flex-col justify-between'>
						<UserHeads />

						<div className='justify-end self-center'>
							{hasScene && <SceneTools />}
							<DiceToolsWrapper />
						</div>
					</div>

					<div
						className='pointer-events-auto flex h-full flex-col'
						style={{ flex: '0 0 360px', width: '360px' }}
					>
						<RightTools />
						{/* <Syrinscape /> */}
						<GMTurnTracker />
						<Sidebar />
					</div>
				</div>

				{/* <Toolbelt /> */}
			</div>

			{showGameName && (
				<div
					className={`pointer-events-none fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-75 transition-opacity duration-1000 ${
						fadeOut ? 'opacity-0' : 'opacity-100'
					}`}
				>
					<GameName />
				</div>
			)}
		</MemoryProvider>
	)
}

export default Interface
