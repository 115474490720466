import React, { FC } from 'react'
import { twMerge } from 'tailwind-merge'

interface IProps {
	onClick?: (e: any) => void
	type?: 'button' | 'reset' | 'submit' | undefined
	className?: string
	children?: React.ReactNode
	disabled?: boolean
}

const ButtonText: FC<IProps> = ({
	type = 'button',
	className,
	children,
	onClick,
	disabled,
}) => {
	return (
		<button
			type={type}
			className={twMerge(
				'mb-8 text-xl font-semibold text-black dark:text-white md:text-xl',
				disabled && 'cursor-not-allowed opacity-50',
				className,
			)}
			onClick={onClick}
			disabled={disabled}
		>
			{children}
		</button>
	)
}

export default ButtonText
