import { useContext } from 'react'
import PdfViewerContext from '../../contexts/book'
import AIButton from './AIButton'
import Loading from './Loading'
import NewWindowButton from './NewWindowButton'
import { PagesDisplayButton } from './PagesDisplayButton'
import ResourceInfo from './ResourceInfo'
import SearchInput from './SearchInput'
import ShareSelection from './ShareSelection'
import SidebarToggle from './SidebarToggle'
import ImageEditorButton from './setShowCreateImage'

type Props = {
	setShowCreateImage: (show: boolean) => void
	isGame: boolean
}

const Container = ({ children }) => {
	return <div className='flex space-x-2'>{children}</div>
}

const Toolbar = ({ setShowCreateImage, isGame }: Props) => {
	const { bookState, bookDispatch } = useContext(PdfViewerContext)
	const { name, currentPage, numPages, pagesToDisplay, searchText } = bookState

	function onSearchChange(event: React.ChangeEvent<HTMLInputElement>) {
		bookDispatch({
			type: 'SET_SEARCH_TEXT',
			payload: event.target.value,
		})
	}

	return (
		<div className='flex h-14 items-center justify-between space-x-2 p-2 text-white'>
			<Container>
				<SidebarToggle />
				<PagesDisplayButton />
				<ResourceInfo
					name={name}
					pageNumber={currentPage}
					numPages={numPages}
					pagesToDisplay={pagesToDisplay}
				/>
				<Loading />
			</Container>

			<Container>
				{isGame && (
					<ImageEditorButton setShowCreateImage={setShowCreateImage} />
				)}
				{isGame && <AIButton />}
				{isGame && <ShareSelection />}
				<NewWindowButton />

				<SearchInput value={searchText} onChange={onSearchChange} />
			</Container>
		</div>
	)
}

export default Toolbar
