import { useContext } from 'react'
import GameContext from '../contexts/game'
import { IScene } from '../interfaces/scene'
import useUser from './useUser'

const useGetActiveScene = (): IScene | null => {
	const { game } = useContext(GameContext)
	const { activeSceneId } = useUser()

	if (!activeSceneId) return null

	const scene = game.documents.byId[activeSceneId]
		? (game.documents.byId[activeSceneId] as IScene)
		: null

	return scene
}

export default useGetActiveScene
