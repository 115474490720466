import * as ContextMenu from '@radix-ui/react-context-menu'
import { useState } from 'react'
import useUser from '../../hooks/useUser'
import ProfileEditor from '../pages/views/ProfileEditor'
import EditProfileMenuItem from './contextmenu/EditProfileMenuItem'
import RemovePlayerMenuItem from './contextmenu/RemovePlayerMenuItem'
import UnassignCharacterMenuItem from './contextmenu/UnassignCharacterMenuItem'
import EditColor from './contextmenu/EditColor'

type Props = {
	children: React.ReactNode
	userId: string
}

export default function UserMenu({ children, userId }: Props) {
	const [editProfile, setEditProfile] = useState(false)
	const { isGM, userId: myUserId } = useUser()
	const isMe = userId === myUserId

	const canEditProfile = isMe
	const canUnassign = isMe || isGM
	const canEditColor = isMe || isGM
	const canRemove = !isMe && isGM

	if (!isGM) return <>{children}</>

	return (
		<>
			<ContextMenu.Root>
				<ContextMenu.Trigger>{children}</ContextMenu.Trigger>

				<ContextMenu.Portal>
					<ContextMenu.Content
						className={
							'z-50 space-y-1 rounded-xl border border-gray-700/50 bg-gray-900 p-1 text-gray-200 shadow-xl'
						}
					>
						{canEditProfile && (
							<EditProfileMenuItem
								userId={userId}
								setEditProfile={setEditProfile}
							/>
						)}
						{canUnassign && <UnassignCharacterMenuItem userId={userId} />}
						{canRemove && <RemovePlayerMenuItem userId={userId} />}
						{canEditColor && <EditColor userId={userId} />}
					</ContextMenu.Content>
				</ContextMenu.Portal>
			</ContextMenu.Root>

			{editProfile && <ProfileEditor onClose={() => setEditProfile(false)} />}
		</>
	)
}
