import { twMerge } from 'tailwind-merge'

type Props = React.HTMLProps<HTMLDivElement>

const MediumUppercase = ({ children, className, style, ...rest }: Props) => {
	return (
		<div
			className={twMerge(
				'uppercase tracking-widest text-gray-500 text-shadow-lg',
				className,
			)}
			style={{
				fontSize: '12px',
				fontWeight: 600,
				...style,
			}}
			{...rest}
		>
			{children}
		</div>
	)
}

export default MediumUppercase
