import { useCallback } from 'react'
import { TDocumentModes } from '../../interfaces/document'

type Props = {
	documentMode: TDocumentModes
	messageToFrame: (type: string, payload: any) => void
}

const useHandleDocumentModeChange = ({
	documentMode,
	messageToFrame,
}: Props) => {
	const handleDocumentModeChange = useCallback(() => {
		messageToFrame('update document mode', {
			editMode: documentMode,
		})
	}, [documentMode])

	return handleDocumentModeChange
}

export default useHandleDocumentModeChange
