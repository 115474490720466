// import { Sprite } from '@pixi/react'
// import * as PIXI from 'pixi.js'
// import { useContext, useRef, useState } from 'react'
// import { useDrop } from 'react-dnd'
// import ItemTypes from '../draganddrop/ItemTypes'
import { Viewport as PixiViewport } from 'pixi-viewport'
import { useState } from 'react'
import Map from './Map'
import Stage from './Stage'
import StageDropZone from './StageDropZone'
import Tabletop from './Tabletop'
import Tokens from './Tokens'
import Viewport from './Viewport'

const StageContainer = () => {
	// const { game, dispatch } = useContext(GameContext)
	const [viewport, setViewport] = useState<PixiViewport>(null)
	false && console.log(viewport)
	// const [image, setImage] = useState('/files/file-1703218082798.webp')
	// const [x, setX] = useState(0)
	// const [y, setY] = useState(0)

	// const xyToViewport = (x: number, y: number) => {
	// 	let globalPoint = new PIXI.Point(x, y)
	// 	let localPoint = viewport.toWorld(globalPoint)
	// 	// .toLocal(globalPoint)
	// 	return localPoint
	// }

	// const ref = useRef(null)
	// const [, drop] = useDrop({
	// 	accept: ItemTypes.DOCUMENT,
	// 	hover: (item: { id: string }, monitor) => {
	// 		const { x, y } = monitor.getSourceClientOffset()
	// 		const point = xyToViewport(x, y)
	// 		setX(point.x)
	// 		setY(point.y)

	// 		const doc = game.documents.byId[item.id]
	// 		const { token } = doc.values
	// 		const asset = game.assets.byId[token]
	// 		setImage(asset.fileurl)
	// 		// let globalPoint = new PIXI.Point(x, y)
	// 		// let localPoint = viewport.toLocal(globalPoint)
	// 		// console.log(localPoint) // This will give you the coordinates in the viewport's space
	// 	},
	// 	drop: (item, monitor) => {
	// 		console.log(item)
	// 		const { x, y } = monitor.getSourceClientOffset()
	// 		const point = xyToViewport(x, y)
	// 		setX(point.x)
	// 		setY(point.y)
	// 	},
	// 	collect: monitor => ({
	// 		isOver: !!monitor.isOver(),
	// 	}),
	// })

	// drop(ref)
	// console.log(x, y)

	return (
		<StageDropZone>
			{/* <div ref={ref}> */}
			<Stage>
				<Viewport setViewport={setViewport}>
					<Tabletop />
					<Map />
					<Tokens />
					{/* <Sprite
							anchor={0.5}
							x={x}
							y={y}
							width={10}
							height={10}
							image={image}
						/> */}
				</Viewport>
			</Stage>
			{/* </div> */}
		</StageDropZone>
	)
}

export default StageContainer
