import { useCallback, useContext, useEffect, useMemo } from 'react'
import { Page } from 'react-pdf'
import 'react-pdf/dist/Page/AnnotationLayer.css'
import 'react-pdf/dist/Page/TextLayer.css'
import { PageCallback } from 'react-pdf/dist/cjs/shared/types'
import PdfViewerContext from '../../contexts/book'
import './PDFViewer.css'

function highlightPattern(text: string, pattern: RegExp): string {
	return text.replace(pattern, value => `<mark>${value}</mark>`)
}

type Props = {
	pageNumber: number
	containerRef: React.RefObject<HTMLDivElement>
}

const PdfPage = ({ pageNumber, containerRef }: Props) => {
	const { bookState, bookDispatch } = useContext(PdfViewerContext)
	const { fitWidth, onRenderSuccess, searchText, pagesToDisplay } = bookState
	const width = useMemo(
		() =>
			fitWidth ? containerRef.current?.clientWidth / pagesToDisplay : undefined,
		[containerRef, fitWidth, pagesToDisplay],
	)

	const handleRenderSuccess = (data: PageCallback) => {
		const { width, height } = data
		if (pageNumber !== 1) {
			bookDispatch({
				type: 'SET_PAGE_SIZE',
				payload: { width, height },
			})
		}
		bookDispatch({
			type: 'REMOVE_LOADING',
			payload: pageNumber,
		})
		onRenderSuccess(pageNumber)
	}

	const textRenderer = useCallback(
		(textItem: { str: string }) =>
			highlightPattern(textItem.str, new RegExp(searchText, 'gi')),
		[searchText],
	)

	useEffect(() => {
		bookDispatch({
			type: 'ADD_LOADING',
			payload: pageNumber,
		})
	}, [])

	const loading = (
		<div
			className='m-px flex items-center justify-center overflow-hidden bg-gray-700/20 text-red-500'
			style={{
				width: bookState.pageSize.width,
				height: bookState.pageSize.height,
			}}
		>
			Loading page {pageNumber}...
		</div>
	)

	return (
		<div className='mb-px'>
			<Page
				canvasBackground='#ffffff00'
				onRenderSuccess={handleRenderSuccess}
				pageNumber={pageNumber}
				loading={loading}
				customTextRenderer={textRenderer}
				width={width}
				className='page'
			/>
		</div>
	)
}

export default PdfPage
