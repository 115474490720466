import React from 'react'
import { twMerge } from 'tailwind-merge'
import useTheme from '../../hooks/useTheme'
import ToolbarControlButton from '../toolbar/ControlButton'
import Translucency from '../Translucency'

interface AdvRollBtnProps {
	label: string
	tooltip?: string
	onRoll: (notation: string) => void
	notation: string
	disabled?: boolean
}

const AdvRollBtn: React.FC<AdvRollBtnProps> = ({
	label,
	tooltip,
	onRoll,
	notation,
	disabled = false,
}) => {
	const { primary } = useTheme()
	const roll = () => {
		if (!disabled) {
			onRoll(notation)
		}
	}

	return (
		<ToolbarControlButton
			className={twMerge(
				'h-10 w-20 rounded-full text-gray-700',
				Translucency,
				// SystemButtonStyle,
				disabled && 'cursor-not-allowed opacity-50',
				label.length < 6 ? 'text-sm' : 'text-xs',
			)}
			innerStyle={{
				color: primary,
			}}
			innerClassName='px-3'
			onClick={roll}
			label={tooltip || label}
			disabled={disabled}
		>
			{label}
		</ToolbarControlButton>
	)
}

export default AdvRollBtn
