import React from 'react'
import { twMerge } from 'tailwind-merge'

interface BodyTextProps extends React.HTMLAttributes<HTMLDivElement> {
	children: React.ReactNode
}

const BodyText: React.FC<BodyTextProps> = ({
	className,
	children,
	...props
}) => {
	return (
		<div
			className={twMerge(
				'text-md mb-8 text-white md:mb-12 lg:text-xl lg:leading-normal ',
				className,
			)}
			{...props}
		>
			{children}
		</div>
	)
}

export default BodyText
