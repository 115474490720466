import { useContext, useEffect } from 'react'
import { useForm } from 'react-hook-form'
import GameContext from '../../../contexts/game'
import { ISetFlagPayload } from '../../../interfaces/game'
import FlagCheckbox from './FlagCheckbox'

const Maps = () => {
	const { game, dispatch } = useContext(GameContext)
	const { register, watch } = useForm()

	useEffect(() => {
		const subscription = watch(value => {
			const payload: ISetFlagPayload = {
				flag: 'maps',
				value: value.flags.maps,
			}

			dispatch({
				type: 'SET_FLAG',
				payload,
			})
		})

		return () => {
			subscription.unsubscribe()
		}
	}, [dispatch, watch])

	return (
		<FlagCheckbox
			id='maps'
			className='mt-0'
			defaultChecked={game.flags.maps}
			register={register}
		/>
	)
}

export default Maps
