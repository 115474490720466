import Dice from './sections/Dice'
import Library from './sections/Features/Library'
import Main from './sections/Features/Main'
import Solo from './sections/Features/Solo'

const HomePage = () => {
	return (
		<div>
			<Main />
			<Solo />
			<Library />
			<Dice />
			{/* <Mobile /> */}

			{/* <div className='md:flex'>
	      <Card className='text-center flex-1 md:mr-4'>
	        <Eyebrow>Get To Know Us</Eyebrow>
	        <Title>We're New</Title>
	        <BodyText>
	          But with 20 years at Squarespace and
	          <br />
	          at game studios, we've been around.
	        </BodyText>
	        <ButtonLink to='/about'>Learn More → </ButtonLink>
	      </Card>

	      <Card className='text-center flex-1 md:ml-4'>
	        <Eyebrow>Come Work With Us</Eyebrow>
	        <Title>We're Hiring</Title>
	        <BodyText>
	          It takes a village to build a community.
	          <br />
	          Maybe you're looking to make a move?
	        </BodyText>
	        <ButtonLink to='/hiring'>Work Here → </ButtonLink>
	      </Card>
	    </div> */}
		</div>
	)
}

export default HomePage
