import React, { useCallback, useContext } from 'react'
import GameContext from '../../contexts/game'
import { TDocumentModes } from '../../interfaces/document'

type Props = {
	documentId: string
	documentMode: TDocumentModes
	messageToFrame: (type: string, payload: any) => void
	setFrameIsLoaded: React.Dispatch<React.SetStateAction<boolean>>
}

const useHandleFrameLoad = ({
	documentId,
	documentMode,
	messageToFrame,
	setFrameIsLoaded,
}: Props) => {
	const { game } = useContext(GameContext)

	const handleFrameLoad = useCallback(() => {
		if (!game.system || !game.system?.code) {
			throw new Error('No system code found')
		}

		const { documents, assets, system } = game
		const { code } = system || {}

		console.log('message to frame', 'editMode: ', documentMode)

		messageToFrame('load', {
			documentId,
			documents,
			code,
			assets,
			editMode: documentMode,
		})

		setFrameIsLoaded(true)
	}, [
		JSON.stringify(game.documents),
		JSON.stringify(game.assets),
		documentMode,
		messageToFrame,
	])

	return handleFrameLoad
}

export default useHandleFrameLoad
