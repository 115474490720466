import { useContext, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import AuthContext from '../contexts/auth'
import GameContext from '../contexts/game'

const useCheckUserInGame = () => {
	const { game } = useContext(GameContext)
	const { authState } = useContext(AuthContext)
	const userId = authState.userId
	// @ts-ignore it's hydrated, the model just doesn't know
	const creatorId = game?.creator?._id
	const isNotAUser = !game.users.includes(userId)
	const isNotTheCreator = creatorId !== userId
	const navigate = useNavigate()

	useEffect(() => {
		if (!game._id || !authState.userId) {
			return
		}

		if (isNotAUser && isNotTheCreator) {
			alert('You no longer have access to this game.')
			navigate('/profile')
		}
	}, [game._id, game.users, navigate, authState])
}

export default useCheckUserInGame
