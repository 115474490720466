import { ArrowDownIcon } from '@heroicons/react/24/solid'
import { useContext } from 'react'
import GameContext from '../../contexts/game'
import SectionButton from './SectionButton'

interface NextButtonsProps {
	disabled?: boolean
}

const NextButtons = ({ disabled }: NextButtonsProps) => {
	const { game, dispatch } = useContext(GameContext)
	const { currentRound, currentTurnIndex } = game.turnTracker
	const { turnOrder } = game.turnTracker

	const moveToNext = () => {
		const { name } = turnOrder.byId[turnOrder.allIds[currentTurnIndex]]

		dispatch({
			type: 'ADD_SYSTEM_MESSAGE',
			payload: {
				message: `It is ${name}'s turn.`,
			},
		})

		dispatch({ type: 'TURN_ORDER_NEXT' })
	}

	if (currentRound === 0) return null

	return (
		<SectionButton onClick={moveToNext} disabled={disabled}>
			<ArrowDownIcon className='h-4 w-4' />
		</SectionButton>
	)
}

export default NextButtons
