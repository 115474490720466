import { TrashIcon } from '@heroicons/react/24/solid'
import { useContext } from 'react'
import GameContext from '../../contexts/game'
import useTheme from '../../hooks/useTheme'
import TooltipWrapper from '../TooltipWrapper'

type Props = {
	id: string
}

const RemoveButton = ({ id }: Props) => {
	const { dispatch } = useContext(GameContext)
	const { primary } = useTheme()

	const handleRemove = () => {
		dispatch({ type: 'REMOVE_FROM_TURN_ORDER', payload: { id } })
	}

	return (
		<TooltipWrapper label='Remove'>
			<button onClick={handleRemove}>
				<TrashIcon
					className='h-5 w-5'
					style={{
						color: primary,
					}}
				/>
			</button>
		</TooltipWrapper>
	)
}

export default RemoveButton
