import { useContext } from 'react'
import GameContext from '../contexts/game'

const useFlags = () => {
	const { game } = useContext(GameContext)
	const { flags } = game

	return flags
}

export default useFlags
