import React, {
	useCallback,
	useContext,
	useEffect,
	useMemo,
	useRef,
	useState,
} from 'react'
import Measure from 'react-measure'
import AutoSizer from 'react-virtualized-auto-sizer'
import { VariableSizeList as List } from 'react-window'
import GameContext from '../../contexts/game'
import UsersContext from '../../contexts/users'
import useGetLog from '../../hooks/useGetLog'
import useUser from '../../hooks/useUser'
import Message from './Message'
import useHandleDrop from './useHandleDrop'

const MemoizedMessage = React.memo(Message)

const Log = () => {
	const { dispatch } = useContext(GameContext)
	const { usersState } = useContext(UsersContext)
	const { userId } = useUser()
	const { log } = useGetLog()
	const [sizes, setSizes] = useState(() => Array(log.length).fill(0))
	const dropRef = useRef<HTMLDivElement>(null)
	const listRef = useRef<List>(null)
	const outerRef = useRef<HTMLDivElement>(null)
	const measuredIndexes = useRef(new Set<number>())
	const user = useMemo(
		() => usersState.users.find(u => u.userId === userId),
		[usersState.users, userId],
	)
	const drop = useHandleDrop({ userId, userProfile: user?.userProfile })

	const resetMeasuredIndexes = useCallback(
		(index: number) => {
			for (let i = index; i < log.length; i++) {
				measuredIndexes.current.delete(i)
			}

			setSizes(prevSizes => {
				const newSizes = [...prevSizes]
				newSizes.splice(index, 1)
				return newSizes
			})
		},
		[log.length],
	)

	const handleDelete = useCallback(
		(messageId: string) => {
			const index = log.findIndex(m => m._id === messageId)
			listRef.current?.resetAfterIndex(index, true)
			resetMeasuredIndexes(index)
			dispatch({ type: 'DELETE_MESSAGE', payload: messageId })
		},
		[dispatch, log, resetMeasuredIndexes],
	)

	const rowRenderer = useCallback(
		({ index, style }) => {
			if (index < 0 || index >= log.length) {
				return null
			}
			const message = log[index]
			const prevMessage = index > 0 ? log[index - 1] : null
			const newSender = !prevMessage || prevMessage.sender !== message.sender
			const newAssumedCharacter =
				!prevMessage ||
				prevMessage.assumedCharacterId !== message.assumedCharacterId
			const showSender = newSender || newAssumedCharacter
			const { height, ...restStyle } = style

			return (
				<Measure
					bounds
					onResize={({ bounds }) => {
						if (!bounds || measuredIndexes.current.has(index)) return
						measuredIndexes.current.add(index)
						setSizes(prevSizes => {
							if (prevSizes[index] === bounds.height) return prevSizes
							const newSizes = [...prevSizes]
							newSizes[index] = bounds.height
							return newSizes
						})
						listRef.current?.resetAfterIndex(index, false)
					}}
				>
					{({ measureRef }) => (
						<div
							ref={measureRef}
							style={{
								height:
									typeof height === 'number' && height !== 0
										? height
										: undefined,
								...restStyle,
							}}
						>
							<MemoizedMessage
								key={message._id}
								message={message}
								onDelete={() => handleDelete(message._id)}
								showSender={showSender}
							/>
						</div>
					)}
				</Measure>
			)
		},
		[log, handleDelete],
	)

	const scrollToBottom = useCallback(() => {
		if (!listRef.current) return
		const list = listRef.current
		setTimeout(() => {
			list.scrollToItem(log.length - 1)
		}, 500) // fix for NaN error
	}, [log.length])

	const initList = useCallback(() => {
		if (!listRef.current) return
		const list = listRef.current
		list.resetAfterIndex(0, true)
		scrollToBottom()
	}, [scrollToBottom])

	// const isScrolledToNearBottom = () => {
	// 	const outerEl = outerRef.current
	// 	if (!outerEl) return false
	// 	const scrollHeight = outerEl.scrollHeight
	// 	const scrollTop = outerEl.scrollTop
	// 	const clientHeight = outerEl.clientHeight
	// 	return scrollHeight - (scrollTop + clientHeight) <= 100
	// }
	useEffect(initList, [initList])

	const stickToBottomOnLogChange = useCallback(() => {
		scrollToBottom()
	}, [scrollToBottom])

	useEffect(stickToBottomOnLogChange, [log.length, stickToBottomOnLogChange])

	useEffect(() => {
		listRef.current?.resetAfterIndex(0, true)
	}, [log])

	drop(dropRef)

	const itemSize = useCallback(
		(index: number) => {
			if (index < 0 || index >= sizes.length) {
				return 0
			}
			return sizes[index]
		},
		[sizes],
	)

	return (
		<div ref={dropRef} className='-mx-3 h-full flex-1 py-2 px-3'>
			<AutoSizer>
				{({ height, width }) => (
					<List
						outerRef={outerRef}
						estimatedItemSize={36}
						ref={listRef}
						height={height}
						width={width}
						itemCount={log.length}
						itemSize={itemSize}
						children={rowRenderer}
						overscanCount={5}
					/>
				)}
			</AutoSizer>
		</div>
	)
}

export default React.memo(Log)
