import LibraryButton from '../toolbar/LibraryButton'
import SyrinscapeButton from '../toolbar/SyrinscapeButton'
import TurnTrackerButton from '../toolbar/TurnTrackerButton'

const RightTools = () => {
	return (
		<div className='pointer-events-auto flex justify-center space-x-1.5'>
			<SyrinscapeButton />
			<TurnTrackerButton />
			<LibraryButton />
		</div>
	)
}

export default RightTools
