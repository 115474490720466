import { BookOpenIcon as BookOpenIconOutline } from '@heroicons/react/24/outline'
import { BookOpenIcon as BookOpenIconSolid } from '@heroicons/react/24/solid'
import * as DropdownMenu from '@radix-ui/react-dropdown-menu'
import { useContext, useState } from 'react'
import { twMerge } from 'tailwind-merge'
import PdfViewerContext from '../../contexts/book'
import CheckboxItem from '../dropdownmenu/CheckboxItem'
import MenuContent from '../dropdownmenu/MenuContent'
import Separator from '../dropdownmenu/Separator'
import TooltipWrapper from '../TooltipWrapper'

export const PagesDisplayButton = () => {
	const { bookState, bookDispatch } = useContext(PdfViewerContext)
	const { pagesToDisplay, isCoverAlone } = bookState
	const [isOpen, setIsOpen] = useState(false)

	false && console.log(isOpen)

	const onOpenChange = (open: boolean) => {
		setIsOpen(open)
	}

	const handleSwitchPagesToDisplay = (pages: number) => {
		bookDispatch({
			type: 'SET_PAGES_TO_DISPLAY',
			payload: pages,
		})
	}

	const handleCoverAlone = () => {
		bookDispatch({
			type: 'SET_IS_COVER_ALONE',
			payload: !isCoverAlone,
		})
	}

	return (
		<DropdownMenu.Root onOpenChange={onOpenChange}>
			<DropdownMenu.Trigger>
				<TooltipWrapper label={isOpen ? '' : 'Display Settings'}>
					<div
						className={twMerge(
							'flex w-9 items-center justify-center',
							isOpen ? 'bg-gray-300 text-black' : '',
						)}
					>
						{pagesToDisplay === 1 ? (
							<BookOpenIconOutline className='h-5 w-5' />
						) : (
							<BookOpenIconSolid className='h-5 w-5' />
						)}
					</div>
				</TooltipWrapper>
			</DropdownMenu.Trigger>

			<MenuContent>
				<CheckboxItem
					checked={pagesToDisplay === 1}
					onCheckedChange={() => handleSwitchPagesToDisplay(1)}
				>
					Show One Page
				</CheckboxItem>
				<CheckboxItem
					checked={pagesToDisplay === 2}
					onCheckedChange={() => handleSwitchPagesToDisplay(2)}
				>
					Show Two Pages
				</CheckboxItem>

				{pagesToDisplay === 2 && (
					<>
						<Separator />

						<CheckboxItem
							checked={isCoverAlone}
							onCheckedChange={handleCoverAlone}
						>
							Show Cover Alone
						</CheckboxItem>
					</>
				)}

				{/* <MenuItem onClick={handleOpen}>Open in Tab</MenuItem>
				<DangerMenuItem onClick={handleDelete}>Delete</DangerMenuItem> */}
			</MenuContent>
		</DropdownMenu.Root>
	)
}
