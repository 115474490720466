import {
	useCallback,
	useContext,
	useEffect,
	useMemo,
	useRef,
	useState,
} from 'react'
import Loader from 'react-loader-spinner'
import { Document } from 'react-pdf'
import 'react-pdf/dist/Page/AnnotationLayer.css'
import 'react-pdf/dist/Page/TextLayer.css'
import { twMerge } from 'tailwind-merge'
import { IResource } from '../../../../shared/types/resources'
import PdfViewerContext from '../../contexts/book'
import { configurePdfWorker } from '../../utils/pdfConfig'
import canvasesToImage from './CanvasesToImage'
import CreateImage from './CreateImage'
import DocumentOutline from './DocumentOutline'
import './PDFViewer.css'
import ProgressBar from './ProgressBar'
import RenderPages from './RenderPages'
import Toolbar from './Toolbar'

configurePdfWorker()

const SIDEBAR_WIDTH = 300

type Props = {
	resource: IResource
	page: string
	isGame: boolean
}

const PdfViewer = ({ resource, page, isGame }: Props) => {
	const { bookState, bookDispatch } = useContext(PdfViewerContext)
	const { pagesToDisplay } = bookState
	const file = useMemo(() => resource.fileurl, [resource.fileurl])
	const [showCreateImage, setShowCreateImage] = useState(false)
	const containerRef = useRef(null)
	const observer = useRef(null)
	const pageOneRef = useRef(null)
	const pageTwoRef = useRef(null)
	const [renderCount, setRenderCount] = useState(1)

	// load cached pagesToDisplay from localStorage
	useEffect(() => {
		const savedPagesToDisplay = localStorage.getItem('pagesToDisplay')
		const pagesToDisplay = savedPagesToDisplay
			? JSON.parse(savedPagesToDisplay)
			: 1
		bookDispatch({ type: 'SET_PAGES_TO_DISPLAY', payload: pagesToDisplay })
	}, [])

	// save pagesToDisplay to localStorage
	useEffect(() => {
		localStorage.setItem(
			'pagesToDisplay',
			JSON.stringify(bookState.pagesToDisplay),
		)
	}, [bookState.pagesToDisplay])

	const onDocumentLoadSuccess = ({ numPages }) => {
		bookDispatch({ type: 'SET_NUM_PAGES', payload: numPages })
	}

	const onRenderSuccess = useCallback(
		(pageNumber: number) => {
			// when a page is rendered, remove the placeholder page,
			// and add the next page to the list of rendered pages
			const placeholderPage = document.querySelector(
				`[data-placeholder-page-number="${pageNumber}"]`,
			)
			if (placeholderPage) placeholderPage.remove()

			// allow the next page in the renderedPages stack to be rendered
			setRenderCount(prev => prev + 1)

			// after page 2 is loaded, placeholder's have their height set
			// so we can scroll to the page specified in the URL
			if (pageNumber === 2) {
				const pageElement = document.getElementById(`page_${page}`)
				const container = containerRef.current
				if (pageElement && container) {
					const rect = pageElement.getBoundingClientRect()
					const containerRect = container.getBoundingClientRect()
					container.scrollTop = rect.top - containerRect.top
				}
			}
		},
		[bookState.renderedPages],
	)

	const setCurrentPage = (page: number) => {
		bookDispatch({
			type: 'SET_CURRENT_PAGE',
			payload: page,
		})

		const url = new URL(window.location.href)
		url.searchParams.set('page', page.toString())
		window.history.replaceState({}, '', url.toString())
	}

	const handleIntersect = (entries: IntersectionObserverEntry[]): void => {
		entries.forEach((entry: IntersectionObserverEntry): void => {
			if (entry.isIntersecting) {
				const page = parseInt(entry.target.id.split('_')[1])
				if (bookState.currentPage !== page) {
					setCurrentPage(page)
					bookDispatch({
						type: 'SET_RENDERED_PAGES',
						payload: Array.from(
							// create an array of page numbers to render
							{ length: pagesToDisplay === 1 ? 4 : 8 },
							(_, i) => page + i - 1,
						),
					})
				}
			}
		})
	}

	useEffect(() => {
		observer.current = new IntersectionObserver(handleIntersect, {
			threshold: 0.75,
		})
		return () => {
			observer.current.disconnect()
		}
	}, [handleIntersect, observer.current])

	const onItemClick = ({ pageNumber }) => {
		const pageElement = document.getElementById(`page_${pageNumber}`)
		if (pageElement) pageElement.scrollIntoView()
	}

	useEffect(() => {
		bookDispatch({
			type: 'SET_ON_RENDER_SUCCESS',
			payload: onRenderSuccess,
		})
	}, [])

	// save canvases to refs for the image editor
	useEffect(() => {
		const { currentPage, pagesToDisplay } = bookState
		const isDoublePageView = pagesToDisplay === 2
		const firstPageNumber = currentPage
		const secondPageNumber = isDoublePageView ? currentPage + 1 : null

		const updateCanvasRef = (
			pageNumber: number | null,
			ref: React.MutableRefObject<HTMLCanvasElement | null>,
		) => {
			if (pageNumber !== null) {
				const pageElement = document.querySelector(
					`[data-page-number="${pageNumber}"]`,
				)
				if (pageElement) {
					const canvas = pageElement.querySelector('canvas')
					if (canvas) ref.current = canvas
				}
			}
		}

		updateCanvasRef(firstPageNumber, pageOneRef)
		if (isDoublePageView) {
			updateCanvasRef(secondPageNumber, pageTwoRef)
		}
	}, [bookState.currentPage, bookState.pagesToDisplay])

	const clearRenderedPages = () => {
		bookDispatch({ type: 'RESET_PAGE_SIZE_TO_DEFAULT' })
		bookDispatch({ type: 'CLEAR_RENDERED_PAGES' })
	}

	const prevWidthRef = useRef<number | null>(null)

	useEffect(() => {
		const resizeObserver = new ResizeObserver(entries => {
			for (const entry of entries) {
				const newWidth = entry.contentRect.width
				console.log('newWidth', newWidth, prevWidthRef.current)
				if (prevWidthRef.current !== newWidth) {
					clearRenderedPages()
					prevWidthRef.current = newWidth
				}
			}
		})

		if (containerRef.current) {
			resizeObserver.observe(containerRef.current)
		}

		return () => {
			if (containerRef.current) {
				resizeObserver.unobserve(containerRef.current)
			}
		}
	}, [containerRef.current, bookDispatch])

	useEffect(() => {
		const handleKeyDown = (event: KeyboardEvent) => {
			const container = containerRef.current
			if (!container) return

			const children = Array.from(container.children) as HTMLElement[]
			const rects = children.map(child => child.getBoundingClientRect())

			const firstFullyVisibleIndex = rects.findIndex(rect => rect.top >= 0)
			let newPage = null

			if (event.key === 'ArrowLeft') {
				event.preventDefault()
				// Scroll to the page before the first fully visible page
				newPage = Math.max(firstFullyVisibleIndex - 1, 0)
			} else if (event.key === 'ArrowRight') {
				event.preventDefault()
				// Scroll to the page after the first fully visible page
				newPage = Math.min(firstFullyVisibleIndex + 1, children.length - 1)
			}

			if (newPage !== null) {
				children[newPage].scrollIntoView({})
			}
		}

		document.addEventListener('keydown', handleKeyDown)

		return () => {
			document.removeEventListener('keydown', handleKeyDown)
		}
	}, [containerRef, bookDispatch])

	useEffect(() => {
		bookDispatch({
			type: 'SET_NAME',
			payload: resource.name,
		})
	}, [])

	return (
		<div className='fixed inset-0 h-full bg-gray-900'>
			<Toolbar setShowCreateImage={setShowCreateImage} isGame={isGame} />
			<ProgressBar />
			<Document
				file={file}
				onLoadSuccess={onDocumentLoadSuccess}
				onItemClick={onItemClick}
				className='relative flex h-full'
				loading={
					<div className='absolute inset-0 flex items-center justify-center text-white'>
						<Loader type='Oval' color='#666' height={30} width={30} />
					</div>
				}
			>
				<DocumentOutline width={SIDEBAR_WIDTH} />
				{/* <DocumentThumbnails /> */}
				<div
					className={twMerge(
						'absolute inset-0 flex flex-1 flex-col items-center overflow-auto transition-all duration-300',
					)}
					style={{
						left:
							bookState.isOutlineVisible || bookState.isThumbnailsVisible
								? SIDEBAR_WIDTH
								: '0',
					}}
					ref={containerRef}
				>
					<RenderPages
						containerRef={containerRef}
						observer={observer.current}
						renderCount={renderCount}
					/>
				</div>
				{/* <PageCounter currentPage={currentPage} numPages={numPages} /> */}
			</Document>

			{showCreateImage && (
				<CreateImage
					onClose={() => setShowCreateImage(false)}
					sourceImage={canvasesToImage({
						canvas1: pageOneRef.current,
						canvas2: pageTwoRef.current,
						pagesToDisplay: bookState.pagesToDisplay,
					})}
				/>
			)}
		</div>
	)
}

export default PdfViewer
