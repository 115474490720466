import { useMutation } from '@apollo/client'
import { useContext } from 'react'
import {
	BookMetadata,
	IResource,
	ResourceType,
} from '../../../shared/types/resources'
import AuthContext from '../contexts/auth'
import { ResourceContext } from '../contexts/resources'
import { CREATE_RESOURCE } from '../graphql/resources'
import { RPGBookCategory } from '../utils/bookcategories'
import useExtractMetadataFromBook from './useExtractMetadataFromBook'
import useFileUpload from './useFileUpload'
import usePdfTextExtractor from './usePdfTextExtractor'
import usePdfThumbnail from './usePdfThumbnail'

const emptyBookMetadata: BookMetadata = {
	title: '',
	description: '',
	tags: [],
	category: '' as RPGBookCategory,
	game: '',
	ruleSystem: '',
	authors: [],
	contributingArtists: [],
	publisher: '',
	year: '',
	edition: '',
	isbn: '',
	pageCount: 0,
	language: '',
	copyright: {
		year: '',
		owner: '',
	},
	series: '',
	seriesNumber: 0,
	relatedBooks: [],
	requiredBooks: [],
	contentWarnings: [],
	playerCount: {
		min: 0,
		max: 0,
	},
	recommendedAgeRange: {
		min: 0,
		max: 0,
	},
	playTime: {
		min: 0,
		max: 0,
	},
	tableOfContents: [],
	errata: [],
}

const emptyImageMetadata = {
	description: '',
	tags: [],
	width: 0,
	height: 0,
}

const emptyVideoMetadata = {
	description: '',
	tags: [],
	width: 0,
	height: 0,
	duration: 0,
}

const emptyOtherMetadata = {
	description: '',
	tags: [],
}

const useResourceUpload = () => {
	const { dispatchResource } = useContext(ResourceContext)
	const { authState } = useContext(AuthContext)
	const { userId } = authState
	const fileUpload = useFileUpload()
	const { createThumbnail } = usePdfThumbnail()
	const { extractText } = usePdfTextExtractor()
	const { extractMetadata } = useExtractMetadataFromBook()
	const [createResourceMutation] = useMutation(CREATE_RESOURCE, {
		onError: error => {
			console.error('Error creating resource', error)
		},
	})

	const getResourceType = (fileType: string): ResourceType => {
		if (fileType.startsWith('image/')) return 'image'
		if (fileType.startsWith('video/')) return 'video'
		if (fileType === 'application/pdf') return 'book'
		return 'other'
	}

	const handleFileUpload = async (file: File) => {
		try {
			if (!userId) {
				throw new Error('User not authenticated')
			}

			const uploadResult = await fileUpload(file)
			const thumbnailFile = await createThumbnail(file)
			const thumbnailUpload = await fileUpload(thumbnailFile)
			const resourceType = getResourceType(file.type)

			let metadata = {}

			switch (resourceType) {
				case 'book':
					metadata = emptyBookMetadata
					break
				case 'image':
					metadata = emptyImageMetadata
					break
				case 'video':
					metadata = emptyVideoMetadata
					break
				case 'other':
					metadata = emptyOtherMetadata
					break
				default:
					throw new Error('Unsupported resource type')
			}

			const resourceInput = {
				name: file.name,
				fileurl: uploadResult.fileurl,
				filesize: file.size,
				filetype: file.type,
				thumbnailurl: thumbnailUpload.fileurl,
				resourceType,
				metadata,
				creator: userId, // Add the creator field with the user's ID
			}

			switch (resourceType) {
				case 'book':
					try {
						const extractedText = await extractText(file)
						const metadata = await extractMetadata(extractedText, resourceInput)
						resourceInput.metadata = metadata
					} catch (error) {
						console.error('Error extracting metadata', error)
					}
					break
				case 'image': {
					const img = new Image()
					img.src = URL.createObjectURL(file)
					await new Promise(resolve => (img.onload = resolve))
					metadata = {
						width: img.width,
						height: img.height,
					}
					break
				}
				case 'video': {
					const video = document.createElement('video')
					video.preload = 'metadata'
					video.src = URL.createObjectURL(file)
					await new Promise(resolve => (video.onloadedmetadata = resolve))
					metadata = {
						width: video.videoWidth,
						height: video.videoHeight,
						duration: Math.round(video.duration),
					}
					break
				}
			}

			const response = await createResourceMutation({
				variables: { resourceInput },
			})

			const resource: IResource = response.data.createResource
			dispatchResource({
				type: 'ADD_RESOURCE',
				payload: { resource },
			})

			return resource
		} catch (error) {
			console.error('Error in file upload', error)
		}
	}

	return handleFileUpload
}

export default useResourceUpload
