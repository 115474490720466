import { EllipsisHorizontalIcon } from '@heroicons/react/24/solid'
import React from 'react'
import { twMerge } from 'tailwind-merge'

type Props = React.ButtonHTMLAttributes<HTMLDivElement> & {
	isOpen?: boolean
	className?: string
	show?: boolean
}

const DocumentWindowMenuButton: React.FC<Props> = ({
	isOpen,
	className,
	show = true,
	...rest
}) => {
	return (
		<div
			className={twMerge(
				'mt-0 flex aspect-square items-center justify-center rounded-full px-1.5 shadow-none backdrop-blur-md transition-all duration-200 hover:bg-white/20',
				className,
				show ? '' : 'opacity-0',
				isOpen
					? 'bg-white/100 text-black opacity-100 hover:bg-white/100 hover:text-black'
					: 'bg-white/10 text-white ',
			)}
			style={
				{
					// backgroundColor: isOpen ? theme.secondary : null,
				}
			}
			{...rest}
		>
			<EllipsisHorizontalIcon className='h-5 w-5' aria-hidden='true' />
		</div>
	)
}

export default DocumentWindowMenuButton
