import { Stage as PixiStage } from '@pixi/react'
import GameContext from '../../contexts/game'
import AuthContext from '../../contexts/auth'
import EventContext from '../../contexts/event'
import { SocketContext } from '../../contexts/socket'
import ToolsContext from '../../contexts/tools'
import UsersContext from '../../contexts/users'
import { useEffect, useState } from 'react'
import { DragStateContext } from '../../contexts/dragdrop'
import { DndContext } from 'react-dnd'

const ContextBridge = ({ children, Context, render }) => {
	return (
		<Context.Consumer>
			{value =>
				render(<Context.Provider value={value}>{children}</Context.Provider>)
			}
		</Context.Consumer>
	)
}

export const Stage = ({ children }) => {
	const [windowDimensions, setWindowDimensions] = useState({
		width: window.innerWidth,
		height: window.innerHeight,
	})

	useEffect(() => {
		const handleResize = () => {
			setWindowDimensions({
				width: window.innerWidth,
				height: window.innerHeight,
			})
		}
		window.addEventListener('resize', handleResize)
		return () => {
			window.removeEventListener('resize', handleResize)
		}
	}, [])

	return (
		<ContextBridge
			Context={AuthContext}
			render={authChildren => (
				<ContextBridge
					Context={EventContext}
					render={eventChildren => (
						<ContextBridge
							Context={GameContext}
							render={gameChildren => (
								<ContextBridge
									Context={SocketContext}
									render={socketChildren => (
										<ContextBridge
											Context={DragStateContext}
											render={dragAndDropChildren => (
												<ContextBridge
													Context={ToolsContext}
													render={toolsChildren => (
														<ContextBridge
															Context={UsersContext}
															render={usersChildren => (
																<ContextBridge
																	Context={DndContext}
																	render={dndChildren => (
																		<PixiStage
																			id='pixi-canvas'
																			width={windowDimensions.width}
																			height={windowDimensions.height}
																			options={{
																				backgroundColor: 0x111144,
																				resolution:
																					window.devicePixelRatio || 1,
																			}}
																		>
																			{dndChildren}
																		</PixiStage>
																	)}
																>
																	{usersChildren}
																</ContextBridge>
															)}
														>
															{toolsChildren}
														</ContextBridge>
													)}
												>
													{dragAndDropChildren}
												</ContextBridge>
											)}
										>
											{socketChildren}
										</ContextBridge>
									)}
								>
									{gameChildren}
								</ContextBridge>
							)}
						>
							{eventChildren}
						</ContextBridge>
					)}
				>
					{authChildren}
				</ContextBridge>
			)}
		>
			{children}
		</ContextBridge>
	)
}

export default Stage
