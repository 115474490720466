import { useCallback, useContext } from 'react'
import { debounce } from 'lodash'
import GameContext from '../../contexts/game'
import { IDocument } from '../../interfaces/document'

type Props = {
	documentId: string
}

const useHandleSaveMessage = ({ documentId }: Props) => {
	const { game, dispatch } = useContext(GameContext)
	const doc = game.documents.byId[documentId]

	const handleSaveMessage = useCallback(
		debounce((newDoc: IDocument) => {
			if (newDoc._id !== documentId) return
			if (!doc) return

			dispatch({
				type: 'UPDATE_DOCUMENT',
				payload: { updatedDocument: newDoc },
			})
		}, 300),
		[doc],
	)

	return handleSaveMessage
}

export default useHandleSaveMessage
