import { useFormContext } from 'react-hook-form'
import { IDocument } from '../../../interfaces/document'
import Label from '../../FormComponents/Label'
import Input from '../../Input'

type Props = {
	doc: IDocument
}

const SceneName = ({ doc }: Props) => {
	const { register } = useFormContext()

	return (
		<div className='center flex align-middle'>
			<Label htmlFor='title' className='mt-3 w-1/3'>
				Name
			</Label>
			<Input
				className='w-2/3'
				placeholder='Scene Name...'
				defaultValue={doc.values.name}
				{...register('name')}
			/>
		</div>
	)
}

export default SceneName
