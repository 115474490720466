import { Container, Sprite } from '@pixi/react'
import * as PIXI from 'pixi.js'
import { useEffect } from 'react'
import useGetAssetById from '../../hooks/useGetAssetById'
import useGetDocumentById from '../../hooks/useGetDocumentById'
import { IToken } from '../../interfaces/scene'
import TokenLabel from './TokenLabel'
import useTokenInteractions from './useTokenInteractions'

type Props = {
	token: IToken
}

const Token = ({ token }: Props) => {
	// const scene = useGetActiveScene()
	// const { grid } = scene.values

	// document the token represents
	const { document } = useGetDocumentById(token.documentId)
	const tokenAssetId = document?.values?.token
	const { asset: tokenAsset } = useGetAssetById(tokenAssetId)
	const { interactionProps } = useTokenInteractions({ tokenId: token._id })

	useEffect(() => {
		console.log('token document updated', document)
	}, [document])

	if (!tokenAsset) return null

	const url = tokenAsset.fileurl

	const tokenFitScale = 0.8
	// const tokenSize = grid.size * tokenFitScale
	const tokenSize = 80 * tokenFitScale

	const tokenWidth = tokenAsset.width
	const tokenHeight = tokenAsset.height
	const scale = tokenSize / tokenWidth
	const width = tokenWidth * scale
	const height = tokenHeight * scale

	return (
		<Container x={token.x} y={token.y} interactive={true} {...interactionProps}>
			<Sprite texture={PIXI.Texture.from(url)} width={width} height={height} />
			<Container>
				<TokenLabel
					text={document.values.name || 'Unnamed'}
					y={height + 5}
					x={width / 2}
					wordWrapWidth={width}
				/>
			</Container>
		</Container>
	)
}

export default Token
