import { ReactNode, useContext } from 'react'
import GameContext from '../contexts/game'
import useSocketConnection from '../hooks/useSocketConnection'
import useSystemManager from '../hooks/useSystemManager'
import useSyncPatches from '../hooks/useSyncPatches'

type Props = {
	children: ReactNode
}

const Sockets = ({ children }: Props) => {
	const { game } = useContext(GameContext)
	const isLoadingGame = !game._id
	const isLoadingSystem = game.system === undefined

	useSocketConnection()
	useSyncPatches()
	useSystemManager()

	if (isLoadingGame || isLoadingSystem) {
		return <div className='text-white'>Loading...</div>
	}

	return <>{children}</>
}

export default Sockets
