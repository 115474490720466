import { IMessage } from '../../interfaces/chat'
import SmallUppercase from '../text/SmallUppercase'
import MessageMenu from './MessageMenu'

type Props = {
	message: IMessage
	onDelete: () => void
}

export default function SystemMessage({ message, onDelete }: Props) {
	return (
		<MessageMenu onDelete={onDelete}>
			<div
				key={message._id}
				className='mx-auto my-6 w-4/6 rounded-2xl py-2 px-4 text-center text-xs text-gray-400'
			>
				<SmallUppercase>{message.message}</SmallUppercase>
			</div>
		</MessageMenu>
	)
}
