import { configurePdfWorker, pdfjs } from '../utils/pdfConfig'

configurePdfWorker()

const usePdfThumbnail = () => {
	const createThumbnail = (file: File) => {
		return new Promise<File>((resolve, reject) => {
			if (file && file.type === 'application/pdf') {
				const fileReader = new FileReader()
				fileReader.onload = e => {
					pdfjs.getDocument(e.target.result).promise.then(pdf => {
						pdf.getPage(1).then(page => {
							const viewport = page.getViewport({ scale: 1 })
							const canvas = document.createElement('canvas')
							canvas.height = viewport.height
							canvas.width = viewport.width
							const renderContext = {
								canvasContext: canvas.getContext('2d'),
								viewport: viewport,
							}
							page.render(renderContext).promise.then(() => {
								const base64Data = canvas.toDataURL()
								const byteCharacters = atob(base64Data.split(',')[1])
								const byteNumbers = new Array(byteCharacters.length)
								for (let i = 0; i < byteCharacters.length; i++) {
									byteNumbers[i] = byteCharacters.charCodeAt(i)
								}
								const byteArray = new Uint8Array(byteNumbers)
								const blob = new Blob([byteArray], { type: 'image/jpeg' })
								const thumbnailFile = new File([blob], 'thumbnail.jpg', {
									type: 'image/jpeg',
								})
								resolve(thumbnailFile)
							})
						})
					})
				}
				fileReader.readAsArrayBuffer(file)
			} else {
				reject('Please provide a PDF file.')
			}
		})
	}

	return { createThumbnail }
}

export default usePdfThumbnail
