import { useQuery } from '@apollo/client'
import { useContext, useEffect, useState } from 'react'
import AuthContext from '../../contexts/auth'
import { GAMES_BY_USER } from '../../graphql/games'
import ButtonLink from './elements/ButtonLink'
import Card from './elements/Card'
import GamesList from './sections/GamesList'
import PageHeader from './sections/PageHeader'
import ProfileEditor from './views/ProfileEditor'

const ProfilePage = () => {
	const { authState } = useContext(AuthContext)
	const [editProfile, setEditProfile] = useState(false)
	const { name } = authState.userDetails
	const { loading, data, refetch } = useQuery(GAMES_BY_USER)

	useEffect(() => {
		refetch()
	}, [authState])

	return (
		<>
			<Card className='xl:rounded-none'>
				<PageHeader title={name}>
					<>
						<button
							className='mr-8 flex-none text-right text-xl font-semibold'
							onClick={() => {
								setEditProfile(true)
							}}
						>
							Edit Profile
						</button>
						<button
							className='flex-none text-xl font-semibold'
							onClick={authState.logout}
						>
							Sign Out
						</button>
					</>
				</PageHeader>
			</Card>

			{data && (
				<>
					<Card className='xl:rounded-none'>
						<PageHeader title='Your Games' className='mb-0'>
							<ButtonLink className='flex-1 text-right' to='/game/new'>
								Create Game
							</ButtonLink>
						</PageHeader>

						<GamesList
							games={data?.gamesByUser?.createdGames}
							refetch={refetch}
							loading={loading}
						/>
					</Card>

					<Card className='xl:rounded-none'>
						<PageHeader title='Joined Games' className='mb-12' />

						<GamesList
							games={data?.gamesByUser?.joinedGames}
							loading={loading}
							refetch={refetch}
						/>
					</Card>
				</>
			)}

			{editProfile && <ProfileEditor onClose={() => setEditProfile(false)} />}
		</>
	)
}

export default ProfilePage
