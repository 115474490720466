import React from 'react'

interface ResourceInfoProps {
	name: string
	pageNumber: number
	numPages: number | null
	pagesToDisplay: number
}

const ResourceInfo: React.FC<ResourceInfoProps> = ({
	name,
	pageNumber,
	numPages,
	pagesToDisplay,
}) => {
	const currentPage =
		pagesToDisplay === 1 ? pageNumber : `${pageNumber - 1}-${pageNumber}`

	return (
		<div className='pl-2'>
			<div className='text-sm font-semibold'>{name}</div>
			{numPages && (
				<div className='text-xs text-gray-500'>
					Page {currentPage} of {numPages}
				</div>
			)}
			{!numPages && <div className='text-xs text-gray-500'>Loading...</div>}
		</div>
	)
}

export default ResourceInfo
