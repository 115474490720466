import { SpeakerWaveIcon } from '@heroicons/react/24/solid'
import { useContext } from 'react'
import GameContext from '../../contexts/game'
import useTheme from '../../hooks/useTheme'
import useUser from '../../hooks/useUser'
import ToolButton from '../interface/ToolButton'

export default function SyrinscapeButton() {
	const { game, dispatch } = useContext(GameContext)
	const { primary } = useTheme()
	const { isGM } = useUser()
	const isShowing = game.syrinscape.show

	if (!isGM) return null
	if (!game.flags.syrinscape) return null

	const handleToggleSyrinscape = () => {
		if (isShowing) {
			dispatch({ type: 'HIDE_SYRINSCAPE' })
		} else {
			dispatch({ type: 'SHOW_SYRINSCAPE' })
			// dispatch({ type: 'HIDE_TURN_TRACKER' })
			// dispatch({ type: 'HIDE_BOOKS' })
			// dispatch({ type: 'HIDE_LIBRARY' })
		}
	}

	return (
		<button onClick={handleToggleSyrinscape}>
			<ToolButton
				className='mt-4'
				tooltip={isShowing ? 'Hide Syrinscape' : 'Show Syrinscape'}
				isOpen={isShowing}
			>
				<SpeakerWaveIcon
					className='h-5 w-5'
					aria-hidden='true'
					style={{
						color: isShowing ? 'black' : primary,
					}}
				/>
			</ToolButton>
		</button>
	)
}
