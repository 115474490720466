import { useContext } from 'react'
import DocumentsContext from '../../../contexts/documents'
import GameContext from '../../../contexts/game'
import { IDocument } from '../../../interfaces/document'
import MenuItemDanger from '../../ContextMenu/ItemDanger'

type Props = {
	document: IDocument
}

const DeleteMenuItem = ({ document }: Props) => {
	const { dispatch } = useContext(GameContext)
	const { dispatchDocuments } = useContext(DocumentsContext)

	const handleDeleteDocument = (documentId: string) => {
		dispatchDocuments({
			type: 'CLOSE_DOCUMENT',
			payload: {
				documentId,
			},
		})

		dispatch({
			type: 'DELETE_DOCUMENT',
			payload: {
				documentId,
			},
		})
	}

	return (
		<MenuItemDanger onSelect={() => handleDeleteDocument(document._id)}>
			Delete
		</MenuItemDanger>
	)
}

export default DeleteMenuItem
