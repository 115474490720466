import { EyeIcon, EyeSlashIcon } from '@heroicons/react/24/solid'
import { useContext, useMemo } from 'react'
import GameContext from '../../contexts/game'
import UsersContext from '../../contexts/users'
import { IDocument } from '../../interfaces/document'
import UserDot from '../UserDot'

export interface IAccessDotsProps {
	document: IDocument
}

export default function AccessDots(props: IAccessDotsProps) {
	const { game } = useContext(GameContext)
	const { theme } = game
	const { document } = props
	const { access } = document
	const accessList = document.accessList
	const { usersState } = useContext(UsersContext)
	const isPrivate = useMemo(() => access === 'private', [access])
	const isPublic = useMemo(() => access === 'public', [access])
	const isShared = useMemo(() => Array.isArray(accessList), [accessList])
	// const { role } = useUser()
	// const isGM = role === 'gm'

	const userColors = useMemo(() => {
		if (!isShared) return null

		return accessList.map(userId => {
			const user = usersState.users.find(u => u.userId === userId)
			const color = user?.userSettings.color

			if (user?.userSettings.role !== 'gm') return color
			else return undefined
		})
	}, [accessList, isShared, usersState.users])

	const UserDots = () => (
		<div className='flex self-center'>
			{userColors.map((u, i) => (
				<UserDot className='ml-px' key={i} color={u || ''} />
			))}
		</div>
	)

	const PublicPill = () => (
		<div
			className='flex rounded-full font-bold uppercase'
			style={{
				fontSize: '8px',
				color: theme.secondary,
			}}
			title='Private'
		>
			<EyeIcon className='my-0.5 h-3 w-3' aria-hidden='true' />
		</div>
	)

	const PrivatePill = () => (
		<div
			className='flex rounded-full font-bold uppercase'
			style={{
				fontSize: '8px',
				color: theme.secondary,
			}}
			title='Private'
		>
			<EyeSlashIcon className='my-0.5 h-3 w-3' aria-hidden='true' />
			{isShared && userColors.length > 0 && <UserDots />}
		</div>
	)

	return (
		<div className='flex space-x-0.5 self-center'>
			{isPublic && <PublicPill />}
			{isPrivate && <PrivatePill />}
		</div>
	)
}
