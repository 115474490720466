import { useContext, useMemo } from 'react'
import GameContext from '../contexts/game'
import useUser from './useUser'
import { IDocument } from '../interfaces/document'

const useCollectionTypes = () => {
	const { game } = useContext(GameContext)
	const { documents, system } = game
	const collections = useMemo(() => system?.collections || [], [system])
	const { userId, isGM } = useUser()

	const isDocumentAccessible = (document: IDocument) => {
		return (
			document.access === 'public' ||
			(Array.isArray(document.accessList) &&
				document.accessList.includes(userId))
		)
	}

	const addTypeIfAccessible = (type: string[]) => (document: IDocument) => {
		if (isDocumentAccessible(document) && !type.includes(document.type)) {
			type.push(document.type)
		}
	}

	const collectionTypes = useMemo(() => {
		// GMs see all collections types (except folders)
		if (isGM) {
			return collections.filter(c => c.type !== 'folder').map(c => c.type)
		}

		// Players see all collection types that are public
		// or which contain documents they have access to
		const initialTypes = collections
			.filter(c => c.allowCreate === 'true')
			.map(c => c.type)

		const { allIds, byId } = documents
		allIds.forEach(id => addTypeIfAccessible(initialTypes)(byId[id]))

		return initialTypes
	}, [collections, documents, isGM, userId])

	return collectionTypes
}

export default useCollectionTypes
