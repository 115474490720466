import React, { useCallback, useContext, useEffect } from 'react'
import EventContext from '../../contexts/event'
import useMessageHandler from '../../hooks/useMessageHandler'
import ErrorBoundary from '../ErrorBoundary'
import SectionDivider from '../SectionDivider'
import ChatForm from './ChatForm'
import Log from './Log'
import TypingIndicator from './TypingIndicator'

const Chat = () => {
	const { eventQueue, dispatchEvent } = useContext(EventContext)
	const handleNewMessage = useMessageHandler()

	const handleEvents = useCallback(() => {
		if (eventQueue.length > 1)
			console.error('eventQueue is too long!', eventQueue)

		if (eventQueue.length) {
			const event = eventQueue[0]
			if (event.name === 'message') {
				handleNewMessage(event.data)
				dispatchEvent({ type: 'DELETE_EVENT', payload: event._id })
			}
		}
	}, [eventQueue, dispatchEvent, handleNewMessage])

	useEffect(() => {
		handleEvents()
	}, [handleEvents])

	return (
		<>
			<SectionDivider
				// label={game.turnTracker.show ? 'Log' : ''}
				label='Log'
				className='z-20 mb-2'
			/>
			<ErrorBoundary>
				<Log />
			</ErrorBoundary>
			<TypingIndicator />
			<ChatForm />
		</>
	)
}

export default React.memo(Chat)
