import { useQuery } from '@apollo/client'
import { useContext, useReducer, useState } from 'react'
import { useParams } from 'react-router-dom'
import PdfViewer from './components/resources/PDFViewer'
import AuthContext from './contexts/auth'
import PdfViewerContext from './contexts/book'
import { GET_RESOURCE } from './graphql/resources'
import { PdfViewerState } from './interfaces/book'
import reducer from './reducers/book'

const initialState: PdfViewerState = {
	name: 'Loading...',
	currentPage: 1,
	numPages: null,
	renderedPages: [],
	onRenderSuccess: () => {},
	pagesToDisplay: 2,
	isCoverAlone: false,
	searchText: '',
	fitWidth: true,
	pageSize: {
		width: '50%',
		height: '90vh',
	},
	isOutlineVisible: JSON.parse(
		localStorage.getItem('outlineVisible') || 'true',
	),
	isThumbnailsVisible: JSON.parse(
		localStorage.getItem('thumbnailVisible') || 'false',
	),
	loading: [],
}

const ResourceApp = () => {
	const [bookState, bookDispatch] = useReducer(reducer, initialState)
	const page = new URLSearchParams(window.location.search).get('page')
	const { resourceId } = useParams()
	const [resource, setResource] = useState(null)
	const { authState } = useContext(AuthContext)

	console.log('Resource ID:', resourceId)
	const { loading, error } = useQuery(GET_RESOURCE, {
		variables: { resourceId },
		fetchPolicy: 'no-cache', // cannot figure out why caching won't work for resources
		onCompleted: data => {
			setResource(data.getResource)
		},
	})

	if (loading) {
		return <div>Loading...</div>
	}

	if (error) {
		console.error(error)
		return <div>Error...</div>
	}

	if (!authState.userId) {
		console.error('Error: Authstate user id missing...', authState)
		return (
			<div className='text-white'>
				Auth state User ID not found... Try to refresh or sign out and in again.
			</div>
		)
	}

	if (!resource) {
		return <div className='text-white'>No book found...</div>
	}

	return (
		<PdfViewerContext.Provider value={{ bookState, bookDispatch }}>
			<PdfViewer resource={resource} page={page} isGame={false} />
		</PdfViewerContext.Provider>
	)
}

export default ResourceApp
