import { useContext, useEffect, useMemo, useState } from 'react'
import { IAsset } from '../../../../shared/types/asset'
import AuthContext from '../../contexts/auth'
import GameContext from '../../contexts/game'
import ImageCrossfade from './ImageCrossFade'

const Cover = () => {
	const { game } = useContext(GameContext)
	const { authState } = useContext(AuthContext)
	const { userId } = authState
	const userSettings = game.userSettings[userId]

	if (!userSettings) return null

	const { activeSceneId } = userSettings
	const scene = useMemo(
		() => game.documents.byId[activeSceneId],
		[game.documents.byId, activeSceneId],
	)
	const hasMap = scene?.values.mapId
	const showMap = scene?.values.showMap
	const [showCover, setShowCover] = useState<boolean>(true)
	const [coverAsset, setCoverAsset] = useState(
		game.assets.byId[scene?.values.coverId],
	)

	useEffect(() => {
		if (hasMap && showMap !== 'false') {
			setShowCover(false)
		} else {
			setShowCover(true)
		}

		const newAsset = game.assets.byId[scene?.values.coverId] as IAsset

		if (newAsset) {
			setCoverAsset(newAsset)
			setShowCover(true)
		}
	}, [scene])

	if (!coverAsset) return null

	return (
		<div
			style={{
				opacity: showCover === false ? 0 : 1,
				pointerEvents: showCover === false ? 'none' : 'auto',
			}}
		>
			<ImageCrossfade imageUrl={coverAsset.fileurl} />
		</div>
	)
}

export default Cover
