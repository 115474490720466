import React from 'react'
import Input from '../Input'

interface SearchInputProps extends React.InputHTMLAttributes<HTMLInputElement> {
	id?: string
	placeholder?: string
}

const SearchInput: React.FC<SearchInputProps> = ({
	id = 'search',
	placeholder = 'Search',
	className,
	...props
}) => (
	<Input
		type='search'
		id={id}
		placeholder={placeholder}
		className='pt-0'
		inputClassName={className}
		{...props}
	/>
)

export default SearchInput
