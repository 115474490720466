import { useContext, useState } from 'react'
import { useForm } from 'react-hook-form'
import DocumentsContext from '../../contexts/documents'
import GameContext from '../../contexts/game'
import Button from '../FormComponents/Button'
import Label from '../FormComponents/Label'
import Input from '../Input'
import ModalWindow from '../window/ModalWindow'

type Props = {
	setEnableClickOutside: (value: boolean) => void
}

const DevelopmentMode = ({ setEnableClickOutside }: Props) => {
	const { game } = useContext(GameContext)
	const { dispatchDocuments } = useContext(DocumentsContext)
	const [isModalOpen, setIsModalOpen] = useState(false)
	const [isDevelopmentModeActive, setIsDevelopmentModeActive] = useState(
		JSON.parse(
			localStorage.getItem(`isDevelopmentModeActive_${game._id}`) || 'false',
		),
	)
	const { register, handleSubmit, watch } = useForm({
		defaultValues: {
			developmentOrigin:
				localStorage.getItem(`developmentOrigin_${game._id}`) || '',
		},
	})

	const watchedDevelopmentOrigin = watch('developmentOrigin')

	const handleOpenModal = () => {
		setEnableClickOutside(false)
		setIsModalOpen(true)
	}

	const handleCloseModal = () => {
		if (
			watchedDevelopmentOrigin !== localStorage.getItem('developmentOrigin')
		) {
			const confirmClose = window.confirm(
				'You have unsaved changes. Are you sure you want to close?',
			)
			if (!confirmClose) {
				return
			}
		}
		setEnableClickOutside(true)
		setIsModalOpen(false)
	}

	const onSubmit = (data: { developmentOrigin: string }) => {
		localStorage.setItem(
			`developmentOrigin_${game._id}`,
			data.developmentOrigin,
		)
		window.location.reload()
	}

	const toggleDevelopmentMode = () => {
		setIsDevelopmentModeActive(!isDevelopmentModeActive)
		localStorage.setItem(
			`isDevelopmentModeActive_${game._id}`,
			JSON.stringify(!isDevelopmentModeActive),
		)

		dispatchDocuments({
			type: 'CLOSE_ALL_DOCUMENTS',
		})
	}

	return (
		<div>
			<Button onClick={handleOpenModal}>Configure Development Mode</Button>
			{isDevelopmentModeActive && (
				<div className='mt-1 text-center text-xs text-green-600'>
					<p className='mb-2'>
						Currently active for origin:{' '}
						{localStorage.getItem('developmentOrigin')}
					</p>
				</div>
			)}
			{!isDevelopmentModeActive && (
				<div className='mt-1 text-center text-xs text-gray-500'>
					<p className='mb-2'>Currently Inactive</p>
				</div>
			)}

			{isModalOpen && (
				<ModalWindow
					id='setup-development-mode'
					title='Setup Development Mode'
					onClose={handleCloseModal}
				>
					<form onSubmit={handleSubmit(onSubmit)}>
						<Label className='mt-3'>Dev Server Origin</Label>
						<Input
							className='w-full'
							placeholder='e.g. http://localhost:3000'
							defaultValue={
								localStorage.getItem(`developmentOrigin_${game._id}`) ||
								'http://localhost:3000'
							}
							{...register('developmentOrigin')}
						/>

						{isDevelopmentModeActive && (
							<Button
								type='submit'
								disabled={
									watchedDevelopmentOrigin ===
									localStorage.getItem('developmentOrigin')
								}
							>
								Update Origin Redirect
							</Button>
						)}

						<Button onClick={toggleDevelopmentMode}>
							{isDevelopmentModeActive ? 'Deactivate' : 'Activate'}
						</Button>

						<div className='mt-2 text-sm text-gray-500'>
							<p className='mb-2'>
								Development Mode enables real-time system development with a
								local server. Not available to connected users.
							</p>
						</div>
					</form>
				</ModalWindow>
			)}
		</div>
	)
}

export default DevelopmentMode
