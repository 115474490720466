import { useQuery } from '@apollo/client'
import { useReducer } from 'react'
import { Navigate, Route, Routes, useLocation } from 'react-router-dom'
import { default as BookApp } from './BookApp'
import DocumentApp from './DocumentApp'
import GameApp from './GameApp'
import ResourceApp from './ResourceApp'
import {
	default as AboutPage,
	default as WorkPage,
} from './components/pages/About'
import HomePage from './components/pages/Home'
import InvitePage from './components/pages/InvitePage'
import NewGamePage from './components/pages/NewGame'
import ProfilePage from './components/pages/Profile'
import SignInPage from './components/pages/SignIn'
import SignUpPage from './components/pages/SignUp'
import BooksPage from './components/pages/books/BooksPage'
import Footer from './components/pages/sections/Footer'
import Navigation from './components/pages/sections/Navigation'
import AuthContext from './contexts/auth'
import { SocketContext, socket } from './contexts/socket'
import { AM_I_AUTHED } from './graphql/users'
import { IAuthState } from './interfaces/auth'
import { IUser } from './interfaces/users'
import AuthReducer from './reducers/auth'

function useGetParam() {
	return new URLSearchParams(useLocation().search)
}

export default function App() {
	const getParam = useGetParam()
	const inviteToken = getParam.get('inviteToken')
	const origin = window.location.origin

	const loginHandler = (token: string, user: IUser) => {
		authDispatch({
			type: 'UPDATE_ALL',
			payload: {
				userId: user._id,
				token,
				userDetails: {
					name: user.name,
					email: user.email,
					avatar: user.avatar,
				},
			},
		})
	}

	const logoutHandler = () => {
		console.log('logging out', origin)

		const options: RequestInit = {
			method: 'post',
			credentials: 'include',
			headers: {
				'Content-type': 'application/x-www-form-urlencoded; charset=UTF-8',
			},
		}

		fetch(`${origin}/api/logout/`, options)
			.then(res => {
				if (!res.ok) {
					alert('An error occurred while attempting to sign out.')
				}
				return res
			})
			.then(() => {
				authDispatch({ type: 'LOGOUT' })

				localStorage?.removeItem('token')
			})
	}

	const loadedUser = localStorage?.getItem('user')

	const initialAuthState: IAuthState = {
		token: localStorage?.getItem('token') || null,
		userId: loadedUser || '',
		userDetails: {
			name: '',
			avatar: '',
			email: '',
		},
		login: loginHandler,
		logout: logoutHandler,
	}

	const [authState, authDispatch] = useReducer(AuthReducer, initialAuthState)
	const authToken = authState.token

	const { loading } = useQuery(AM_I_AUTHED, {
		onCompleted(data) {
			const { token, user } = data.amIAuthed

			if (!user || !token) {
				authDispatch({ type: 'LOGOUT' })

				localStorage?.removeItem('token')
			} else {
				authDispatch({
					type: 'UPDATE_ALL',
					payload: {
						userId: user._id,
						token: token,
						userDetails: {
							name: user.name,
							avatar: user.avatar,
							email: user.email,
						},
					},
				})
			}
		},
		onError(error) {
			throw error
		},
	})

	if (loading) {
		return <div>Loading...</div>
	}

	const isAuthed = !!authToken
	const isInvited = !!inviteToken

	return (
		<AuthContext.Provider value={{ authState, authDispatch }}>
			<SocketContext.Provider value={socket}>
				<Navigation loading={loading} />
				<Routes>
					<Route path='/' element={<HomePage />} />
					<Route path='/about' element={<AboutPage />} />
					<Route path='/hiring' element={<WorkPage />} />
					{!isAuthed && <Route path='/signin' element={<SignInPage />} />}
					{!isAuthed && <Route path='/signup' element={<SignUpPage />} />}
					{!isAuthed && (
						<Route
							path='/profile'
							element={<Navigate to='/signin' replace />}
						/>
					)}

					{isInvited && <Route path='/game/:gameId' element={<InvitePage />} />}
					{!isAuthed && !isInvited && (
						<Route path='/game/:gameId' element={<Navigate to='/signin' />} />
					)}

					{isAuthed && (
						<Route path='/signin' element={<Navigate to='/profile' />} />
					)}
					{isAuthed && <Route path='/profile' element={<ProfilePage />} />}
					{isAuthed && <Route path='/profile/books' element={<BooksPage />} />}
					{isAuthed && <Route path='/game/new' element={<NewGamePage />} />}
					{isAuthed && <Route path='/game/:gameId' element={<GameApp />} />}
					{isAuthed && (
						<Route
							path='/document/:gameId/:documentId'
							element={<DocumentApp />}
						/>
					)}
					{isAuthed && (
						<Route path='/book/:gameId/:bookId' element={<BookApp />} />
					)}
					{isAuthed && (
						<Route path='/resource/:resourceId' element={<ResourceApp />} />
					)}
				</Routes>
				<Footer />
			</SocketContext.Provider>
		</AuthContext.Provider>
	)
}
