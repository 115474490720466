const Footer = () => {
	const isGameInUrl =
		location.pathname.includes('/game/') ||
		location.pathname.includes('/book/') ||
		location.pathname.includes('/document/')

	if (isGameInUrl) return null

	const currentYear = new Date().getFullYear()

	return (
		<div className='business-info my-12 text-center text-sm text-gray-500'>
			<p>&copy; {currentYear} Hi Adventure, Inc. All rights reserved.</p>
			<p>
				<a href='https://hiadventure.com/terms-of-service/'>Terms of Service</a>{' '}
				• <a href='https://hiadventure.com/privacy-policy/'>Privacy Policy</a>{' '}
			</p>
		</div>
	)
}

export default Footer
