import { useFormContext } from 'react-hook-form'
import { IDocument } from '../../../interfaces/document'
import Checkbox from '../../FormComponents/Checkbox'
import Label from '../../FormComponents/Label'

type Props = {
	doc: IDocument
}

const SceneSecret = ({ doc }: Props) => {
	const { register } = useFormContext()

	return (
		<div className='center flex items-center align-middle'>
			<Label htmlFor='title' className='mt-3 w-1/3'>
				Secret
			</Label>
			<Checkbox
				className='mt-2 w-2/3'
				defaultChecked={doc.values.nameIsSecret}
				{...register('nameIsSecret')}
			/>
		</div>
	)
}

export default SceneSecret
