import { WindowIcon } from '@heroicons/react/24/solid'
import DocumentWindowHeaderButton from './DocumentWindowHeaderButton'

type Props = {
	handlePop: () => void
}

const PopoutButton = ({ handlePop }: Props) => {
	return (
		<DocumentWindowHeaderButton label='Popout as Window' onClick={handlePop}>
			<WindowIcon className='h-5 w-5' />
		</DocumentWindowHeaderButton>
	)
}

export default PopoutButton
