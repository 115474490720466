import HeroBanner from '../../../../assets/frontsite/herobanner.png'
import BodyText from '../../elements/BodyText'
import ButtonLink from '../../elements/ButtonLink'
import Card from '../../elements/Card'
import Eyebrow from '../../elements/Eyebrow'
import Title from '../../elements/Title'

const Main = () => {
	return (
		<Card
			innerClassName='text-center md:max-w-full md:flex items-center overflow-hidden h-[450px]'
			style={{
				backgroundSize: 'cover',
				backgroundPosition: '75% 45%',
				backgroundRepeat: 'no-repeat',
				backgroundImage: `url(${HeroBanner})`,
			}}
		>
			<div
				style={{
					position: 'absolute',
					inset: 0,
					background:
						'radial-gradient(circle at 80% 50%, transparent 10%, rgba(0, 0, 0, 0.4) 70%)',
				}}
			></div>
			<div className='relative z-10 w-1/2'>
				<Eyebrow>Just Imagine</Eyebrow>
				<Title>Online Roleplaying</Title>
				<BodyText>
					But easy to prep, run and share.
					<br />
					Remotely, at the table, or anywhere.
				</BodyText>
				<ButtonLink
					to='/signup'
					className='mx-auto w-fit rounded-full bg-[#fff]/20 px-6 py-3 text-black backdrop-blur-xl'
				>
					Get Started →
				</ButtonLink>
			</div>
			<div className='relative z-10 w-1/2'>
				{/* <img
					src={HeroImage}
					alt='A fantasy composition in the shape of the New Realms logo with a play button in the middle.'
					className='h-full w-full object-cover'
				/> */}
			</div>
		</Card>
	)
}

export default Main
