import { Container, Sprite } from '@pixi/react'
import useGetActiveScene from '../../hooks/useGetActiveScene'
import useGetAssetById from '../../hooks/useGetAssetById'
import { useEffect, useState } from 'react'

const Map = () => {
	const scene = useGetActiveScene()
	const { asset: mapAsset } = useGetAssetById(scene?.values?.mapId)

	const [width, setWidth] = useState(0)
	const [height, setHeight] = useState(0)

	useEffect(() => {
		if (mapAsset) {
			const aspectRatio = mapAsset.width / mapAsset.height
			let newWidth: number, newHeight: number

			if (mapAsset.width > mapAsset.height) {
				newWidth = 100
				newHeight = 100 / aspectRatio
			} else {
				newHeight = 100
				newWidth = 100 * aspectRatio
			}

			setWidth(newWidth)
			setHeight(newHeight)
		}
	}, [mapAsset])

	if (!mapAsset) return null

	const { fileurl } = mapAsset
	const x = -(width / 2)
	const y = -(height / 2)

	return (
		<Container x={x} y={y}>
			{fileurl && (
				<>
					{/* <Shadow width={width} height={height} /> */}
					<Sprite width={width} height={height} image={fileurl} />
				</>
			)}
		</Container>
	)
}

export default Map
