import Color from 'color'
import { useContext } from 'react'
import GameContext from '../../contexts/game'

interface IProps {
	title?: string
	emptyDescription?: string | undefined
}

const ListMessage: React.FC<IProps> = ({ title, emptyDescription }) => {
	const { game } = useContext(GameContext)
	const { theme } = game
	const fadedPrimaryColor = Color(theme.primary).fade(0.5).string()

	return (
		<div
			className='color={fadedPrimaryColor} flex h-full flex-1 flex-col items-center justify-center px-4 text-center'
			style={{
				color: fadedPrimaryColor,
			}}
		>
			<div className='my-4'>{title ? title : 'Empty'}</div>
			{emptyDescription && (
				<div className='my-4 text-sm'>{emptyDescription}</div>
			)}
		</div>
	)
}

export default ListMessage
