import { useContext } from 'react'
import GameContext from '../../contexts/game'
import useTheme from '../../hooks/useTheme'
import useUser from '../../hooks/useUser'
import SectionDivider from '../SectionDivider'
import SmallUppercase from '../text/SmallUppercase'

const PlayerTurnTracker = () => {
	const { game } = useContext(GameContext)
	const { currentRound, turnOrder, currentTurnIndex } = game?.turnTracker || {}
	const { secondary } = useTheme()
	const { isGM } = useUser()

	// Get current actor details
	const currentActorId = turnOrder?.allIds[currentTurnIndex]
	const currentActorActualName = turnOrder?.byId[currentActorId]?.name
	const currentActorIsIdentified = turnOrder?.byId[currentActorId]?.identified
	const currentActorName = currentActorIsIdentified
		? currentActorActualName
		: 'Unidentified'

	// Initialize next actor details
	let nextActorIndex = (currentTurnIndex + 1) % turnOrder?.allIds.length
	let nextActorId = turnOrder?.allIds[nextActorIndex]
	let nextActorActualName = turnOrder?.byId[nextActorId]?.name
	let nextActorIsIdentified = turnOrder?.byId[nextActorId]?.identified
	let nextActorName = nextActorIsIdentified
		? nextActorActualName
		: 'Unidentified'

	// Find the next active participant
	let iterations = 0
	while (
		!turnOrder?.byId[nextActorId]?.isActive &&
		iterations < turnOrder?.allIds.length
	) {
		nextActorIndex = (nextActorIndex + 1) % turnOrder?.allIds.length
		nextActorId = turnOrder?.allIds[nextActorIndex]
		nextActorActualName = turnOrder?.byId[nextActorId]?.name
		nextActorIsIdentified = turnOrder?.byId[nextActorId]?.identified
		nextActorName = nextActorIsIdentified ? nextActorActualName : 'Unidentified'
		iterations++
	}

	// Check if the next active participant is earlier in the turn order
	const currentActorIndex = turnOrder.allIds.indexOf(currentActorId)
	const isNextRound = nextActorIndex < currentActorIndex
	const newRound = isNextRound ? `(Round ${currentRound + 1})` : ''

	// Render nothing if there's no current round or turn order
	if (isGM || !currentRound || !turnOrder.allIds.length) {
		return null
	}

	// Render the turn tracker
	return (
		<div className='pb-4'>
			<SectionDivider label='Turn Tracker' className='my-0' />

			<div className='just flex space-x-4 align-top'>
				<div className='flex-1'>
					<SmallUppercase>Now</SmallUppercase>
					<div className='overflow-hidden text-ellipsis'>
						{currentActorName}
					</div>
				</div>

				<div className='flex-1'>
					<SmallUppercase>Next {newRound}</SmallUppercase>
					<div
						className='overflow-hidden text-ellipsis'
						style={{ color: secondary }}
					>
						{nextActorName}
					</div>
				</div>
			</div>
		</div>
	)
}

export default PlayerTurnTracker
