import React from 'react'

interface TitleProps extends React.HTMLAttributes<HTMLDivElement> {
	children: React.ReactNode
}

const Title: React.FC<TitleProps> = ({ className, children, ...props }) => {
	return (
		<div
			className={`mb-4 text-3xl font-semibold text-black dark:text-white md:mb-6 lg:text-4xl ${
				className || ''
			}`}
			{...props}
		>
			{children}
		</div>
	)
}

export default Title
