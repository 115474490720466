import { MutableRefObject, useContext, useRef } from 'react'
import GameContext from '../contexts/game'
import { IDocument } from '../interfaces/document'
import useAssetUploader from './useAssetUploader'

export const useHandleUpload = (
	doc: IDocument,
): (({ name, documentId }) => void) => {
	const { game, dispatch } = useContext(GameContext)
	const gameId = game._id
	const assetUploader = useAssetUploader()
	const inputRef: MutableRefObject<HTMLInputElement | null> = useRef(null)

	const handleUpload = ({ name, documentId }) => {
		console.log('handleUpload', { name, documentId })
		if (!documentId) return
		if (doc._id !== documentId) return
		if (!name) return

		inputRef.current = document.createElement('input')
		inputRef.current.name = 'asset'
		inputRef.current.type = 'file'
		inputRef.current.accept = 'image/*,video/*'
		inputRef.current.onchange = async (e: Event) => {
			try {
				const target = e.target as HTMLInputElement
				const file = target.files?.[0]

				if (!file) return

				if (!file.type.match('image.*') && !file.type.match('video.*')) {
					alert('File type is not supported. Please select an image or video.')
					return
				}

				if (file.size > 100 * 1000 * 1000) {
					alert('File is too large. Max size is 100MB.')
					return
				}

				const asset = await assetUploader(file, gameId)

				if (!asset) {
					console.error('Asset upload failed.')
					return
				}

				dispatch({
					type: 'UPDATE_DOCUMENT',
					payload: {
						updatedDocument: {
							...doc,
							version: doc.version + 1,
							values: {
								...doc.values,
								[name]: asset._id,
							},
						},
					},
				})
			} catch (error) {
				console.error('An error occurred during file upload:', error)
			}
		}

		inputRef.current.click()
	}

	return handleUpload
}
