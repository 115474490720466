import * as ContextMenu from '@radix-ui/react-context-menu'
import MenuItem from '../ContextMenu/Item'
import Menu from '../ContextMenu/Menu'

type Props = {
	onDelete: () => void
	children: React.ReactNode
}

export default function MessageMenu({ onDelete, children }: Props) {
	return (
		<ContextMenu.Root>
			<ContextMenu.Trigger>{children}</ContextMenu.Trigger>

			<Menu>
				<MenuItem
					onSelect={onDelete}
					className='text-red-600 hover:bg-red-500/10 dark:text-red-600 dark:hover:bg-red-500/10'
				>
					Delete
				</MenuItem>
			</Menu>
		</ContextMenu.Root>
	)
}
