/* eslint-disable */
import { MouseEvent, useContext, useRef, useState } from 'react'
import { twMerge } from 'tailwind-merge'
import DocumentsContext from '../../contexts/documents'
import useGetDocumentById from '../../hooks/useGetDocumentById'
import useGetUserById from '../../hooks/useGetUserById'
import useUser from '../../hooks/useUser'
import { IMessage } from '../../interfaces/chat'
import Avatar from '../Avatar'
import ItemInformation from '../Library/ItemInformation'
import Translucency from '../Translucency'
import SmallUppercase from '../text/SmallUppercase'
import DocumentMenu from './DocumentMenu'
import MessageMenu from './MessageMenu'
import { DEFAULT_MIN_VERSION } from 'tls'

export interface IDocumentProps {
	message: IMessage
	onDelete: () => void
	className?: string
	showSender?: boolean
}

export default function Document({
	message,
	onDelete,
	showSender = false,
}: IDocumentProps) {
	const { document } = useGetDocumentById(message.attachmentId)
	const { userId, isGM } = useUser()
	const { user: sender } = useGetUserById(message.sender)
	const { dispatchDocuments } = useContext(DocumentsContext)
	const [showMenu, setShowMenu] = useState(false)
	const messageRef = useRef<HTMLDivElement>(null)

	const documentIsPrivate = document.access === 'private'
	const userIsNotSender = userId !== message.sender
	const userIsNotOnAccessList = !document.accessList.includes(userId)

	if (!isGM && documentIsPrivate && userIsNotSender && userIsNotOnAccessList)
		return null

	const handleClick = () => {
		dispatchDocuments({
			type: 'OPEN_DOCUMENT',
			payload: { documentId: document._id },
		})
	}

	const handleContextMenu = (e: MouseEvent) => {
		e.preventDefault()
		setShowMenu(true)
	}

	return (
		<MessageMenu onDelete={onDelete}>
			<div
				className={twMerge('cursor-pointer', showSender ? 'mt-2' : 'mt-0.5')}
				onContextMenu={handleContextMenu}
				onClick={handleClick}
			>
				<div className='my-2 flex flex-row'>
					{sender?.userProfile && (
						<Avatar
							src={sender.userProfile.avatar}
							color={sender.userSettings.color}
							className={showSender ? '' : 'opacity-0'}
						/>
					)}

					<div className='ml-2 flex-1'>
						<SmallUppercase>
							{sender?.userProfile.name} to{' '}
							{documentIsPrivate ? 'you' : 'everyone'}:
						</SmallUppercase>

						<ItemInformation
							ref={messageRef}
							document={document}
							className={twMerge('rounded-xl px-3', Translucency)}
						/>
					</div>
				</div>
			</div>

			<DocumentMenu
				showMenu={showMenu}
				setShowMenu={setShowMenu}
				onDelete={onDelete}
				messageRef={messageRef}
			/>
		</MessageMenu>
	)
}
