import { useContext, useMemo } from 'react'
import { useFormContext } from 'react-hook-form'
import GameContext from '../../contexts/game'
import useCollectionTypes from '../../hooks/useCollectionTypes'
import DocumentTypeListItem from './DocumentTypeListItem'

export default function DocumentTypeList() {
	const { game } = useContext(GameContext)
	const { system, library } = game
	const collections = useMemo(() => system?.collections || [], [system])
	const collectionTypes = useCollectionTypes()
	const { setValue } = useFormContext()

	const sortedCollectionTypes = useMemo(() => {
		const extendedTypes = ['Books', ...collectionTypes]
		return extendedTypes.sort((a, b) => a.localeCompare(b))
	}, [collectionTypes])

	if (!library.show) return null

	return (
		<div className='mb-4 flex flex-wrap justify-center gap-x-0 gap-y-0.5'>
			{sortedCollectionTypes.map(type => {
				const pluralName =
					type === 'Books'
						? 'Books'
						: collections.find(c => c.type === type)?.pluralName
				return (
					<button key={type} onClick={() => setValue('selection', type)}>
						<DocumentTypeListItem label={pluralName} type={type} />
					</button>
				)
			})}
		</div>
	)
}
