import { InformationCircleIcon } from '@heroicons/react/24/solid'
import useHandleOpenDocument from '../document/useHandleOpenDocument'
import TooltipWrapper from '../TooltipWrapper'
import useTheme from '../../hooks/useTheme'

type Props = {
	documentId: string
}

const OpenDocumentButton = ({ documentId }: Props) => {
	const handleOpenDocument = useHandleOpenDocument()
	const { primary } = useTheme()

	return (
		<TooltipWrapper label='Open'>
			<button onClick={() => handleOpenDocument({ documentId })}>
				<InformationCircleIcon
					className='h-5 w-5 text-gray-500'
					style={{
						color: primary,
					}}
				/>
			</button>
		</TooltipWrapper>
	)
}

export default OpenDocumentButton
