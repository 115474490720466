import { WindowIcon } from '@heroicons/react/24/solid'
import ToolbarButton from './ToolbarButton'

const NewWindowButton = () => {
	const handleOpenWindow = () => {
		window.open(window.location.href, '_blank', 'width=1800,height=1050')
	}

	return (
		<ToolbarButton
			label='Open Another Copy of Book'
			onClick={handleOpenWindow}
			onMouseDown={e => e.preventDefault()}
		>
			<WindowIcon className='h-5 w-5' />
		</ToolbarButton>
	)
}

export default NewWindowButton
