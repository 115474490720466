import { MinusCircleIcon, PlusCircleIcon } from '@heroicons/react/24/solid'
import { forwardRef, useState } from 'react'
import { twMerge } from 'tailwind-merge'
import useTheme from '../hooks/useTheme'
import Translucency from './Translucency'

interface StepperProps {
	value: number
	onChange: (value: number) => void
	min?: number
	max?: number
	step?: number
	className?: string
}

const Stepper = forwardRef<HTMLDivElement, StepperProps>(
	(
		{ value, onChange, min = -Infinity, max = Infinity, step = 1, className },
		ref,
	) => {
		const { primary } = useTheme()
		// const fadedPrimaryColor = Color(primary).fade(0.75).string()
		const [isFocused, setIsFocused] = useState(false)

		const increment = () => {
			const newValue = Math.min(value + step, max)
			onChange(newValue)
		}

		const decrement = () => {
			const newValue = Math.max(value - step, min)
			onChange(newValue)
		}

		const displayValue = isFocused
			? value.toString()
			: value > 0
			? `+${value}`
			: value.toString()

		return (
			<div
				ref={ref}
				className={twMerge(
					'flex h-10 items-center rounded-full bg-gray-800 px-1 text-xs text-gray-700',
					Translucency,
					className,
				)}
			>
				<button
					onClick={decrement}
					className='flex h-8 items-center justify-center rounded-full opacity-75 transition-opacity hover:opacity-100'
					style={{
						color: primary,
					}}
				>
					<MinusCircleIcon className='h-8 w-8' />
				</button>
				<input
					type='text'
					value={displayValue}
					onChange={e => {
						const newValue = e.target.value.replace(/^(\+?)/, '')
						onChange(Number(newValue))
					}}
					onFocus={() => setIsFocused(true)}
					onBlur={() => setIsFocused(false)}
					className='h-full w-8 bg-transparent text-center text-sm text-white focus:outline-none'
					style={{
						color: primary,
					}}
				/>
				<button
					onClick={increment}
					className='flex h-8 items-center justify-center rounded-full opacity-75 transition-opacity hover:opacity-100'
					style={{
						color: primary,
					}}
				>
					<PlusCircleIcon className='h-8 w-8' />
				</button>
			</div>
		)
	},
)

Stepper.displayName = 'Stepper'

export default Stepper
