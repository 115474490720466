import React, { forwardRef, useContext } from 'react'
import { twMerge } from 'tailwind-merge'
import useGetCollectionName from '../../hooks/useGetCollectionName'
import { IDocument } from '../../interfaces/document'
import Menu from '../documentmenu/Menu'
import SmallUppercase from '../text/SmallUppercase'
import AccessDots from './AccessDots'
import ItemThumbnail from './ItemThumbnail'
import DocumentWindowMenuButton from './MenuButton'
import GameContext from '../../contexts/game'

type Props = {
	document: IDocument
	className?: string
	style?: React.CSSProperties
	showMenuButton?: boolean
}

const ItemInformation = forwardRef<HTMLDivElement, Props>(
	({ document, className, showMenuButton = true }, ref) => {
		const { game } = useContext(GameContext)
		const { name } = document.values
		const { singularName } = useGetCollectionName(document)
		const isCharacter =
			game.system?.collections?.find(c => c.type === document.type)
				.canAssumeAsCharacter === 'true'

		return (
			<div
				ref={ref}
				className={twMerge(
					'flex flex-auto cursor-pointer space-x-1 overflow-hidden py-1.5',
					className,
				)}
			>
				<ItemThumbnail id={document._id} round={isCharacter} />
				<div className='flex flex-1 flex-col overflow-hidden'>
					<div className='mt-0.5 truncate text-base'>
						{name ? (
							name
						) : (
							<span className='italic text-gray-500'>Unnamed</span>
						)}
					</div>
					{singularName !== 'Folder' && (
						<div className='flex space-x-1'>
							<SmallUppercase>{singularName}</SmallUppercase>
							<AccessDots document={document} />
						</div>
					)}
				</div>
				<Menu document={document}>
					<DocumentWindowMenuButton
						className='bg-gray-500/10'
						show={showMenuButton}
					/>
				</Menu>
			</div>
		)
	},
)

export default ItemInformation
