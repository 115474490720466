import React, { useEffect, useState } from 'react'
import { twMerge } from 'tailwind-merge'
import DiceTools from '../../dice/DiceTools'
import Translucency from '../../Translucency'

interface RollResult {
	id: number
	message: string
	timestamp: number
}

const RESULT_DISPLAY_DURATION = 5000 // 5 seconds

const Dice: React.FC = () => {
	const [rollResults, setRollResults] = useState<RollResult[]>([])

	useEffect(() => {
		const timer = setInterval(() => {
			const now = Date.now()
			setRollResults(prev =>
				prev.filter(result => now - result.timestamp < RESULT_DISPLAY_DURATION),
			)
		}, 1000)

		return () => clearInterval(timer)
	}, [])

	return (
		<>
			<section
				className={twMerge(
					'fixed bottom-4 left-1/2 z-50 -translate-x-1/2 rounded-full p-2 shadow-lg',
					Translucency,
				)}
			>
				<DiceTools />
			</section>
			<div className='fixed bottom-8 right-8 z-50 flex flex-col-reverse space-y-2 space-y-reverse'>
				{rollResults.map(result => (
					<div
						key={result.id}
						className='rounded-lg bg-gray-800 p-4 text-white shadow-lg'
					>
						<p>{result.message}</p>
					</div>
				))}
			</div>
		</>
	)
}

export default Dice
