import {
	ApolloClient,
	ApolloProvider,
	HttpLink,
	InMemoryCache,
	from,
} from '@apollo/client'
import { onError } from '@apollo/client/link/error'

const httpLink = new HttpLink({
	uri: '/graphql',
	fetch: async (uri, options) => await fetch(uri, options),
})

const errorLink = onError(({ graphQLErrors, networkError }) => {
	if (graphQLErrors) {
		graphQLErrors.forEach(({ message, locations, path }) =>
			console.error(
				`[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`,
			),
		)
	}

	if (networkError) console.error(`[Network error]: ${networkError}`)
})

const client = new ApolloClient({
	link: from([errorLink, httpLink]),
	cache: new InMemoryCache({
		addTypename: false, // removing this to make merging with the server easier
	}),
	credentials: 'include',
	connectToDevTools: true,
})

const Apollo = ({ children }) => {
	return <ApolloProvider client={client}>{children}</ApolloProvider>
}

export { Apollo, client }
