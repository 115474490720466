import { TilingSprite } from '@pixi/react'
import desktopWood from '../../assets/backgrounds/desktop-wood.jpg'

const Tabletop = () => {
	const size = 1000

	return (
		<TilingSprite
			image={desktopWood}
			width={size}
			height={size}
			x={-size / 2}
			y={-size / 2}
			tilePosition={{ x: 0, y: 0 }}
			tileScale={{ x: 0.05, y: 0.05 }}
		/>
	)
}

export default Tabletop
