import { useRef, useState } from 'react'
import { twMerge } from 'tailwind-merge'
import useTheme from '../../hooks/useTheme'
import SystemButtonStyle from '../SystemButtonStyle'
import Tooltip from '../Tooltip'

type Props = {
	tooltip: string
	className?: string
	children?: React.ReactNode
	isOpen?: boolean
}

export default function ToolButton({
	className,
	children,
	tooltip,
	isOpen,
}: Props) {
	const anchorRef = useRef<HTMLDivElement>(null)
	const [showLabel, setShowLabel] = useState(false)
	const { primary } = useTheme()

	return (
		<div
			className={twMerge(SystemButtonStyle, className)}
			style={{
				backgroundColor: isOpen ? primary : '',
				color: isOpen ? 'black' : primary,
			}}
		>
			<div
				ref={anchorRef}
				onMouseEnter={() => setShowLabel(true)}
				onMouseLeave={() => setShowLabel(false)}
				className='absolute flex h-full w-full items-center justify-center'
			>
				{children}
			</div>

			{tooltip && (
				<Tooltip open={showLabel} anchor={anchorRef} placement='bottom'>
					{tooltip}
				</Tooltip>
			)}
		</div>
	)
}
