import { useMutation } from '@apollo/client'
import * as DropdownMenu from '@radix-ui/react-dropdown-menu'
import { useContext, useState } from 'react'
import serverOrigin from '../../../config'
import GameContext from '../../../contexts/game'
import { ResourceContext } from '../../../contexts/resources'
import { REMOVE_GAME_FROM_RESOURCES } from '../../../graphql/resources'
import { IBook } from '../../../interfaces/book'
import DangerMenuItem from '../../dropdownmenu/DangerMenuItem'
import MenuContent from '../../dropdownmenu/MenuContent'
import MenuItem from '../../dropdownmenu/MenuItem'
import BookMenuButton from '../BookMenuButton'
import Permissions from './Permissions'

type Props = {
	book: IBook
}

const BookMenu = ({ book }: Props) => {
	const { game, dispatch } = useContext(GameContext)
	const { resources } = useContext(ResourceContext)
	const resource = resources[book.resourceId]
	const [isOpen, setIsOpen] = useState(false)
	const [removeGameFromResources] = useMutation(REMOVE_GAME_FROM_RESOURCES)

	const handleOpen = () => {
		window.open(`${serverOrigin}${resource.fileurl}`, '_blank')
	}

	const handleDelete = async (event: React.MouseEvent) => {
		event.stopPropagation()

		try {
			await removeGameFromResources({
				variables: {
					resourceIds: [resource._id],
					gameId: game._id,
				},
			})

			dispatch({ type: 'REMOVE_BOOK', payload: { bookId: resource._id } })
		} catch (err) {
			console.error(err)
		}
	}

	const onOpenChange = (open: boolean) => {
		setIsOpen(open)
	}

	return (
		<DropdownMenu.Root onOpenChange={onOpenChange}>
			<DropdownMenu.Trigger>
				<BookMenuButton
					className={
						isOpen
							? 'text-white dark:text-white'
							: 'text-white/30 hover:text-white'
					}
				/>
			</DropdownMenu.Trigger>

			<MenuContent>
				<MenuItem onClick={handleOpen}>Open in Tab</MenuItem>
				<Permissions book={book} />
				<DangerMenuItem onClick={handleDelete}>Remove From Game</DangerMenuItem>
			</MenuContent>
		</DropdownMenu.Root>
	)
}

export default BookMenu
