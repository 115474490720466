import { FC, useContext } from 'react'
import DangerButton from '../FormComponents/DangerButton'
import GameContext from '../../contexts/game'
import { ISystem } from '../../interfaces/system'

const SystemReset: FC = () => {
	const { game, dispatch } = useContext(GameContext)

	const handleResetSystem = () => {
		const doReset = window.confirm('Are you sure you want to reset the system?')
		if (!doReset) return

		const systemConfig: ISystem = {
			repository: '',
			name: 'No System',
			version: '1.0.0',
			description: 'There is no system installed.',
			author: 'Michael Heilemann',
			license: 'NA',
			collections: [],
			code: '',
			assetsPath: '',
			assetIds: [],
			grid: {
				type: 'square',
				size: 1,
				unitSize: 1,
				unit: 'm',
			},
		}

		dispatch({
			type: 'LOAD_SYSTEM',
			payload: {
				system: systemConfig,
			},
		})
	}

	const handleDeleteAllDocuments = () => {
		const doDelete = window.confirm(
			'Are you sure you want to delete all documents?',
		)
		if (!doDelete) return

		dispatch({
			type: 'DELETE_ALL_DOCUMENTS',
		})
	}

	const handleDeleteLog = () => {
		const doDelete = window.confirm('Are you sure you want to delete the log?')

		if (!doDelete) return

		dispatch({
			type: 'DELETE_LOG',
		})
	}

	return (
		<>
			{game.system?.name && (
				<>
					<DangerButton onClick={handleResetSystem}>Delete System</DangerButton>

					<DangerButton onClick={handleDeleteAllDocuments}>
						Delete All Documents
					</DangerButton>
				</>
			)}

			<DangerButton onClick={handleDeleteLog}>Delete Chat Log</DangerButton>
		</>
	)
}

export default SystemReset
