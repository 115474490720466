import { FingerPrintIcon } from '@heroicons/react/24/solid'
import { useContext } from 'react'
import { twMerge } from 'tailwind-merge'
import GameContext from '../../contexts/game'
import useTheme from '../../hooks/useTheme'
import TooltipWrapper from '../TooltipWrapper'

type Props = {
	id: string
}

const ToggleIdentifiedButton = ({ id }: Props) => {
	const { game, dispatch } = useContext(GameContext)
	const { primary } = useTheme()
	const { identified } = game.turnTracker.turnOrder.byId[id]

	const handleIdentified = e => {
		e.stopPropagation()
		dispatch({
			type: 'TOGGLE_TURN_TRACKER_ITEM_IDENTIFIED',
			payload: { id },
		})
	}

	return (
		<TooltipWrapper label={identified ? 'Identified' : 'Not Identified'}>
			<button onClick={e => handleIdentified(e)}>
				<FingerPrintIcon
					className={twMerge(
						'h-5 w-5',
						identified ? 'opacity-100' : 'opacity-50',
					)}
					style={{ color: primary }}
				/>
			</button>
		</TooltipWrapper>
	)
}

export default ToggleIdentifiedButton
