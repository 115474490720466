import React, { useEffect, useState } from 'react'
import { animated, useSpring } from 'react-spring'

interface ImageCrossfadeProps {
	imageUrl: string
	duration?: number // crossfade duration in seconds
}

const ImageCrossfade: React.FC<ImageCrossfadeProps> = ({
	imageUrl,
	duration = 1.5,
}) => {
	const [currentImage, setCurrentImage] = useState(imageUrl)
	const [nextImage, setNextImage] = useState('')

	const fade = useSpring({
		from: { opacity: 0 },
		to: { opacity: 1 },
		config: { duration: duration * 1000 },
		reset: true,
	})

	useEffect(() => {
		if (imageUrl !== currentImage) {
			setNextImage(imageUrl)
		}
	}, [imageUrl, currentImage])

	useEffect(() => {
		if (nextImage && nextImage !== currentImage) {
			const timer = setTimeout(() => {
				setCurrentImage(nextImage)
				setNextImage('')
			}, duration * 1000)
			return () => clearTimeout(timer)
		}
	}, [nextImage, currentImage, duration])

	return (
		<div className='fixed inset-0'>
			<div
				className='absolute inset-0 bg-cover bg-center bg-no-repeat'
				style={{ backgroundImage: `url(${currentImage})` }}
			/>
			{nextImage && (
				<animated.div
					className='absolute inset-0 bg-cover bg-center bg-no-repeat'
					style={{
						backgroundImage: `url(${nextImage})`,
						...fade,
					}}
				/>
			)}
		</div>
	)
}

export default ImageCrossfade
