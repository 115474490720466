import { useContext } from 'react'
import GameContext from '../../contexts/game'
import { ResourceContext } from '../../contexts/resources'
import BookItem from './BookItem'
import useItemFilter from '../../hooks/UseItemFilter'

type Props = {
	bookId: string
}

const Book = ({ bookId }: Props) => {
	const { game } = useContext(GameContext)
	const { resources } = useContext(ResourceContext)
	const book = game.books.byId[bookId]
	const resource = resources[book.resourceId]
	const hasAccess = useItemFilter(book)

	if (!resource || !hasAccess) {
		return null
	}

	return <BookItem resource={resource} book={book} />
}

export default Book
