import {
	CloudIcon,
	MapIcon,
	// HandIcon,
	PencilIcon,
} from '@heroicons/react/24/solid'
import { useContext, useRef } from 'react'
import { twMerge } from 'tailwind-merge'
import GameContext from '../../contexts/game'
import ToolsContext from '../../contexts/tools'
import useShowMap from '../../hooks/useShowMap'
import useUser from '../../hooks/useUser'
import { stringBoolean } from '../../interfaces/game'
import Translucency from '../Translucency'
import SceneName from '../interface/SceneName'
import ToolbarControlButton from './ControlButton'

const SceneTools = () => {
	const { game, dispatch } = useContext(GameContext)
	const { toolsState, dispatchTools } = useContext(ToolsContext)
	const { mode } = toolsState
	const { activeSceneId, isGM } = useUser()
	const { showMap, hasBothMapandCover } = useShowMap()
	const drawAnchorRef = useRef<HTMLButtonElement>(null)
	const fogAnchorRef = useRef<HTMLButtonElement>(null)

	const handleDrawingModeClick = () => {
		if (mode !== 'draw') {
			dispatchTools({ type: 'SET_MODE', payload: 'draw' })
		} else {
			dispatchTools({ type: 'SET_MODE', payload: 'select' })
		}
	}

	const handleFogModeClick = () => {
		if (mode !== 'fog') {
			dispatchTools({ type: 'SET_MODE', payload: 'fog' })
		} else {
			dispatchTools({ type: 'SET_MODE', payload: 'select' })
		}
	}

	const handleMapModeClick = () => {
		dispatch({
			type: 'SHOW_MAP',
			payload: {
				sceneId: activeSceneId,
				showMap: showMap === 'true' ? 'false' : ('true' as stringBoolean),
			},
		})
	}

	const ShowMapButton = () => {
		if (!isGM || !hasBothMapandCover) return null

		return (
			<ToolbarControlButton
				className={twMerge('h-10 w-10', Translucency)}
				label='Show Map'
				isOpen={showMap === 'true'}
				onClick={handleMapModeClick}
			>
				<MapIcon className='inline-block h-5 w-5' aria-hidden='true' />
			</ToolbarControlButton>
		)
	}

	const MapTools = () => {
		if (showMap === 'false') return null

		return (
			<>
				{/* <ToolbarControlButton
					className='h-10 w-10 text-yellow-500'
					label='Select'
					isOpen={mode === 'select'}
					onClick={handleSelectModeClick}
				>
					<HandIcon className='inline-block h-5 w-5' aria-hidden='true' />
				</ToolbarControlButton> */}

				{isGM && (
					<ToolbarControlButton
						className={twMerge('h-10 w-10', Translucency)}
						ref={fogAnchorRef}
						label='Fog'
						isOpen={mode === 'fog'}
						onClick={handleFogModeClick}
					>
						<CloudIcon className='inline-block h-5 w-5' aria-hidden='true' />
					</ToolbarControlButton>
				)}

				<ToolbarControlButton
					className={twMerge('h-10 w-10', Translucency)}
					ref={drawAnchorRef}
					label='Draw'
					isOpen={mode === 'draw'}
					onClick={handleDrawingModeClick}
				>
					<PencilIcon className='inline-block h-5 w-5' aria-hidden='true' />
				</ToolbarControlButton>
			</>
		)
	}

	return (
		// <Pod className='pointer-events-auto mb-4 flex items-center justify-center space-y-2 py-2'>
		// {/* <SceneToolsName /> */}
		<div className='pointer-events-auto flex flex-col items-center'>
			<div className='mb-2 flex flex-row items-center space-x-4'>
				{/* {isGM && <SceneButton />} */}
				{game.flags.maps && <ShowMapButton />}
				{/* <GuideButton /> */}
				{false && <MapTools />}
			</div>

			<SceneName />
		</div>

		// <DrawingTools open={mode === 'draw'} anchor={drawAnchorRef} />
		// <FogTools open={mode === 'fog'} anchor={fogAnchorRef} />
		// </Pod>
	)
}

export default SceneTools
