import { TrashIcon } from '@heroicons/react/24/solid'
import { useContext } from 'react'
import GameContext from '../../contexts/game'
import SectionButton from './SectionButton'

const ClearAllButton = () => {
	const { game, dispatch } = useContext(GameContext)
	const { turnOrder } = game.turnTracker

	const clearAll = () => {
		if (window.confirm('Are you sure you want to empty the turn tracker?')) {
			dispatch({ type: 'CLEAR_TURN_TRACKER' })
		}
	}

	if (turnOrder.allIds.length === 0) return null

	return (
		<SectionButton onClick={clearAll}>
			<TrashIcon className='h-4 w-4' />
			{/* <div>Clear</div> */}
		</SectionButton>
	)
}

export default ClearAllButton
