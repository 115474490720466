import { useContext, useEffect } from 'react'
import { useForm } from 'react-hook-form'
import GameContext from '../../contexts/game'
import Label from '../FormComponents/Label'
import TextArea from '../TextArea'

const SceneDescription = () => {
	const { game, dispatch } = useContext(GameContext)
	const { register, watch } = useForm()

	useEffect(() => {
		const subscription = watch(({ description }) => {
			dispatch({
				type: 'SET_GAME_DESCRIPTION',
				payload: {
					description: description,
				},
			})
		})

		return () => subscription.unsubscribe()
	}, [dispatch, watch])

	return (
		<div className='center mt-1 flex align-middle'>
			<Label htmlFor='description' className='mt-3 flex-grow'>
				Description
			</Label>
			<TextArea
				className='w-80'
				rows={4}
				placeholder='Game Description...'
				defaultValue={game.description}
				{...register('description')}
			/>
		</div>
	)
}

export default SceneDescription
