import { useRef } from 'react'
import { twMerge } from 'tailwind-merge'
import useGetAvatarSrc from '../../hooks/useGetAvatarSrc'
import useUser from '../../hooks/useUser'
import Avatar from '../Avatar'
import TextArea from '../TextArea'
import Translucency from '../Translucency'

interface InputProps {
	message: string
	onChange: (e: React.ChangeEvent<HTMLTextAreaElement>) => void
	onSend: () => void
}

const Input = ({ message, onChange, onSend }: InputProps) => {
	const { color, userId } = useUser()
	const avatarSrc = useGetAvatarSrc({ userId })
	const inputRef = useRef<HTMLTextAreaElement>(null)

	const handleKeyDown = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
		if (e.key === 'Enter' && !e.altKey && !e.shiftKey && !e.ctrlKey) {
			e.preventDefault()
			onSend()
		}
	}

	return (
		<div
			className={twMerge(
				Translucency,
				'flex w-full flex-grow place-items-center items-start space-x-2 rounded-3xl border-0',
			)}
		>
			<Avatar color={color} src={avatarSrc} />

			<TextArea
				ref={inputRef}
				value={message}
				onChange={onChange}
				onKeyDown={handleKeyDown}
				placeholder='Message...'
				className='min-h-10 m-0 w-full resize-none border-0 bg-transparent p-2 py-2.5 text-sm text-gray-100 shadow-xl outline-none placeholder:text-gray-500'
				style={{ resize: 'none' }}
			/>
			{message && (
				<button
					type='submit'
					className={twMerge('h-10 rounded-r-full pr-4 text-xl')}
					onClick={onSend}
				>
					→
				</button>
			)}
		</div>
	)
}

export default Input
