import { IBook } from '../interfaces/book'
import { IDocument } from '../interfaces/document'
import useUser from './useUser'

export default function useItemFilter(
	props: IDocument | IBook | null,
): boolean {
	const { userId, isGM } = useUser()

	if (!props) {
		return false
	}

	const { access, accessList } = props

	if (!access || !accessList) {
		return false
	}

	if (isGM) {
		return true
	}

	if (access === 'public') {
		return true
	}

	// If user ID is included in 'accessList', user has access
	if (accessList.includes(userId)) {
		return true
	}

	return false
}
