// the 'right' input
import { forwardRef } from 'react'
import { twMerge } from 'tailwind-merge'
import Loader from 'react-loader-spinner'

interface IInputProps extends React.InputHTMLAttributes<HTMLInputElement> {
	loading?: boolean
	inputClassName?: string
}

const Input = forwardRef<HTMLInputElement, IInputProps>(
	({ className = '', loading = false, inputClassName, ...props }, ref) => {
		return (
			<div className={twMerge('flex pt-1', className)}>
				<input
					type='text'
					ref={ref}
					className={twMerge(
						'block w-full rounded-md bg-gray-800 py-2 pl-3 pr-0 text-white placeholder-gray-400 focus:border-blue-500 focus:ring-blue-500 sm:text-sm',
						inputClassName,
						loading && 'pr-10',
						props.disabled && 'cursor-not-allowed opacity-50',
					)}
					disabled={loading || props.disabled}
					{...props}
				/>

				{loading && (
					<div className='-ml-8 flex items-center'>
						<Loader type='Oval' color='#666' height={20} width={20} />
					</div>
				)}
			</div>
		)
	},
)

export default Input
