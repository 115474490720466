import { PixiComponent, useApp } from '@pixi/react'
import { Viewport as PixiViewport } from 'pixi-viewport'
import * as PIXI from 'pixi.js'
import { FC, ReactNode, useEffect, useRef } from 'react'
import { IAsset } from '../../../../shared/types/asset'
import useGetActiveScene from '../../hooks/useGetActiveScene'
import useGetAssetById from '../../hooks/useGetAssetById'

export interface ViewportProps {
	children?: ReactNode
	setViewport?: (viewport: PixiViewport) => void
}

export interface PixiComponentViewportProps extends ViewportProps {
	app: PIXI.Application
	mapAsset: IAsset
}

const PixiViewportComponent = PixiComponent('Viewport', {
	create: ({ app, setViewport }: PixiComponentViewportProps) => {
		// fix from https://github.com/davidfig/pixi-viewport/issues/438
		const events = new PIXI.EventSystem(app.renderer)
		events.domElement = app.renderer.view as any
		events.resolution = app.renderer.resolution

		const viewport = new PixiViewport({
			events: events,
			ticker: app.ticker,
			allowPreserveDragOutside: true,
			passiveWheel: false,
		})

		viewport
			.drag({
				clampWheel: false,
			})
			.wheel({
				percent: 0.1,
				trackpadPinch: true,
				smooth: 3,
			})
			.decelerate()
			// .clampZoom({
			// 	minScale: 0.35,
			// 	maxScale: 10,
			// })
			.moveCenter(0, 0)
			.setZoom(1)

		setViewport(viewport)

		return viewport
	},
	applyProps(viewport, oldProps, newProps) {
		const { mapAsset } = newProps as PixiComponentViewportProps

		const MAPUNITS = 100
		const SCALEFACTOR = 1.4

		if (mapAsset) {
			const scale = viewport.findFit(
				MAPUNITS * SCALEFACTOR,
				MAPUNITS * SCALEFACTOR,
			)
			console.log('PixiViewportComponent: scale', scale)
			viewport.clampZoom({
				minScale: 1,
				maxScale: 100,
			})
			viewport.setZoom(scale)

			viewport.on('zoomed', () => {
				console.log('Scale changed:', viewport.scale.x) // or viewport.scale.y
			})
		}
	},
	willUnmount: (viewport: PixiViewport) => {
		// @ts-ignore Hack to get around pixi-viewport bug when destroying
		viewport.options.noTicker = true
		viewport.destroy({ children: true, texture: true, baseTexture: true })
	},
})

const Viewport: FC<ViewportProps> = props => {
	const scene = useGetActiveScene()
	const { asset: mapAsset } = useGetAssetById(scene?.values?.mapId)
	const ref = useRef<PixiViewport>(null)

	useEffect(() => {
		// temp fix, revisit this and make it more ideal
		ref.current.setZoom(100)
	}, [ref])

	return (
		<PixiViewportComponent
			ref={ref}
			app={useApp()}
			mapAsset={mapAsset}
			{...props}
		/>
	)
}

export default Viewport
