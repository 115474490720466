import { useContext } from 'react'
import GameContext from '../contexts/game'
import UsersContext from '../contexts/users'
import useUser from './useUser'
import { IMessage } from '../interfaces/chat'
import ObjectID from 'bson-objectid'

const useAssumeCharacter = () => {
	const { game, dispatch } = useContext(GameContext)
	const { usersState } = useContext(UsersContext)
	const { userId: selfId, isGM } = useUser()

	const checkCharacterAvailability = (characterId: string) => {
		for (const userId in game.userSettings) {
			const userSetting = game.userSettings[userId]
			const { assumedCharacterId } = userSetting
			let name = ''

			if (assumedCharacterId === characterId) {
				usersState.users.forEach(user => {
					if (user.userId === userId) {
						console.log(user)
						name = user.userProfile?.name
					}
				})

				return name
			}
		}
		return null
	}

	const handleUnassumeCharacter = (characterId: string) => {
		dispatch({
			type: 'UNASSUME_CHARACTER',
			payload: { characterId },
		})
	}

	const handleAssumeCharacter = (characterId: string, userId: string) => {
		const isSelf = userId === selfId
		const userName = checkCharacterAvailability(characterId)
		const characterIsAssumed = !!userName
		const userSetting = game.userSettings[userId]
		const assumedCharacterId = userSetting?.assumedCharacterId
		const hasAssumedCharacter = !!assumedCharacterId
		const assumedCharacterName =
			game.documents.byId[assumedCharacterId]?.values?.name
		const characterToAssume = game.documents.byId[characterId]?.values?.name
		let confirmationMessage = ''

		if (characterIsAssumed) {
			console.log('characterIsAssumed condition met')
			if (isGM) {
				confirmationMessage = `${userName} is assuming this character. If you assume this character, ${userName} will be removed from it. (Only Game Masters can do this).`
			} else {
				alert(
					`${userName} is already assuming this character. You need to unassume the character first, or a GM has to do so before to make it available.`,
				)
				return
			}
		} else if (!isSelf && hasAssumedCharacter) {
			confirmationMessage = `${userName} is currently playing as ${assumedCharacterName}. Are you sure you want to assign them to ${characterToAssume}?`
		} else if (isSelf && hasAssumedCharacter) {
			confirmationMessage = `You are currently playing as ${assumedCharacterName}. Are you sure you want to play as ${characterToAssume}?`
		}

		if (confirmationMessage) {
			console.log('confirmationMessage:', confirmationMessage)
			const shouldAssume = window.confirm(confirmationMessage)
			if (!shouldAssume) return
		}

		// Logic to unassume the character and assign the new character
		handleUnassumeCharacter(characterId)

		dispatch({
			type: 'ADD_USER_TO_DOCUMENT',
			payload: {
				userId,
				documentId: characterId,
			},
		})

		dispatch({
			type: 'ASSUME_CHARACTER',
			payload: { userId, characterId },
		})

		const payload: IMessage = {
			_id: ObjectID().toHexString(),
			sender: userId,
			type: 'document',
			message: 'Assigning character to you:',
			attachmentId: characterId,
			access: 'private',
			accessList: [userId],
			assumedCharacterId: '',
			// fallbackName: user.userProfile.name,
			// fallbackAvatarId: user.userProfile.avatar || '',
		}

		dispatch({
			type: 'ADD_MESSAGE',
			payload,
		})
	}

	return { handleAssumeCharacter, handleUnassumeCharacter }
}

export default useAssumeCharacter
