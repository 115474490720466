import { HTMLAttributes, ReactNode, forwardRef } from 'react'
import { twMerge } from 'tailwind-merge'

interface CardProps extends HTMLAttributes<HTMLDivElement> {
	innerClassName?: string
	children?: ReactNode
}

const Card = forwardRef<HTMLDivElement, CardProps>(
	({ innerClassName, className, children, ...props }, ref) => {
		return (
			<div
				ref={ref}
				{...props}
				className={twMerge(
					'relative mb-px overflow-hidden bg-gray-100 p-8 py-16 text-black dark:bg-gray-800 dark:text-white md:p-16 md:py-8',
					className,
				)}
			>
				<div className={twMerge('m-auto max-w-xl', innerClassName)}>
					{children}
				</div>
			</div>
		)
	},
)

export default Card
